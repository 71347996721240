import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, FormControl, Link, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import {
  Controller,
  type ControllerRenderProps,
  type SubmitHandler,
  type UseFormStateReturn,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import {
  CustomNotificationModel,
  GetCustomNotificationDocument,
  useAdminEditCustomNotificationMutation,
} from "src/types/graphql.ts";
import { Spacing } from "../../types/enum.ts";
import { type AttachmentModel } from "../../types/generic.ts";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink } from "react-router-dom";
import FileDropzone from "../FileDropzone.tsx";

/**
 * Types
 */
interface Props {
  notification: CustomNotificationModel;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  illustration: yup.string().nullable(),
  title: yup.string().nullable(),
  text: yup.string().nullable(),
  buttonText: yup.string().nullable(),
  buttonUrl: yup.string().nullable(),
});
type FormValues = yup.InferType<typeof schema>;

const EditPopUpNotificationFormCont: React.FC<Props> = ({ notification }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [edit] = useAdminEditCustomNotificationMutation({
    refetchQueries: [
      {
        query: GetCustomNotificationDocument,
        variables: {
          id: notification.id,
        },
      },
    ],
  });

  const defaultValues = {
    illustration: notification.illustration,
    title: notification.title,
    text: notification.text,
    buttonText: notification.buttonText,
    buttonUrl: notification.buttonUrl,
  };

  const { register, control, handleSubmit, formState } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (form): Promise<void> => {
    const variables = {
      id: notification.id,
      input: {
        illustration: form?.illustration,
        title: form?.title,
        text: form?.text,
        buttonText: form?.buttonText,
        buttonUrl: form?.buttonUrl,
      },
    };
    try {
      await edit({ variables });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const renderers = {
    placeholder: () => {
      return (
        <Box>
          <Typography variant="h5" color="textPrimary" align="center">
            Select files
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Drag and drop file here
          </Typography>
        </Box>
      );
    },
    file: <TName extends "illustration">({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, TName>;
      formState: UseFormStateReturn<FormValues>;
    }) => {
      return (
        <FileDropzone
          preview
          showFileContained
          placeholder={renderers.placeholder()}
          fileName={value ?? undefined}
          onDropFile={(attachment: AttachmentModel) => {
            onChange(attachment?.url);
          }}
        />
      );
    },
  };

  return (
    <Box mb={Spacing.m}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          Pop-up Notification
        </Typography>
        <Link color="inherit" component={RouterLink} to={"/popup-notifications"}>
          <Box mr={Spacing.sm}>
            <ArrowBackIcon />
          </Box>
        </Link>
      </Box>

      <Card>
        <Box p={Spacing.l}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h5" color="textPrimary">
              Illustration
            </Typography>
            <Box mt={Spacing.s} mb={Spacing.l}>
              <Controller name="illustration" control={control} render={renderers.file} />
            </Box>

            <Typography variant="h5" color="textPrimary">
              Headline
            </Typography>
            <Box mt={Spacing.s} mb={Spacing.l}>
              <FormControl fullWidth>
                <TextField variant="outlined" {...register("title")} placeholder="Headline" />
              </FormControl>
            </Box>

            <Typography variant="h5" color="textPrimary">
              Body Text
            </Typography>
            <Box mt={Spacing.s} mb={Spacing.l}>
              <FormControl fullWidth>
                <TextField variant="outlined" {...register("text")} placeholder="Body Text" />
              </FormControl>
            </Box>

            <Typography variant="h5" color="textPrimary">
              Button Text
            </Typography>
            <Box mt={Spacing.s} mb={Spacing.l}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  {...register("buttonText")}
                  placeholder="Button Text(leave it blank if button is not required)"
                />
              </FormControl>
            </Box>

            <Typography variant="h5" color="textPrimary">
              Button Url
            </Typography>
            <Box mt={Spacing.s} mb={Spacing.m}>
              <FormControl fullWidth>
                <TextField variant="outlined" {...register("buttonUrl")} placeholder="Button Url" />
              </FormControl>
            </Box>

            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={not(formState.isValid) || formState.isSubmitting}
              fullWidth
            >
              Save
            </Button>
          </form>
        </Box>
      </Card>
    </Box>
  );
};

export default EditPopUpNotificationFormCont;
