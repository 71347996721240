import { ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { isEmpty, isNil } from "ramda";
import React from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { BooleanOptions } from "src/helpers/const";
import { Spacing } from "src/types/enum";
import { useValidateForSelectionBulkInviteMutation } from "src/types/graphql";
import * as yup from "yup";
import ServerMessages from "../../ServerMessages.tsx";
import BulkInviteParticipantsNumber from "../BulkInviteParticipantsNumber";
import { FormStates } from "../types";
import BulkInviteSelectionFormHeader from "./BulkInviteSelectionFormHeader";

/**
 * Types
 */
interface Props {
  formState: FormStates;
  setFormState: React.Dispatch<React.SetStateAction<FormStates>>;
  onClose: () => void;
  filters: any;
  selected: string[];
  allSelected: boolean;
  setEventId: React.Dispatch<React.SetStateAction<string | null>>;
  validatedUserIds: string[];
  onValidated: React.Dispatch<React.SetStateAction<string[]>>;
  eventTitle: string | null;
  setEventTitle: React.Dispatch<React.SetStateAction<string | null>>;
  serverMessages: string[];
  setServerMessages: React.Dispatch<React.SetStateAction<string[]>>;
}

/**
 * Schema
 */
const schema = yup.object({
  eventId: yup.string().trim().required("This field is required"),
});
type FormValues = yup.InferType<typeof schema>;

const BulkInviteSelectionValidateForm: React.FC<Props> = ({
  formState,
  setFormState,
  onClose,
  filters,
  selected,
  allSelected,
  eventTitle,
  setEventId,
  setEventTitle,
  validatedUserIds,
  onValidated,
  serverMessages,
  setServerMessages,
}: Props) => {
  const defaultValues = {
    eventId: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [validateForSelectionBulkInvite] = useValidateForSelectionBulkInviteMutation();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    setEventId(formData.eventId);
    try {
      const { data } = await validateForSelectionBulkInvite({
        variables: {
          eventId: formData.eventId,
          filters: {
            ...filters,
            ids: selected,
            all: allSelected,
            verificationStatus: filters.verificationStatus.map(
              (o: any) => o === BooleanOptions.True,
            ),
            openToSpeakingPartners: filters.openToSpeakingPartners.map(
              (o: any) => o === BooleanOptions.True,
            ),
          },
        },
      });
      const userIds = data?.validateForSelectionBulkInvite.userIds;

      if (isNil(userIds)) {
        enqueueSnackbar("Unexpected user validation error.", { variant: "error" });
        return;
      }
      const messages = data?.validateForSelectionBulkInvite.warningMessages;
      if (messages && !isEmpty(messages)) {
        setServerMessages(messages);
      }

      if (!isEmpty(userIds)) {
        onValidated(userIds);
        setFormState(isEmpty(messages) ? FormStates.Confirm : FormStates.PreConfirm);
      } else {
        setFormState(FormStates.Update);
      }
      const title = data?.validateForSelectionBulkInvite.eventTitle;
      if (title) {
        setEventTitle(title);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        console.error(error);
      }
    }
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onContinue = () => {
    setServerMessages([]);
    setFormState(FormStates.Confirm);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <BulkInviteSelectionFormHeader />

        <Controller
          name="eventId"
          control={control}
          render={({ field }) => (
            <TextField
              InputProps={field}
              label="Event id"
              fullWidth
              placeholder="Paste event id here"
              sx={{ marginBottom: Spacing.sm }}
              helperText={errors.eventId?.message}
              error={!isNil(errors.eventId?.message)}
            />
          )}
        />

        <BulkInviteParticipantsNumber number={validatedUserIds.length} />

        {eventTitle && (
          <Typography variant="body2" color="textPrimary" mb={Spacing.sm}>
            Event:{" "}
            <Typography component="span" fontWeight="bold">
              {eventTitle}
            </Typography>
          </Typography>
        )}

        {!isEmpty(serverMessages) && !isEmpty(validatedUserIds) && (
          <ServerMessages
            subtitle="Some users cannot be invited. You can invite validated users or update the selection."
            messages={serverMessages}
          />
        )}

        {!isEmpty(serverMessages) && isEmpty(validatedUserIds) && (
          <ServerMessages
            subtitle="No users can be invited. Close the window and update the selection."
            messages={serverMessages}
          />
        )}
      </DialogContent>

      <DialogActions>
        {formState === FormStates.Update ? (
          <Button onClick={onCancel}>Close</Button>
        ) : (
          <Button onClick={onCancel}>Cancel</Button>
        )}
        {formState === FormStates.Validate && (
          <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting}>
            Validate
          </LoadingButton>
        )}
        {formState === FormStates.PreConfirm && <Button onClick={onContinue}>Continue</Button>}
      </DialogActions>
    </form>
  );
};

export default BulkInviteSelectionValidateForm;
