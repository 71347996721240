import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  Date: { input: any; output: any };
  JSON: { input: any; output: any };
  Upload_Scalar: { input: any; output: any };
}

export interface AbuseModel {
  abuser?: Maybe<UserModel>;
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  reason?: Maybe<UserReportReason>;
  updatedAt: Scalars["Date"]["output"];
  victim?: Maybe<UserModel>;
}

export interface ActivateUserInput {
  activationKey: Scalars["String"]["input"];
}

export interface AddEventCalendarInput {
  calendarType: CalendarType;
  eventID: Scalars["String"]["input"];
}

export interface AdminFilterBannersInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterCoversInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterEventsInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AdminFilterFeatureFlagsInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterIllustrationsInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterReportsUserFilterInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterThemesInput {
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGrammarLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResource?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTopicLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterTopicsInput {
  category?: InputMaybe<AdminTopicsCategories>;
  excludeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdminTopicsOrderBy>;
  sort?: InputMaybe<SortDirection>;
  status?: InputMaybe<TopicStatus>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  theme?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AdminFilterUsersInput {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  badges?: InputMaybe<Array<UserBadgeType>>;
  englishLevels?: InputMaybe<Array<SpeakingLevelCategories>>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  ids?: InputMaybe<Array<Scalars["String"]["input"]>>;
  interests?: InputMaybe<Array<Interest>>;
  kind?: InputMaybe<Array<EventPermissions>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  location?: InputMaybe<Array<UserLocation>>;
  occupations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  openToSpeakingPartners?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  order?: InputMaybe<SortDirection>;
  orderBy?: InputMaybe<AdminUsersOrderBy>;
  plan?: InputMaybe<Array<MembershipPlans>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionStatus?: InputMaybe<Array<SubscriptionStatus>>;
  verificationStatus?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
}

export interface AdminFilterVideoSetInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterZineCoversInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export enum AdminTopicsCategories {
  GrammarLibrary = "GrammarLibrary",
  Resource = "Resource",
  Standard = "Standard",
  TopicLibrary = "TopicLibrary",
}

export enum AdminTopicsOrderBy {
  LastUpdated = "lastUpdated",
  Theme = "theme",
  Title = "title",
}

export enum AdminUsersOrderBy {
  Country = "country",
  Date = "date",
  Email = "email",
  Name = "name",
}

export enum ArticleAction {
  Publish = "Publish",
}

export enum ArticleByAuthor {
  Any = "Any",
  Follow = "Follow",
  Heylady = "Heylady",
  Members = "Members",
}

export enum ArticleCategory {
  Any = "Any",
  Featured = "Featured",
  Interests = "Interests",
  Journey = "Journey",
  Liked = "Liked",
  Popular = "Popular",
  Recommended = "Recommended",
  Topics = "Topics",
}

export interface ArticleModel {
  attachments?: Maybe<Array<ArticlesAttachmentModel>>;
  author?: Maybe<UserModel>;
  comments?: Maybe<Array<CommentModel>>;
  content?: Maybe<Scalars["String"]["output"]>;
  coverUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  likedByMe?: Maybe<LikeModel>;
  likes?: Maybe<Array<LikeModel>>;
  myEnglishJourney?: Maybe<Scalars["Boolean"]["output"]>;
  noComments?: Maybe<Scalars["Int"]["output"]>;
  noLikes?: Maybe<Scalars["Int"]["output"]>;
  publishedAt?: Maybe<Scalars["Date"]["output"]>;
  reads: ReadArticleModel;
  scheduledFor?: Maybe<Scalars["Date"]["output"]>;
  tags?: Maybe<Array<Interest>>;
  theme?: Maybe<ArticleThemes>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface ArticleNotificationModel {
  article: ArticleModel;
  articleAction: ArticleAction;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export enum ArticleOrderBy {
  Published = "Published",
  Title = "Title",
}

export interface ArticleSearchResult {
  item: ArticleModel;
  matches?: Maybe<Array<SearchMatch>>;
  refIndex: Scalars["Int"]["output"];
}

export enum ArticleThemes {
  ArtAndCulture = "ART_AND_CULTURE",
  CareerWomen = "CAREER_WOMEN",
  Community = "COMMUNITY",
  FamilyAndFriends = "FAMILY_AND_FRIENDS",
  FoodAndTraditions = "FOOD_AND_TRADITIONS",
  HealthAndWellness = "HEALTH_AND_WELLNESS",
  PersonalDevelopment = "PERSONAL_DEVELOPMENT",
  ScienceAndTechnology = "SCIENCE_AND_TECHNOLOGY",
  TheEnvironment = "THE_ENVIRONMENT",
  Travel = "TRAVEL",
}

export interface ArticlesAttachmentModel {
  article: ArticleModel;
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  meta?: Maybe<MetaFileType>;
  updatedAt: Scalars["Date"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
}

export interface AssessmentResultContentRecord {
  question: Scalars["String"]["output"];
  recording: Scalars["String"]["output"];
}

export interface AssessmentResultModel {
  assessmentId: Scalars["String"]["output"];
  assessmentType?: Maybe<Scalars["String"]["output"]>;
  cefrScore?: Maybe<Scalars["String"]["output"]>;
  coherenceScore: Scalars["Float"]["output"];
  content?: Maybe<Array<AssessmentResultContentRecord>>;
  createdAt: Scalars["Date"]["output"];
  fluencyScore: Scalars["Float"]["output"];
  grammarScore: Scalars["Float"]["output"];
  halloScore: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  improvements?: Maybe<Array<Scalars["String"]["output"]>>;
  isAddedByAdmin: Scalars["Boolean"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  pronunciationScore: Scalars["Float"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
  vocabularyScore: Scalars["Float"]["output"];
}

export interface AssignOrDeleteBadgeInput {
  achievedDate?: InputMaybe<Scalars["Date"]["input"]>;
  type: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export enum AssistanceReasons {
  Assistance = "ASSISTANCE",
  HelpFindingEvents = "HELP_FINDING_EVENTS",
  HelpFindingPeople = "HELP_FINDING_PEOPLE",
  NeedMoreTime = "NEED_MORE_TIME",
  SomethingElse = "SOMETHING_ELSE",
}

export interface AssistanceReasonsInput {
  customReason?: InputMaybe<Scalars["String"]["input"]>;
  reason: Array<AssistanceReasons>;
}

export interface AttendEventInput {
  eventID: Scalars["String"]["input"];
}

export interface AttendanceMetrics {
  totalDuration?: Maybe<Scalars["Int"]["output"]>;
  totalEvents?: Maybe<Scalars["Int"]["output"]>;
  totalGroup?: Maybe<Scalars["Int"]["output"]>;
  totalHostedDuration?: Maybe<Scalars["Int"]["output"]>;
  totalHostedEvents?: Maybe<Scalars["Int"]["output"]>;
  totalHostedGroup?: Maybe<Scalars["Int"]["output"]>;
  totalHostedInPerson?: Maybe<Scalars["Int"]["output"]>;
  totalHostedOneToOnes?: Maybe<Scalars["Int"]["output"]>;
  totalHostedOther?: Maybe<Scalars["Int"]["output"]>;
  totalHostedWebinars?: Maybe<Scalars["Int"]["output"]>;
  totalInPerson?: Maybe<Scalars["Int"]["output"]>;
  totalOneToOnes?: Maybe<Scalars["Int"]["output"]>;
  totalOther?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedDuration?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedEvents?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedGroup?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedInPerson?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedNonScheduledEvents?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedOneToOnes?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedOther?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedScheduledEvents?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedWebinars?: Maybe<Scalars["Int"]["output"]>;
  totalVideoCalls?: Maybe<Scalars["Int"]["output"]>;
  totalWebinars?: Maybe<Scalars["Int"]["output"]>;
}

export interface AttendanceRecord {
  attendanceRatio?: Maybe<Scalars["Float"]["output"]>;
  attended?: Maybe<Scalars["Int"]["output"]>;
  totalRsvps?: Maybe<Scalars["Int"]["output"]>;
}

export interface AttendedEvents {
  groupEvents?: Maybe<Scalars["Int"]["output"]>;
  inPersonEvents?: Maybe<Scalars["Int"]["output"]>;
  oneToOnes?: Maybe<Scalars["Int"]["output"]>;
  other?: Maybe<Scalars["Int"]["output"]>;
  webinars?: Maybe<Scalars["Int"]["output"]>;
}

export enum Audience {
  NewMembers = "NEW_MEMBERS",
  OneMonthPlus = "ONE_MONTH_PLUS",
  Zero = "ZERO",
}

export interface Availability {
  from: Scalars["String"]["output"];
  until: Scalars["String"]["output"];
}

export interface AvailabilityForUsersInput {
  userIds: Array<Scalars["String"]["input"]>;
}

export interface AvailabilityInput {
  from: Scalars["String"]["input"];
  until: Scalars["String"]["input"];
}

export interface AvailabilityMatchForUsers {
  availabilityMatch?: Maybe<AvailabilityStatus>;
  userId: Scalars["String"]["output"];
}

export enum AvailabilityStatus {
  Different = "Different",
  Shared = "Shared",
}

export interface BadgeNotificationModel {
  badge: UserBadgeModel;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export interface BannerModel {
  audience?: Maybe<Array<EventPermissions>>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  sliders?: Maybe<Array<SliderModel>>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface BulkInviteResponse {
  warningMessages: Array<Scalars["String"]["output"]>;
}

export interface BulkInviteWithEmailsInput {
  emails: Array<Scalars["String"]["input"]>;
}

export interface BulkInviteWithUserIdsInput {
  userIds: Array<Scalars["String"]["input"]>;
}

export interface CalendarResponseType {
  reason?: Maybe<GoogleApiMessage>;
  status: MutationStatus;
  url?: Maybe<Scalars["String"]["output"]>;
}

export enum CalendarType {
  Google = "GOOGLE",
  Outlook = "OUTLOOK",
}

export interface CancelAttendanceInput {
  eventID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ChargeBeeAddress {
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  line1?: Maybe<Scalars["String"]["output"]>;
  line2?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
}

export interface ChargeBeeAddressInput {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  line1?: InputMaybe<Scalars["String"]["input"]>;
  line2?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ChargebeeCustomerModel {
  cbAddress?: Maybe<ChargeBeeAddress>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  currency?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  oldId?: Maybe<Scalars["String"]["output"]>;
  paymentMethods: Array<ChargebeePaymentMethodModel>;
  promotionalCredits?: Maybe<Scalars["Int"]["output"]>;
  resourceVersion: Scalars["Date"]["output"];
  subscriptions: Array<ChargebeeSubscriptionModel>;
  user: UserModel;
}

export interface ChargebeePaymentMethodModel {
  card?: Maybe<Scalars["JSON"]["output"]>;
  cbCustomerId?: Maybe<Scalars["String"]["output"]>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  cbObj?: Maybe<Scalars["JSON"]["output"]>;
  customer: ChargebeeCustomerModel;
  id: Scalars["String"]["output"];
  isPrimary?: Maybe<Scalars["Boolean"]["output"]>;
  resourceVersion: Scalars["Date"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
}

export interface ChargebeeSubscriptionModel {
  billingCycleAnchor?: Maybe<Scalars["Date"]["output"]>;
  cancelReason?: Maybe<Scalars["String"]["output"]>;
  cancelScheduleCreatedAt?: Maybe<Scalars["Date"]["output"]>;
  cancelledAt?: Maybe<Scalars["Date"]["output"]>;
  cbCustomerId?: Maybe<Scalars["String"]["output"]>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  cbPaymentMethodId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  currentPeriodEnd?: Maybe<Scalars["Date"]["output"]>;
  currentPeriodStart?: Maybe<Scalars["Date"]["output"]>;
  customer: ChargebeeCustomerModel;
  id: Scalars["String"]["output"];
  plan?: Maybe<Scalars["JSON"]["output"]>;
  resourceVersion: Scalars["Date"]["output"];
  resumesAt?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export enum ChatChannelType {
  Messaging = "messaging",
  Public = "public",
}

export interface ChatMentionNotificationModel {
  actor?: Maybe<UserModel>;
  channelId: Scalars["String"]["output"];
  chatMessage?: Maybe<Scalars["String"]["output"]>;
  chatMessageId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  parentChatMessageId?: Maybe<Scalars["String"]["output"]>;
  user: UserModel;
}

export enum ColourCode {
  Blue = "BLUE",
  Green = "GREEN",
  Orange = "ORANGE",
  Pink = "PINK",
  Red = "RED",
}

export enum CommentAction {
  Comment = "Comment",
  Mention = "Mention",
}

export interface CommentModel {
  author?: Maybe<UserModel>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  parent?: Maybe<CommentModel>;
  parentID?: Maybe<Scalars["String"]["output"]>;
  reactions?: Maybe<Array<CommentReactionsModel>>;
  replies?: Maybe<Array<CommentModel>>;
  targetArticle?: Maybe<ArticleModel>;
  targetEvent?: Maybe<EventModel>;
  targetId: Scalars["String"]["output"];
  targetTopic?: Maybe<TopicModel>;
  targetType: CommentTargetType;
  updatedAt: Scalars["Date"]["output"];
}

export interface CommentNotificationModel {
  actor?: Maybe<UserModel>;
  comment: CommentModel;
  commentAction: CommentAction;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export interface CommentReactionsModel {
  comment: CommentModel;
  commentId: Scalars["String"]["output"];
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  unifiedId: Scalars["String"]["output"];
  user: UserModel;
  userId: Scalars["String"]["output"];
}

export enum CommentTargetType {
  Article = "Article",
  Event = "Event",
  Topic = "Topic",
}

export interface CompletedVideoModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  user: UserModel;
  videoId: Scalars["String"]["output"];
}

export interface ConfirmInPersonEventInput {
  status: InPersonEventConfirmationStatus;
  userIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface ConfirmTotpInput {
  deviceName: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
}

export enum ContactUsCategories {
  Events = "EVENTS",
  Feedback = "FEEDBACK",
  GeneralQuestion = "GENERAL_QUESTION",
  MyAccount = "MY_ACCOUNT",
  TechnicalSupport = "TECHNICAL_SUPPORT",
  TopicsAndLessons = "TOPICS_AND_LESSONS",
}

export interface ContactUsInput {
  category: ContactUsCategories;
  email: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  wantsNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export enum ConversationKind {
  CasualConversation = "CASUAL_CONVERSATION",
  CustomConversation = "CUSTOM_CONVERSATION",
  DeepDive = "DEEP_DIVE",
  StudyGroup = "STUDY_GROUP",
}

export interface Coordinates {
  lat?: Maybe<Scalars["Float"]["output"]>;
  long?: Maybe<Scalars["Float"]["output"]>;
}

export interface CoverModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  primary?: Maybe<ResponsiveType>;
  secondary?: Maybe<ResponsiveType>;
  tertiary?: Maybe<ResponsiveType>;
  theme?: Maybe<Array<ThemeModel>>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface CreateArticlesAttachmentInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<MetaFileInput>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateBannerInput {
  title: Scalars["String"]["input"];
}

export interface CreateChannelInput {
  usersID?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface CreateCommentInput {
  mentions?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message: Scalars["String"]["input"];
  parentID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateCoverInput {
  primary?: InputMaybe<ResponsiveInput>;
  secondary?: InputMaybe<ResponsiveInput>;
  tertiary?: InputMaybe<ResponsiveInput>;
  title: Scalars["String"]["input"];
}

export interface CreateEventAttachmentInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<MetaFileInput>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateEventInput {
  attachments?: InputMaybe<Array<CreateEventAttachmentInput>>;
  conversationKind?: InputMaybe<ConversationKind>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdFromTopic?: InputMaybe<Scalars["Boolean"]["input"]>;
  customTopic?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  hostId?: InputMaybe<Scalars["String"]["input"]>;
  ideasToDiscuss?: InputMaybe<Array<Scalars["String"]["input"]>>;
  invitePeople?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForConfidentUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventKind>;
  links?: InputMaybe<Array<LinkInput>>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  maxParticipants?: InputMaybe<Scalars["Int"]["input"]>;
  peopleIDToInvite?: InputMaybe<Array<Scalars["String"]["input"]>>;
  recurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateFeatureFlagInput {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  label: Scalars["String"]["input"];
}

export interface CreateIllustrationInput {
  title: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateInviteInput {
  eventID: Scalars["String"]["input"];
  isConfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHost?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  userID: Scalars["String"]["input"];
}

export interface CreateLessonInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  kind: LessonKind;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicID: Scalars["String"]["input"];
}

export interface CreateLikeInput {
  genericId: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateLiveTableInput {
  englishLevel?: InputMaybe<EventEnglishLevelNamed>;
  maxParticipants?: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
}

export interface CreateMessageInput {
  description: Scalars["String"]["input"];
  receiver_id: Scalars["String"]["input"];
}

export interface CreateReactionInput {
  commentId: Scalars["String"]["input"];
  unifiedId: Scalars["String"]["input"];
}

export interface CreateSectionInput {
  elements?: InputMaybe<Array<ElementInput>>;
  lessonID: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
}

export interface CreateSparkInput {
  eventId?: InputMaybe<Scalars["String"]["input"]>;
  liveTableId?: InputMaybe<Scalars["String"]["input"]>;
  videoCallId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateThemeInput {
  isGrammarLibrary: Scalars["Boolean"]["input"];
  isResource: Scalars["Boolean"]["input"];
  isStandard: Scalars["Boolean"]["input"];
  isTopicLibrary: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
}

export interface CreateTopicInput {
  title: Scalars["String"]["input"];
}

export interface CreateUserFeatureFlagInput {
  featureFlagId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export interface CreateUserInput {
  email: Scalars["String"]["input"];
  englishLevel: Scalars["Int"]["input"];
  password: Scalars["String"]["input"];
  planParam: Scalars["String"]["input"];
}

export interface CreateVideoCallInput {
  channelType: ChatChannelType;
  chatRoomId: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
}

export interface CreateVideoInput {
  position?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  videoSetId: Scalars["String"]["input"];
}

export interface CreateVideoSetInput {
  colour: ColourCode;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
}

export interface CreateZineInput {
  coverUrl: Scalars["String"]["input"];
}

export interface CursorPaginatedNotifications {
  items: Array<NotificationBaseModel>;
  nextCursor?: Maybe<Scalars["Date"]["output"]>;
  total: Scalars["Int"]["output"];
}

export interface CustomNotificationModel {
  buttonText?: Maybe<Scalars["String"]["output"]>;
  buttonUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  illustration?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface DailyMeetingInput {
  id: Scalars["String"]["input"];
  userID: Scalars["String"]["input"];
}

export interface DatetimeFilters {
  gt?: InputMaybe<Scalars["Date"]["input"]>;
  lt?: InputMaybe<Scalars["Date"]["input"]>;
}

export interface DeactivateInput {
  otherReason: Scalars["String"]["input"];
  reason: Array<UserDeactivationReason>;
}

export interface DeclineInviteInput {
  inviteID?: InputMaybe<Scalars["String"]["input"]>;
}

export enum DeepDiveKind {
  RolePlay = "ROLE_PLAY",
  Roundtable = "ROUNDTABLE",
  ShareAndCompare = "SHARE_AND_COMPARE",
  SolveAProblem = "SOLVE_A_PROBLEM",
}

export interface DeleteInvitesInput {
  eventId: Scalars["String"]["input"];
  isHost: Scalars["Boolean"]["input"];
}

export interface DeletePhotoInput {
  id: Scalars["String"]["input"];
}

export interface DeleteTopicsInput {
  topicIds: Array<Scalars["String"]["input"]>;
}

export interface DuplicateLessonInput {
  lessonId: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
}

export interface EditArticleInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  myEnglishJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishedAt?: InputMaybe<Scalars["Date"]["input"]>;
  scheduledFor?: InputMaybe<Scalars["Date"]["input"]>;
  tags?: InputMaybe<Array<Interest>>;
  theme?: InputMaybe<ArticleThemes>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditArticlesAttachmentInput {
  description: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface EditBannerInput {
  audience?: InputMaybe<Array<EventPermissions>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditCommentInput {
  message: Scalars["String"]["input"];
}

export interface EditCoverInput {
  primary?: InputMaybe<ResponsiveInput>;
  secondary?: InputMaybe<ResponsiveInput>;
  tertiary?: InputMaybe<ResponsiveInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditCustomNotificationInput {
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonUrl?: InputMaybe<Scalars["String"]["input"]>;
  illustration?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditEventInput {
  conversationKind?: InputMaybe<ConversationKind>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  englishLevel?: InputMaybe<EventEnglishLevel>;
  eventCategory?: InputMaybe<EventCategory>;
  eventLessonKind?: InputMaybe<EventLessonKind>;
  hostId?: InputMaybe<Scalars["String"]["input"]>;
  ideasToDiscuss?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForUnverified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventKind>;
  links?: InputMaybe<Array<LinkInput>>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["Any"]["input"]>;
  noParticipants?: InputMaybe<Scalars["Int"]["input"]>;
  permissions?: InputMaybe<Array<EventPermissions>>;
  recurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditFeatureFlagInput {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  label: Scalars["String"]["input"];
}

export interface EditIllustrationInput {
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditLessonInput {
  deepDiveKind?: InputMaybe<DeepDiveKind>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<LessonKind>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditSectionInput {
  elements?: InputMaybe<Array<ElementInput>>;
  lessonID?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface EditSliderInput {
  background?: InputMaybe<ResponsiveInput>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonURL?: InputMaybe<Scalars["String"]["input"]>;
  featureImage?: InputMaybe<Scalars["String"]["input"]>;
  imageAlignment?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  textColour?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleStyle?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditThemeInput {
  coverId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGrammarLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResource?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTopicLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditTopicInput {
  illustrationId?: InputMaybe<Scalars["String"]["input"]>;
  stars?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TopicStatus>;
  themeId?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditUserFeatureFlagInput {
  featureFlagId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export interface EditUserInput {
  availability?: InputMaybe<Array<AvailabilityInput>>;
  avatarUrl?: InputMaybe<Scalars["String"]["input"]>;
  badge?: InputMaybe<UserBadge>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Date"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  extraConversations?: InputMaybe<Scalars["Int"]["input"]>;
  extraHostedEvents?: InputMaybe<Scalars["Int"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAgeVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHeyLady?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventPermissions>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  nativeLanguage?: InputMaybe<Scalars["String"]["input"]>;
  needSpeakingPartner?: InputMaybe<Scalars["Boolean"]["input"]>;
  occupationId?: InputMaybe<Scalars["String"]["input"]>;
  otherLanguage?: InputMaybe<Scalars["String"]["input"]>;
  pauseCredits?: InputMaybe<Scalars["Int"]["input"]>;
  showBirthday?: InputMaybe<Scalars["Boolean"]["input"]>;
  showBirthdayYear?: InputMaybe<Scalars["Boolean"]["input"]>;
  speakingLevelScore?: InputMaybe<Scalars["Float"]["input"]>;
  story?: InputMaybe<Scalars["String"]["input"]>;
  wantsNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface EditVideoInput {
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  videoSetID?: InputMaybe<Scalars["String"]["input"]>;
  videoURL?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditVideoSetInput {
  audience?: InputMaybe<Array<Audience>>;
  colour?: InputMaybe<ColourCode>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditZineInput {
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface Element {
  kind: ElementKind;
  meta: Scalars["JSON"]["output"];
  value?: Maybe<Scalars["Any"]["output"]>;
}

export interface ElementInput {
  kind: ElementKind;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  value?: InputMaybe<Scalars["Any"]["input"]>;
}

export enum ElementKind {
  Attachment = "ATTACHMENT",
  Banner = "BANNER",
  Button = "BUTTON",
  Divider = "DIVIDER",
  Embedded = "EMBEDDED",
  Grid = "GRID",
  Ideas = "IDEAS",
  Image = "IMAGE",
  ImageAndButton = "IMAGE_AND_BUTTON",
  Layout = "LAYOUT",
  Links = "LINKS",
  Paragraph = "PARAGRAPH",
  Questions = "QUESTIONS",
  Spacing = "SPACING",
  Title = "TITLE",
  Video = "VIDEO",
}

export enum EmailNotifications {
  Digest = "DIGEST",
  Inactive = "INACTIVE",
  Spot = "SPOT",
}

export enum EnglishLevel {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  C1 = "C1",
  C2 = "C2",
}

export enum EnglishLevelMatch {
  Different = "Different",
  Same = "Same",
  Similar = "Similar",
}

export enum EnglishSkill {
  GivingAPresentation = "GIVING_A_PRESENTATION",
  InterviewOrBeInterviewed = "INTERVIEW_OR_BE_INTERVIEWED",
  IntroduceMyself = "INTRODUCE_MYSELF",
  MeetNewPeople = "MEET_NEW_PEOPLE",
  NegotiateAndDebate = "NEGOTIATE_AND_DEBATE",
  PassAnEnglishExam = "PASS_AN_ENGLISH_EXAM",
  PersonalNetworking = "PERSONAL_NETWORKING",
  Pronunciation = "PRONUNCIATION",
  SpeakConfidentlyAtWork = "SPEAK_CONFIDENTLY_AT_WORK",
  ThinkInEnglish = "THINK_IN_ENGLISH",
  UnderstandMoviesAndMusic = "UNDERSTAND_MOVIES_AND_MUSIC",
  UnderstandNativeSpeakers = "UNDERSTAND_NATIVE_SPEAKERS",
  WriteCreatively = "WRITE_CREATIVELY",
  WriteProfessionally = "WRITE_PROFESSIONALLY",
}

export interface EntityIdResponseType {
  id: Scalars["String"]["output"];
}

export interface EventAttachmentModel {
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  event: EventModel;
  id: Scalars["String"]["output"];
  meta?: Maybe<MetaFileType>;
  updatedAt: Scalars["Date"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
}

export enum EventAvailabilityFilter {
  AnyTime = "ANY_TIME",
  AvailableWhenIAm = "AVAILABLE_WHEN_I_AM",
}

export enum EventCategory {
  Conversation = "CONVERSATION",
  None = "NONE",
  Professional = "PROFESSIONAL",
  StudyAndLearn = "STUDY_AND_LEARN",
}

export enum EventEnglishLevel {
  Advanced = "ADVANCED",
  Everyone = "EVERYONE",
  Intermediate = "INTERMEDIATE",
}

export enum EventEnglishLevelFilter {
  Advanced = "ADVANCED",
  All = "ALL",
  AnyLevel = "ANY_LEVEL",
  Intermediate = "INTERMEDIATE",
}

export enum EventEnglishLevelNamed {
  Advanced = "ADVANCED",
  AnyLevel = "ANY_LEVEL",
  Intermediate = "INTERMEDIATE",
}

export enum EventHostedByFilter {
  Anyone = "ANYONE",
  BigSisters = "BIG_SISTERS",
  HlCoach = "HL_COACH",
  HlMembers = "HL_MEMBERS",
  HlTeam = "HL_TEAM",
  PeopleIFollow = "PEOPLE_I_FOLLOW",
}

export enum EventKind {
  CasualConversation = "CASUAL_CONVERSATION",
  ConversationStarter = "CONVERSATION_STARTER",
  DeepDive = "DEEP_DIVE",
  GeneralEvent = "GENERAL_EVENT",
  GrammarSpot = "GRAMMAR_SPOT",
  GroupEvent = "GROUP_EVENT",
  Interview = "INTERVIEW",
  InPerson = "IN_PERSON",
  LadiesLunch = "LADIES_LUNCH",
  NewMemberMeetup = "NEW_MEMBER_MEETUP",
  OfficeHours = "OFFICE_HOURS",
  Offline = "OFFLINE",
  OneToOne = "ONE_TO_ONE",
  Online = "ONLINE",
  TeacherTalk = "TEACHER_TALK",
}

export enum EventLessonKind {
  DeepDive = "DEEP_DIVE",
  Overview = "OVERVIEW",
  Vocabulary = "VOCABULARY",
}

export interface EventModel {
  attachments?: Maybe<Array<EventAttachmentModel>>;
  closedDoorAt?: Maybe<Scalars["Date"]["output"]>;
  cohosts: Array<UserModel>;
  comments?: Maybe<Array<CommentModel>>;
  confirmationStatus?: Maybe<InPersonEventConfirmationStatus>;
  conversationKind?: Maybe<ConversationKind>;
  coverUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  createdFromTopic?: Maybe<Scalars["Boolean"]["output"]>;
  customTopic?: Maybe<Scalars["Boolean"]["output"]>;
  declined: Array<UserModel>;
  description?: Maybe<Scalars["String"]["output"]>;
  duplicateOf?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  englishLevel?: Maybe<EventEnglishLevel>;
  englishSkills?: Maybe<Array<EnglishSkill>>;
  eventCategory?: Maybe<EventCategory>;
  eventLessonKind?: Maybe<EventLessonKind>;
  host?: Maybe<UserModel>;
  id: Scalars["String"]["output"];
  ideasToDiscuss?: Maybe<Array<Scalars["String"]["output"]>>;
  illustrationUrl?: Maybe<Scalars["String"]["output"]>;
  interests?: Maybe<Array<Interest>>;
  invited: Array<UserModel>;
  invites?: Maybe<Array<InviteModel>>;
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  isForConfidentUsers?: Maybe<Scalars["Boolean"]["output"]>;
  isForFoundation?: Maybe<Scalars["Boolean"]["output"]>;
  isForRise?: Maybe<Scalars["Boolean"]["output"]>;
  isForUnverified?: Maybe<Scalars["Boolean"]["output"]>;
  isFull?: Maybe<Scalars["Boolean"]["output"]>;
  isOnline?: Maybe<Scalars["Boolean"]["output"]>;
  isPublic?: Maybe<Scalars["Boolean"]["output"]>;
  kind?: Maybe<EventKind>;
  likes?: Maybe<Array<LikeModel>>;
  links?: Maybe<Array<Link>>;
  location?: Maybe<Scalars["String"]["output"]>;
  locationId?: Maybe<Scalars["String"]["output"]>;
  locationLat?: Maybe<Scalars["Float"]["output"]>;
  locationLong?: Maybe<Scalars["Float"]["output"]>;
  maxParticipants?: Maybe<Scalars["Int"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  noParticipants?: Maybe<Scalars["Int"]["output"]>;
  occupations?: Maybe<Array<OccupationModel>>;
  organizer?: Maybe<UserModel>;
  participants: Array<UserModel>;
  permissions?: Maybe<Array<EventPermissions>>;
  recurring?: Maybe<Scalars["Boolean"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  topic?: Maybe<TopicModel>;
  updatedAt: Scalars["Date"]["output"];
  videoUrl?: Maybe<Scalars["String"]["output"]>;
}

export enum EventNotificationAction {
  Cohosting = "Cohosting",
  ConfirmInPersonEvent = "ConfirmInPersonEvent",
  Delete = "Delete",
  FollowingIsHosting = "FollowingIsHosting",
  HostRemoveCohost = "HostRemoveCohost",
  HostRemoveParticipant = "HostRemoveParticipant",
  InApp15MinBeforeEvent = "InApp15MinBeforeEvent",
  Invite = "Invite",
  OneToOneNoResponse = "OneToOneNoResponse",
  SharedEvent = "SharedEvent",
  Update = "Update",
  UserAccept = "UserAccept",
  UserDecline = "UserDecline",
}

export interface EventNotificationModel {
  action: EventNotificationAction;
  actor?: Maybe<UserModel>;
  event: EventModel;
  id: Scalars["String"]["output"];
  invite?: Maybe<InviteModel>;
  user: UserModel;
}

export enum EventPermissions {
  BigSister = "BIG_SISTER",
  HeyLadyAdmin = "HEY_LADY_ADMIN",
  HeyLadyTeam = "HEY_LADY_TEAM",
  InactiveMember = "INACTIVE_MEMBER",
  MoreThanTwelveMonths = "MORE_THAN_TWELVE_MONTHS",
  OneToThree = "ONE_TO_THREE",
  ThreeToTwelve = "THREE_TO_TWELVE",
  ZeroToOne = "ZERO_TO_ONE",
}

export enum EventTypeFilter {
  AllEventTypes = "ALL_EVENT_TYPES",
  Conversation = "CONVERSATION",
  DeepDive = "DEEP_DIVE",
  InPerson = "IN_PERSON",
  ListenOnly = "LISTEN_ONLY",
  NewMemberMeetup = "NEW_MEMBER_MEETUP",
  Professional = "PROFESSIONAL",
  StudyAndLearn = "STUDY_AND_LEARN",
}

export enum EventsOrderBy {
  Created = "CREATED",
  EndDate = "END_DATE",
  InvitedFirst = "INVITED_FIRST",
  StartDate = "START_DATE",
  Updated = "UPDATED",
}

export interface EventsPerDay {
  count: Scalars["Int"]["output"];
  day: Scalars["Date"]["output"];
}

export interface FeatureFlagModel {
  active?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  users: Array<UserFlagModel>;
}

export interface FilterArticlesInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  byAuthor?: InputMaybe<ArticleByAuthor>;
  category?: InputMaybe<ArticleCategory>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface FilterCustomNotificationsInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface FilterPaginatedEventInput {
  availability?: InputMaybe<EventAvailabilityFilter>;
  duration?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  englishLevel?: InputMaybe<EventEnglishLevelFilter>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  eventDate?: InputMaybe<Scalars["Date"]["input"]>;
  hostId?: InputMaybe<Scalars["String"]["input"]>;
  hostedBy?: InputMaybe<EventHostedByFilter>;
  interests?: InputMaybe<Array<Interest>>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPast?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  matchEnglishLevel?: InputMaybe<Scalars["Boolean"]["input"]>;
  occupationIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizerId?: InputMaybe<Scalars["String"]["input"]>;
  showFullEvents?: InputMaybe<Scalars["Boolean"]["input"]>;
  showTopPicks?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<EventTypeFilter>;
  userInviteStatus?: InputMaybe<UserInviteStatus>;
  usersDayLight?: InputMaybe<Scalars["Boolean"]["input"]>;
  withinOneWeek?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface FilterThemesInput {
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGrammarLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResource?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTopicLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface FilterTopicsInput {
  excludeTheme?: InputMaybe<Scalars["String"]["input"]>;
  includeTheme?: InputMaybe<Scalars["String"]["input"]>;
}

export interface FilterUsersInput {
  availability?: InputMaybe<Array<AvailabilityStatus>>;
  englishLevels?: InputMaybe<Array<SpeakingLevelCategories>>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  following?: InputMaybe<Scalars["Boolean"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<Array<EventPermissions>>;
  lastOnlineAt?: InputMaybe<DatetimeFilters>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  location?: InputMaybe<Array<UserLocation>>;
  occupations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface FollowInput {
  followingId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface FollowerModel {
  createdAt: Scalars["Date"]["output"];
  follower: UserModel;
  following: UserModel;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface FriendInput {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
}

export interface FriendJoinedNotificationModel {
  friend: UserModel;
  id: Scalars["String"]["output"];
  type: FriendJoinedNotificationType;
  user: UserModel;
}

export enum FriendJoinedNotificationType {
  AcceptedInvite = "AcceptedInvite",
  FriendJoined = "FriendJoined",
}

export interface FriendlyFaces {
  attended?: Maybe<Scalars["Int"]["output"]>;
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
}

export interface GenericResponseType {
  reason?: Maybe<Scalars["String"]["output"]>;
  status: MutationStatus;
}

export enum GoogleApiMessage {
  CalendarAlreadyAdded = "CALENDAR_ALREADY_ADDED",
  CalendarNotFound = "CALENDAR_NOT_FOUND",
  EventAlreadyAdded = "EVENT_ALREADY_ADDED",
  EventNotFound = "EVENT_NOT_FOUND",
  InvalidGrant = "INVALID_GRANT",
  InviteNotFound = "INVITE_NOT_FOUND",
  Success = "SUCCESS",
  Unauthorized = "UNAUTHORIZED",
}

export interface GoogleCalendarModel {
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  etag?: Maybe<Scalars["String"]["output"]>;
  googleId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  kind?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export interface GoogleEventModel {
  createdAt: Scalars["Date"]["output"];
  endDate?: Maybe<Scalars["String"]["output"]>;
  etag?: Maybe<Scalars["String"]["output"]>;
  event?: Maybe<EventModel>;
  googleCalendar?: Maybe<GoogleCalendarModel>;
  googleId?: Maybe<Scalars["String"]["output"]>;
  hostID?: Maybe<Scalars["String"]["output"]>;
  iCalUID?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  invite: InviteModel;
  kind?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  organizerID?: Maybe<Scalars["String"]["output"]>;
  reminders?: Maybe<Scalars["JSON"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface IllustrationModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  topics?: Maybe<Array<TopicModel>>;
  updatedAt: Scalars["Date"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
}

export enum ImageAlignment {
  Left = "Left",
  Right = "Right",
}

export interface ImportUserInput {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  extraConversations?: InputMaybe<Scalars["Int"]["input"]>;
  extraHostedEvents?: InputMaybe<Scalars["Int"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventPermissions>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  priceId?: InputMaybe<Scalars["String"]["input"]>;
  trialDays?: InputMaybe<Scalars["Int"]["input"]>;
}

export enum InPersonEventConfirmationStatus {
  Hosted = "Hosted",
  Initial = "Initial",
  NotHosted = "NotHosted",
}

export interface Industry {
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  occupations: Array<OccupationModel>;
}

export interface IndustryInput {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  occupations: Array<OccupationInput>;
}

export interface InitiateAssessmentResponse {
  assessmentId: Scalars["String"]["output"];
  assessmentUrl: Scalars["String"]["output"];
}

export enum Interest {
  AnimalsAndWildlife = "ANIMALS_AND_WILDLIFE",
  ArtsAndCrafts = "ARTS_AND_CRAFTS",
  BeautyAndFashion = "BEAUTY_AND_FASHION",
  BusinessAndEntrepreneurship = "BUSINESS_AND_ENTREPRENEURSHIP",
  CareersAndProfessionalDevelopment = "CAREERS_AND_PROFESSIONAL_DEVELOPMENT",
  Design = "DESIGN",
  DifferentCultures = "DIFFERENT_CULTURES",
  Economics = "ECONOMICS",
  EnvironmentalIssues = "ENVIRONMENTAL_ISSUES",
  Equality = "EQUALITY",
  FamilyAndMotherhood = "FAMILY_AND_MOTHERHOOD",
  FeminismAndEmpowerment = "FEMINISM_AND_EMPOWERMENT",
  FoodAndCooking = "FOOD_AND_COOKING",
  Games = "GAMES",
  GoalsSettingAndSuccess = "GOALS_SETTING_AND_SUCCESS",
  HealthAndWellness = "HEALTH_AND_WELLNESS",
  Hiking = "HIKING",
  History = "HISTORY",
  HomeAndGardening = "HOME_AND_GARDENING",
  Ielts = "IELTS",
  Languages = "LANGUAGES",
  Leadership = "LEADERSHIP",
  LivingAbroad = "LIVING_ABROAD",
  LoveAndRelationships = "LOVE_AND_RELATIONSHIPS",
  MoviesAndTvShows = "MOVIES_AND_TV_SHOWS",
  MusicAndDance = "MUSIC_AND_DANCE",
  NatureAndTheEnvironment = "NATURE_AND_THE_ENVIRONMENT",
  PersonalDevelopment = "PERSONAL_DEVELOPMENT",
  Photography = "PHOTOGRAPHY",
  Politics = "POLITICS",
  Psychology = "PSYCHOLOGY",
  Reading = "READING",
  ScienceAndTechnology = "SCIENCE_AND_TECHNOLOGY",
  Spirituality = "SPIRITUALITY",
  Sports = "SPORTS",
  SustainableLiving = "SUSTAINABLE_LIVING",
  TheatreAndPerformance = "THEATRE_AND_PERFORMANCE",
  Travel = "TRAVEL",
  VideoGames = "VIDEO_GAMES",
  VolunteeringAndCharity = "VOLUNTEERING_AND_CHARITY",
  Writing = "WRITING",
}

export interface InviteModel {
  createdAt: Scalars["Date"]["output"];
  event: EventModel;
  googleEvent: GoogleEventModel;
  id: Scalars["String"]["output"];
  isConfirmed?: Maybe<Scalars["Boolean"]["output"]>;
  isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  isHost?: Maybe<Scalars["Boolean"]["output"]>;
  status?: Maybe<InviteStatus>;
  updatedAt: Scalars["Date"]["output"];
  user?: Maybe<UserModel>;
}

export enum InviteStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Initial = "Initial",
}

export interface InvitedFriendsStats {
  invitedFriendsTotal?: Maybe<Scalars["Int"]["output"]>;
  invitedPaidFriends?: Maybe<Scalars["Int"]["output"]>;
}

export interface Invoice {
  amount?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Date"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  downloadUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  last4Digits?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
}

export enum LessonKind {
  Custom = "CUSTOM",
  DeepDive = "DEEP_DIVE",
  Overview = "OVERVIEW",
  Vocabulary = "VOCABULARY",
}

export interface LessonModel {
  createdAt: Scalars["Date"]["output"];
  deepDiveKind?: Maybe<DeepDiveKind>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  kind: LessonKind;
  sections?: Maybe<Array<SectionModel>>;
  title?: Maybe<Scalars["String"]["output"]>;
  topic: TopicModel;
  updatedAt: Scalars["Date"]["output"];
}

export interface LikeModel {
  article: ArticleModel;
  event: EventModel;
  genericId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  topic: TopicModel;
  user: UserModel;
}

export interface Link {
  description: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
}

export interface LinkInput {
  description: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface LiveTableModel {
  createdAt: Scalars["Date"]["output"];
  creatorId?: Maybe<Scalars["String"]["output"]>;
  dailyRoomId: Scalars["String"]["output"];
  endedAt?: Maybe<Scalars["Date"]["output"]>;
  englishLevel?: Maybe<EventEnglishLevelNamed>;
  id: Scalars["String"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  lastSingleUserAt?: Maybe<Scalars["Date"]["output"]>;
  maxParticipants: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface LiveTableStartedMessage {
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  tableCreatorId: Scalars["String"]["output"];
  tableEnglishLevel?: Maybe<EventEnglishLevelNamed>;
  tableId: Scalars["String"]["output"];
  tableTitle: Scalars["String"]["output"];
}

export interface LiveTableStartedMessageInput {
  tableEnglishLevel?: InputMaybe<EventEnglishLevelNamed>;
  tableId: Scalars["String"]["input"];
  tableTitle: Scalars["String"]["input"];
}

export interface LiveTablesFeedbackInput {
  comment: Scalars["String"]["input"];
  rating: Scalars["Int"]["input"];
  willUseAgain: Scalars["Int"]["input"];
}

export interface LiveTablesParticipant {
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface LiveTablesResponse {
  participants?: Maybe<Array<LiveTablesParticipant>>;
  table: LiveTableModel;
}

export interface MatchDetails {
  availabilityMatch?: Maybe<AvailabilityStatus>;
  commonEnglishSkills?: Maybe<Array<EnglishSkill>>;
  commonInterests?: Maybe<Array<Interest>>;
  englishLevelMatch?: Maybe<EnglishLevelMatch>;
  occupationMatch?: Maybe<OccupationMatch>;
  sameCity?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface MatchScores {
  availabilityScore: Scalars["Float"]["output"];
  englishInterestScore: Scalars["Float"]["output"];
  englishLevelScore: Scalars["Float"]["output"];
  eventParticipationScore: Scalars["Float"]["output"];
  interestScore: Scalars["Float"]["output"];
  locationScore: Scalars["Float"]["output"];
  occupationScore: Scalars["Float"]["output"];
}

export interface MeetingTokenResponse {
  exp?: Maybe<Scalars["Int"]["output"]>;
  roomName: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
}

export enum MembershipPlans {
  Annual = "Annual",
  FreeOneMonth = "FreeOneMonth",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SupporterOneMonth = "SupporterOneMonth",
  ThreeMonthsOnly = "ThreeMonthsOnly",
}

export interface MessageModel {
  bodyText: Scalars["String"]["output"];
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  receiver: UserModel;
  sender: UserModel;
  updatedAt: Scalars["Date"]["output"];
}

export interface MetaFileInput {
  extension?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface MetaFileType {
  extension?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["Int"]["output"]>;
}

export enum MonthlyRecapSteps {
  ArticlesWritten = "ArticlesWritten",
  AttendanceRatio = "AttendanceRatio",
  EventsAttended = "EventsAttended",
  EventsHosted = "EventsHosted",
  EventsStreak = "EventsStreak",
  FriendlyFaces = "FriendlyFaces",
  GlobalNetwork = "GlobalNetwork",
  ParticipationTime = "ParticipationTime",
  Sparks = "Sparks",
  SpontaneousConversations = "SpontaneousConversations",
  ThankYou = "ThankYou",
}

export interface MonthlyReportNotificationModel {
  id: Scalars["String"]["output"];
  type: MonthlyReportNotificationType;
}

export enum MonthlyReportNotificationType {
  MonthlyReport = "MonthlyReport",
}

export interface Mutation {
  activateTrialSubscription: GenericResponseType;
  activateUser?: Maybe<GenericResponseType>;
  addEventToGoogleCalendar: CalendarResponseType;
  addZoomLicenseToUser?: Maybe<GenericResponseType>;
  adminBanUser?: Maybe<GenericResponseType>;
  adminCreateBanner?: Maybe<BannerModel>;
  adminCreateCover?: Maybe<CoverModel>;
  adminCreateCustomNotification?: Maybe<CustomNotificationModel>;
  adminCreateFeatureFlag?: Maybe<FeatureFlagModel>;
  adminCreateIllustration?: Maybe<IllustrationModel>;
  adminCreateLesson?: Maybe<LessonModel>;
  adminCreateNote?: Maybe<NoteModel>;
  adminCreateSection?: Maybe<SectionModel>;
  adminCreateSlider?: Maybe<GenericResponseType>;
  adminCreateTheme?: Maybe<ThemeModel>;
  adminCreateTopic?: Maybe<TopicModel>;
  adminCreateUserFeatureFlag?: Maybe<UserFlagModel>;
  adminCreateVideo?: Maybe<GenericResponseType>;
  adminCreateVideoSet?: Maybe<VideoSetModel>;
  adminCreateZineCover?: Maybe<ZineModel>;
  adminDeleteArticle?: Maybe<GenericResponseType>;
  adminDeleteBanner?: Maybe<GenericResponseType>;
  adminDeleteCover?: Maybe<GenericResponseType>;
  adminDeleteEvent?: Maybe<GenericResponseType>;
  adminDeleteFeatureFlag?: Maybe<GenericResponseType>;
  adminDeleteIllustration?: Maybe<GenericResponseType>;
  adminDeleteLesson?: Maybe<GenericResponseType>;
  adminDeleteSection?: Maybe<GenericResponseType>;
  adminDeleteSlider?: Maybe<GenericResponseType>;
  adminDeleteTheme?: Maybe<GenericResponseType>;
  adminDeleteTopic?: Maybe<GenericResponseType>;
  adminDeleteTopics?: Maybe<GenericResponseType>;
  adminDeleteUserFeatureFlag?: Maybe<GenericResponseType>;
  adminDeleteUserReport?: Maybe<GenericResponseType>;
  adminDeleteVideo?: Maybe<GenericResponseType>;
  adminDeleteVideoSet?: Maybe<GenericResponseType>;
  adminDeleteZineCover?: Maybe<GenericResponseType>;
  adminEditArticle?: Maybe<ArticleModel>;
  adminEditBanner?: Maybe<BannerModel>;
  adminEditCover?: Maybe<CoverModel>;
  adminEditCustomNotification?: Maybe<GenericResponseType>;
  adminEditEvent?: Maybe<GenericResponseType>;
  adminEditFeatureFlag?: Maybe<FeatureFlagModel>;
  adminEditIllustration?: Maybe<IllustrationModel>;
  adminEditLesson?: Maybe<LessonModel>;
  adminEditSection?: Maybe<SectionModel>;
  adminEditSlide?: Maybe<SliderModel>;
  adminEditTheme?: Maybe<ThemeModel>;
  adminEditTopic?: Maybe<TopicModel>;
  adminEditUser?: Maybe<GenericResponseType>;
  adminEditUserFeatureFlag?: Maybe<UserFlagModel>;
  adminEditVideo?: Maybe<VideoModel>;
  adminEditVideoSet?: Maybe<VideoSetModel>;
  adminEditZineCover?: Maybe<ZineModel>;
  adminGenerateAttachmentUploadUrl?: Maybe<UploadUrlsType>;
  adminImportUser?: Maybe<GenericResponseType>;
  adminOrderSlider?: Maybe<GenericResponseType>;
  adminOrderVideo?: Maybe<GenericResponseType>;
  adminOrderVideoSet?: Maybe<GenericResponseType>;
  adminRepublishEvent: GenericResponseType;
  adminUnbanUser?: Maybe<GenericResponseType>;
  adminWelcomeUser?: Maybe<GenericResponseType>;
  assignAllExtraReferralBadges: GenericResponseType;
  assignCompleteOnboardingQuizBadge: GenericResponseType;
  assignUserBadge?: Maybe<GenericResponseType>;
  attendEvent?: Maybe<InviteModel>;
  bulkInviteWithUserIds?: Maybe<BulkInviteResponse>;
  cancelAttendance: InviteModel;
  cancelScheduleCancelSubscription?: Maybe<GenericResponseType>;
  chatAddMembersToChannel?: Maybe<GenericResponseType>;
  chatCreateChannel?: Maybe<Scalars["Any"]["output"]>;
  chatGiveAdminRole?: Maybe<Scalars["Any"]["output"]>;
  chatRemoveMembersFromChannel?: Maybe<GenericResponseType>;
  chatRenameChannel?: Maybe<GenericResponseType>;
  chatSendDirectMessage?: Maybe<GenericResponseType>;
  chooseSubscription?: Maybe<Scalars["JSON"]["output"]>;
  closeDoor?: Maybe<EventModel>;
  closeVideoCallDoor: VideoCallModel;
  completeVideo?: Maybe<CompletedVideoModel>;
  confirmInPersonEvent: GenericResponseType;
  confirmTotp: GenericResponseType;
  contactUs?: Maybe<GenericResponseType>;
  createAbuse?: Maybe<AbuseModel>;
  createArticle?: Maybe<ArticleModel>;
  createArticleAttachment?: Maybe<ArticlesAttachmentModel>;
  createAttachment?: Maybe<EventAttachmentModel>;
  createComment?: Maybe<CommentModel>;
  createEvent?: Maybe<EventModel>;
  createInvite: InviteModel;
  createLike?: Maybe<LikeModel>;
  createLiveTable: LiveTableModel;
  createMessage?: Maybe<MessageModel>;
  createReaction: CommentReactionsModel;
  createSpark: GenericResponseType;
  createTopicForPreviewMode?: Maybe<EntityIdResponseType>;
  createTotp: UserTotpModel;
  createUser: UserSignUpModel;
  createUserPhoto?: Maybe<UserPhotoModel>;
  createVideoCall: VideoCallModel;
  deactivate?: Maybe<GenericResponseType>;
  declineInvite: InviteModel;
  deleteAccount?: Maybe<GenericResponseType>;
  deleteArticle?: Maybe<EntityIdResponseType>;
  deleteArticleAttachment?: Maybe<EntityIdResponseType>;
  deleteAttachment?: Maybe<EntityIdResponseType>;
  deleteComment?: Maybe<GenericResponseType>;
  deleteEvent?: Maybe<GenericResponseType>;
  deleteInvite?: Maybe<GenericResponseType>;
  deleteInvites?: Maybe<GenericResponseType>;
  deleteLike?: Maybe<GenericResponseType>;
  deleteReaction: GenericResponseType;
  deleteUserBadge?: Maybe<GenericResponseType>;
  deleteUserPhoto?: Maybe<EntityIdResponseType>;
  duplicateEvent?: Maybe<EventModel>;
  duplicateLesson?: Maybe<GenericResponseType>;
  duplicateTopic?: Maybe<GenericResponseType>;
  editArticle?: Maybe<ArticleModel>;
  editComment?: Maybe<CommentModel>;
  eventMeetingToken?: Maybe<MeetingTokenResponse>;
  follow?: Maybe<GenericResponseType>;
  generateArticlesAttachmentUploadUrl?: Maybe<UploadUrlsType>;
  generateAttachmentUploadUrl?: Maybe<UploadUrlsType>;
  generateGroupPictureUploadUrl?: Maybe<UploadUrlsType>;
  generateUserUploadUrl?: Maybe<UploadUrlsType>;
  getHostedPage?: Maybe<Scalars["JSON"]["output"]>;
  getPrecancelPage?: Maybe<Scalars["String"]["output"]>;
  heartbeat?: Maybe<GenericResponseType>;
  initiateAssessment: InitiateAssessmentResponse;
  issueCompleteProfileNotification: GenericResponseType;
  liveTableMeetingToken: MeetingTokenResponse;
  markAllAsSeen?: Maybe<GenericResponseType>;
  markJoinEvent: GenericResponseType;
  markReportAsSeen?: Maybe<GenericResponseType>;
  notificationSeen?: Maybe<NotificationBaseModel>;
  notifyAboutStartedLiveTable: GenericResponseType;
  openDoor?: Maybe<EventModel>;
  openVideoCallDoor: VideoCallModel;
  pause: GenericResponseType;
  publishEvent?: Maybe<EventModel>;
  publishOrScheduleArticle?: Maybe<ArticleModel>;
  recoverAccount?: Maybe<GenericResponseType>;
  removeTotp: GenericResponseType;
  requestAssistance?: Maybe<GenericResponseType>;
  requestRefund?: Maybe<GenericResponseType>;
  requestResetPassword: GenericResponseType;
  resendConfirmationEmail: GenericResponseType;
  resetPassword?: Maybe<GenericResponseType>;
  restoreUsers?: Maybe<GenericResponseType>;
  resume: GenericResponseType;
  saveArticle?: Maybe<ArticleModel>;
  saveParticipantSession: GenericResponseType;
  scheduleCancelSubscription?: Maybe<GenericResponseType>;
  seenCompletedBasics?: Maybe<GenericResponseType>;
  sendLink?: Maybe<GenericResponseType>;
  sendLiveTablesFeedback: GenericResponseType;
  sendSystemNotifications?: Maybe<SystemNotificationResponseType>;
  setGroupPictureUrl?: Maybe<GenericResponseType>;
  setToken: GenericResponseType;
  shareEvent: GenericResponseType;
  subscribe?: Maybe<Scalars["JSON"]["output"]>;
  switch?: Maybe<Scalars["JSON"]["output"]>;
  triggerAssignAllUserBadges: GenericResponseType;
  triggerBackupEventsParticipants: GenericResponseType;
  triggerChatServiceSync?: Maybe<GenericResponseType>;
  triggerRecreateDailyMeetings: GenericResponseType;
  triggerRecreateZoomMeetings: GenericResponseType;
  triggerUserDeletionAfterRetention: GenericResponseType;
  triggerUserPermissionPromotion: GenericResponseType;
  unFollow?: Maybe<GenericResponseType>;
  updateArticleAttachment?: Maybe<ArticlesAttachmentModel>;
  updateAttachment?: Maybe<EventAttachmentModel>;
  updateEvent?: Maybe<EventModel>;
  updateLiveTable: GenericResponseType;
  updateMe?: Maybe<UserModel>;
  updatePassword: GenericResponseType;
  updatePaymentMethod?: Maybe<Scalars["String"]["output"]>;
  updateUserPhoto?: Maybe<UserPhotoModel>;
  updateUserSettings?: Maybe<GenericResponseType>;
  uploadActiveDays: GenericResponseType;
  uploadArticleCover?: Maybe<ArticleModel>;
  uploadArticleImage?: Maybe<UploadUrlsType>;
  uploadEventDescriptionImage?: Maybe<UploadUrlsType>;
  uploadZineCover?: Maybe<ZineModel>;
  validateForSelectionBulkInvite: ValidateSelectionBulkInviteResponse;
  validateUsersForEmailBulkInvite?: Maybe<ValidateBulkInviteResponse>;
  verifyUser?: Maybe<GenericResponseType>;
  videoMeetingToken: MeetingTokenResponse;
}

export interface MutationActivateUserArgs {
  input: ActivateUserInput;
}

export interface MutationAddEventToGoogleCalendarArgs {
  input: AddEventCalendarInput;
}

export interface MutationAddZoomLicenseToUserArgs {
  userID: Scalars["String"]["input"];
}

export interface MutationAdminBanUserArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminCreateBannerArgs {
  input: CreateBannerInput;
}

export interface MutationAdminCreateCoverArgs {
  input: CreateCoverInput;
}

export interface MutationAdminCreateFeatureFlagArgs {
  input: CreateFeatureFlagInput;
}

export interface MutationAdminCreateIllustrationArgs {
  input: CreateIllustrationInput;
}

export interface MutationAdminCreateLessonArgs {
  input: CreateLessonInput;
}

export interface MutationAdminCreateNoteArgs {
  input: NoteInput;
  userID: Scalars["String"]["input"];
}

export interface MutationAdminCreateSectionArgs {
  input: CreateSectionInput;
}

export interface MutationAdminCreateSliderArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminCreateThemeArgs {
  input: CreateThemeInput;
}

export interface MutationAdminCreateTopicArgs {
  input: CreateTopicInput;
}

export interface MutationAdminCreateUserFeatureFlagArgs {
  input: CreateUserFeatureFlagInput;
}

export interface MutationAdminCreateVideoArgs {
  input: CreateVideoInput;
}

export interface MutationAdminCreateVideoSetArgs {
  input: CreateVideoSetInput;
}

export interface MutationAdminCreateZineCoverArgs {
  input: CreateZineInput;
}

export interface MutationAdminDeleteArticleArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteBannerArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteCoverArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteIllustrationArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteLessonArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteSectionArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteSliderArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteThemeArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteTopicArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteTopicsArgs {
  input: DeleteTopicsInput;
}

export interface MutationAdminDeleteUserFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteUserReportArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteVideoArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteVideoSetArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteZineCoverArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminEditArticleArgs {
  id: Scalars["String"]["input"];
  input: EditArticleInput;
}

export interface MutationAdminEditBannerArgs {
  id: Scalars["String"]["input"];
  input: EditBannerInput;
}

export interface MutationAdminEditCoverArgs {
  id: Scalars["String"]["input"];
  input: EditCoverInput;
}

export interface MutationAdminEditCustomNotificationArgs {
  id: Scalars["String"]["input"];
  input: EditCustomNotificationInput;
}

export interface MutationAdminEditEventArgs {
  id: Scalars["String"]["input"];
  input: EditEventInput;
}

export interface MutationAdminEditFeatureFlagArgs {
  id: Scalars["String"]["input"];
  input: EditFeatureFlagInput;
}

export interface MutationAdminEditIllustrationArgs {
  id: Scalars["String"]["input"];
  input: EditIllustrationInput;
}

export interface MutationAdminEditLessonArgs {
  id: Scalars["String"]["input"];
  input: EditLessonInput;
}

export interface MutationAdminEditSectionArgs {
  id: Scalars["String"]["input"];
  input: EditSectionInput;
}

export interface MutationAdminEditSlideArgs {
  id: Scalars["String"]["input"];
  input: EditSliderInput;
}

export interface MutationAdminEditThemeArgs {
  id: Scalars["String"]["input"];
  input: EditThemeInput;
}

export interface MutationAdminEditTopicArgs {
  id: Scalars["String"]["input"];
  input: EditTopicInput;
}

export interface MutationAdminEditUserArgs {
  id: Scalars["String"]["input"];
  input: EditUserInput;
}

export interface MutationAdminEditUserFeatureFlagArgs {
  id: Scalars["String"]["input"];
  input: EditUserFeatureFlagInput;
}

export interface MutationAdminEditVideoArgs {
  id: Scalars["String"]["input"];
  input: EditVideoInput;
}

export interface MutationAdminEditVideoSetArgs {
  id: Scalars["String"]["input"];
  input: EditVideoSetInput;
}

export interface MutationAdminEditZineCoverArgs {
  id: Scalars["String"]["input"];
  input: EditZineInput;
}

export interface MutationAdminGenerateAttachmentUploadUrlArgs {
  filename: Scalars["String"]["input"];
}

export interface MutationAdminImportUserArgs {
  input: ImportUserInput;
}

export interface MutationAdminOrderSliderArgs {
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}

export interface MutationAdminOrderVideoArgs {
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}

export interface MutationAdminOrderVideoSetArgs {
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}

export interface MutationAdminRepublishEventArgs {
  eventId: Scalars["String"]["input"];
}

export interface MutationAdminUnbanUserArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminWelcomeUserArgs {
  input: ImportUserInput;
}

export interface MutationAssignUserBadgeArgs {
  input: AssignOrDeleteBadgeInput;
}

export interface MutationAttendEventArgs {
  input: AttendEventInput;
}

export interface MutationBulkInviteWithUserIdsArgs {
  eventId: Scalars["String"]["input"];
  input: BulkInviteWithUserIdsInput;
}

export interface MutationCancelAttendanceArgs {
  input: CancelAttendanceInput;
}

export interface MutationChatAddMembersToChannelArgs {
  id: Scalars["String"]["input"];
  input: CreateChannelInput;
}

export interface MutationChatCreateChannelArgs {
  input: CreateChannelInput;
}

export interface MutationChatGiveAdminRoleArgs {
  id: Scalars["String"]["input"];
}

export interface MutationChatRemoveMembersFromChannelArgs {
  id: Scalars["String"]["input"];
  input: CreateChannelInput;
}

export interface MutationChatRenameChannelArgs {
  channelName: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
}

export interface MutationChatSendDirectMessageArgs {
  input: SendDirectChatMessageInput;
}

export interface MutationChooseSubscriptionArgs {
  input: SubscribeInput;
}

export interface MutationCloseDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationCloseVideoCallDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationCompleteVideoArgs {
  id: Scalars["String"]["input"];
}

export interface MutationConfirmInPersonEventArgs {
  id: Scalars["String"]["input"];
  input: ConfirmInPersonEventInput;
}

export interface MutationConfirmTotpArgs {
  input: ConfirmTotpInput;
}

export interface MutationContactUsArgs {
  input: ContactUsInput;
}

export interface MutationCreateAbuseArgs {
  input: ReportAbuseInput;
}

export interface MutationCreateArticleAttachmentArgs {
  articleId: Scalars["String"]["input"];
  input: CreateArticlesAttachmentInput;
}

export interface MutationCreateAttachmentArgs {
  eventID: Scalars["String"]["input"];
  input: CreateEventAttachmentInput;
}

export interface MutationCreateCommentArgs {
  input: CreateCommentInput;
  targetId: Scalars["String"]["input"];
  targetType: CommentTargetType;
}

export interface MutationCreateEventArgs {
  input: CreateEventInput;
}

export interface MutationCreateInviteArgs {
  input: CreateInviteInput;
}

export interface MutationCreateLikeArgs {
  input: CreateLikeInput;
}

export interface MutationCreateLiveTableArgs {
  input: CreateLiveTableInput;
}

export interface MutationCreateMessageArgs {
  writeMessageData: CreateMessageInput;
}

export interface MutationCreateReactionArgs {
  input: CreateReactionInput;
}

export interface MutationCreateSparkArgs {
  input: CreateSparkInput;
}

export interface MutationCreateTopicForPreviewModeArgs {
  input: TopicInPreviewInput;
}

export interface MutationCreateUserArgs {
  input: CreateUserInput;
}

export interface MutationCreateUserPhotoArgs {
  input: UpdatePhotoInput;
}

export interface MutationCreateVideoCallArgs {
  input: CreateVideoCallInput;
}

export interface MutationDeactivateArgs {
  input: DeactivateInput;
}

export interface MutationDeclineInviteArgs {
  input: DeclineInviteInput;
}

export interface MutationDeleteAccountArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteArticleArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteArticleAttachmentArgs {
  attachmentId: Scalars["String"]["input"];
}

export interface MutationDeleteAttachmentArgs {
  attachmentID: Scalars["String"]["input"];
}

export interface MutationDeleteCommentArgs {
  commentID: Scalars["String"]["input"];
}

export interface MutationDeleteEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteInviteArgs {
  inviteId: Scalars["String"]["input"];
}

export interface MutationDeleteInvitesArgs {
  input: DeleteInvitesInput;
}

export interface MutationDeleteLikeArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteReactionArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteUserBadgeArgs {
  input: AssignOrDeleteBadgeInput;
}

export interface MutationDeleteUserPhotoArgs {
  input: DeletePhotoInput;
}

export interface MutationDuplicateEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDuplicateLessonArgs {
  input: DuplicateLessonInput;
}

export interface MutationDuplicateTopicArgs {
  id: Scalars["String"]["input"];
}

export interface MutationEditArticleArgs {
  id: Scalars["String"]["input"];
  input: EditArticleInput;
}

export interface MutationEditCommentArgs {
  commentID: Scalars["String"]["input"];
  input: EditCommentInput;
}

export interface MutationEventMeetingTokenArgs {
  input: DailyMeetingInput;
}

export interface MutationFollowArgs {
  input: FollowInput;
}

export interface MutationGenerateArticlesAttachmentUploadUrlArgs {
  articleId: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
}

export interface MutationGenerateAttachmentUploadUrlArgs {
  eventID: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
}

export interface MutationGenerateGroupPictureUploadUrlArgs {
  input: UploadPictureInput;
}

export interface MutationGenerateUserUploadUrlArgs {
  input: UploadPhotoInput;
}

export interface MutationGetHostedPageArgs {
  id: Scalars["String"]["input"];
}

export interface MutationHeartbeatArgs {
  input: UpdateUserInput;
}

export interface MutationLiveTableMeetingTokenArgs {
  input: DailyMeetingInput;
}

export interface MutationMarkJoinEventArgs {
  eventId: Scalars["String"]["input"];
}

export interface MutationNotificationSeenArgs {
  id: Scalars["String"]["input"];
}

export interface MutationNotifyAboutStartedLiveTableArgs {
  input: LiveTableStartedMessageInput;
}

export interface MutationOpenDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationOpenVideoCallDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationPublishEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationPublishOrScheduleArticleArgs {
  id: Scalars["String"]["input"];
  input: PublishArticleInput;
}

export interface MutationRemoveTotpArgs {
  totpId: Scalars["String"]["input"];
}

export interface MutationRequestAssistanceArgs {
  input: AssistanceReasonsInput;
}

export interface MutationRequestRefundArgs {
  input: RequestRefundInput;
}

export interface MutationRequestResetPasswordArgs {
  input: RequestPasswordInput;
}

export interface MutationResendConfirmationEmailArgs {
  input: ResendConfirmationEmailInput;
}

export interface MutationResetPasswordArgs {
  input: ResetPasswordInput;
}

export interface MutationRestoreUsersArgs {
  userIds: Array<Scalars["String"]["input"]>;
}

export interface MutationSaveArticleArgs {
  id: Scalars["String"]["input"];
  input: SaveDraftArticleInput;
}

export interface MutationSaveParticipantSessionArgs {
  meetingSessionId: Scalars["String"]["input"];
  participantId: Scalars["String"]["input"];
}

export interface MutationScheduleCancelSubscriptionArgs {
  input: RequestRefundInput;
}

export interface MutationSendLinkArgs {
  input: FriendInput;
}

export interface MutationSendLiveTablesFeedbackArgs {
  input: LiveTablesFeedbackInput;
}

export interface MutationSendSystemNotificationsArgs {
  emails: Array<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
}

export interface MutationSetGroupPictureUrlArgs {
  input: SetPictureInput;
}

export interface MutationSetTokenArgs {
  input: Scalars["String"]["input"];
}

export interface MutationShareEventArgs {
  eventId: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
}

export interface MutationSubscribeArgs {
  input: SubscribeInput;
}

export interface MutationSwitchArgs {
  input: SubscribeInput;
}

export interface MutationUnFollowArgs {
  input: FollowInput;
}

export interface MutationUpdateArticleAttachmentArgs {
  attachmentId: Scalars["String"]["input"];
  input: EditArticlesAttachmentInput;
}

export interface MutationUpdateAttachmentArgs {
  attachmentID: Scalars["String"]["input"];
  input: UpdateEventAttachmentInput;
}

export interface MutationUpdateEventArgs {
  id: Scalars["String"]["input"];
  input: UpdateEventInput;
}

export interface MutationUpdateLiveTableArgs {
  id: Scalars["String"]["input"];
  input: UpdateLiveTableInput;
}

export interface MutationUpdateMeArgs {
  input: UpdateUserInput;
}

export interface MutationUpdatePasswordArgs {
  input: UpdatePasswordInput;
}

export interface MutationUpdateUserPhotoArgs {
  input: UpdatePhotoInput;
  photoID: Scalars["String"]["input"];
}

export interface MutationUpdateUserSettingsArgs {
  input: UpdateSettingsInput;
}

export interface MutationUploadActiveDaysArgs {
  input: UploadActiveDaysInput;
}

export interface MutationUploadArticleCoverArgs {
  input: UploadImageArticleInput;
}

export interface MutationUploadArticleImageArgs {
  input: UploadImageArticleInput;
}

export interface MutationUploadEventDescriptionImageArgs {
  id: Scalars["String"]["input"];
  input: UploadEventImageInput;
}

export interface MutationUploadZineCoverArgs {
  input: UploadCoverZineInput;
}

export interface MutationValidateForSelectionBulkInviteArgs {
  eventId: Scalars["String"]["input"];
  filters: AdminFilterUsersInput;
}

export interface MutationValidateUsersForEmailBulkInviteArgs {
  eventId: Scalars["String"]["input"];
  input: BulkInviteWithEmailsInput;
}

export interface MutationVerifyUserArgs {
  id: Scalars["String"]["input"];
  input: VerifyUserInput;
}

export interface MutationVideoMeetingTokenArgs {
  input: DailyMeetingInput;
}

export enum MutationStatus {
  Failed = "FAILED",
  Success = "SUCCESS",
}

export interface NoteInput {
  description: Scalars["String"]["input"];
}

export interface NoteModel {
  createdAt: Scalars["Date"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export interface NotificationBaseModel {
  articleNotification?: Maybe<ArticleNotificationModel>;
  badgeNotification?: Maybe<BadgeNotificationModel>;
  chatMentionNotification?: Maybe<ChatMentionNotificationModel>;
  commentNotification?: Maybe<CommentNotificationModel>;
  createdAt: Scalars["Date"]["output"];
  eventNotification?: Maybe<EventNotificationModel>;
  friendJoinedNotification?: Maybe<FriendJoinedNotificationModel>;
  id: Scalars["String"]["output"];
  monthlyReportNotification?: Maybe<MonthlyReportNotificationModel>;
  notificationSeen?: Maybe<Array<NotificationSeenModel>>;
  profileUpdateNotification?: Maybe<ProfileUpdateNotificationModel>;
  systemNotification?: Maybe<SystemNotificationModel>;
  type: NotificationType;
  updatedAt: Scalars["Date"]["output"];
}

export interface NotificationCursorPaginatedInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  maxDate?: InputMaybe<Scalars["Date"]["input"]>;
}

export interface NotificationSeenModel {
  id: Scalars["String"]["output"];
  seenAt?: Maybe<Scalars["Date"]["output"]>;
  user: UserModel;
}

export enum NotificationType {
  Article = "Article",
  Badge = "Badge",
  ChatMention = "ChatMention",
  Comment = "Comment",
  Event = "Event",
  FriendJoined = "FriendJoined",
  MonthlyReport = "MonthlyReport",
  ProfileUpdate = "ProfileUpdate",
  System = "System",
}

export interface OccupationInput {
  id: Scalars["String"]["input"];
  industry?: InputMaybe<IndustryInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
}

export enum OccupationMatch {
  Different = "Different",
  Same = "Same",
  SharedIndustry = "SharedIndustry",
}

export interface OccupationModel {
  id: Scalars["String"]["output"];
  industry?: Maybe<Industry>;
  name?: Maybe<Scalars["String"]["output"]>;
}

export enum Onboarding {
  Availability = "AVAILABILITY",
  Avatar = "AVATAR",
  Complete = "COMPLETE",
  EnglishLevel = "ENGLISH_LEVEL",
  EnglishSkills = "ENGLISH_SKILLS",
  Interests = "INTERESTS",
  Location = "LOCATION",
  Name = "NAME",
  Occupation = "OCCUPATION",
  Story = "STORY",
}

export interface OrderArticlesInput {
  by?: InputMaybe<ArticleOrderBy>;
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface OrderEventInput {
  by?: InputMaybe<EventsOrderBy>;
  sort?: InputMaybe<SortDirection>;
}

export interface PaginatedArticles {
  items?: Maybe<Array<ArticleModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedAssessmentResult {
  items?: Maybe<Array<AssessmentResultModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedAssessmentResultInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface PaginatedBanners {
  items?: Maybe<Array<BannerModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedCovers {
  items?: Maybe<Array<CoverModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedCustomNotifications {
  items?: Maybe<Array<CustomNotificationModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedEvent {
  items?: Maybe<Array<EventModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedFeatureFlags {
  items?: Maybe<Array<FeatureFlagModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedIllustrations {
  items?: Maybe<Array<IllustrationModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedMatchedUser {
  items?: Maybe<Array<UserMatchingModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedReportsUser {
  items?: Maybe<Array<AbuseModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedThemes {
  items?: Maybe<Array<ThemeModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedTopics {
  items?: Maybe<Array<TopicModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedUser {
  items?: Maybe<Array<UserModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedVideoSets {
  items?: Maybe<Array<VideoSetModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedZines {
  items?: Maybe<Array<ZineModel>>;
  total: Scalars["Int"]["output"];
}

export enum ParticipationStatus {
  Attending = "Attending",
  Declined = "Declined",
  Invited = "Invited",
  NotInvited = "NotInvited",
}

export interface ParticipationStatusInput {
  eventId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export interface ParticipationStatusResult {
  status: ParticipationStatus;
}

export interface PauseResponse {
  canPause: Scalars["Boolean"]["output"];
  pauseCredits: Scalars["Int"]["output"];
}

export interface PaymentMethod {
  email?: Maybe<Scalars["String"]["output"]>;
  expMonth?: Maybe<Scalars["Int"]["output"]>;
  expYear?: Maybe<Scalars["Int"]["output"]>;
  last4Digits?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
}

export interface ProfileUpdateNotificationModel {
  id: Scalars["String"]["output"];
  type: ProfileUpdateNotificationType;
}

export enum ProfileUpdateNotificationType {
  CompleteProfile = "CompleteProfile",
  UpdateAvailability = "UpdateAvailability",
}

export interface PublishArticleInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  myEnglishJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishedAt?: InputMaybe<Scalars["Date"]["input"]>;
  scheduledFor?: InputMaybe<Scalars["Date"]["input"]>;
  tags?: InputMaybe<Array<Interest>>;
  theme?: InputMaybe<ArticleThemes>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface Query {
  adminGetArticle: ArticleModel;
  adminGetBanner: BannerModel;
  adminGetCover: CoverModel;
  adminGetFeatureFlag: FeatureFlagModel;
  adminGetGroupedOccupations: Array<OccupationModel>;
  adminGetIllustration: IllustrationModel;
  adminGetLesson: LessonModel;
  adminGetOccupations: Array<OccupationModel>;
  adminGetPlanDetails: CbPlanDetails;
  adminGetSelectedUsers: Array<UserModel>;
  adminGetSlide: SliderModel;
  adminGetTheme: ThemeModel;
  adminGetTopic: TopicModel;
  adminGetTopicByLessonId: TopicModel;
  adminGetUserFeatureFlag: UserFlagModel;
  adminGetVideoSet?: Maybe<VideoSetModel>;
  adminGetZineCover: ZineModel;
  adminListCovers: Array<CoverModel>;
  adminListIllustrations: Array<IllustrationModel>;
  adminListPaginatedArticles: PaginatedArticles;
  adminListPaginatedBanners: PaginatedBanners;
  adminListPaginatedCovers: PaginatedCovers;
  adminListPaginatedCustomNotifications: PaginatedCustomNotifications;
  adminListPaginatedFeatureFlags: PaginatedFeatureFlags;
  adminListPaginatedIllustrations: PaginatedIllustrations;
  adminListPaginatedReportsUsers: PaginatedReportsUser;
  adminListPaginatedThemes: PaginatedThemes;
  adminListPaginatedTopics: PaginatedTopics;
  adminListPaginatedVideoSets: PaginatedVideoSets;
  adminListPaginatedZineCovers: PaginatedZines;
  adminListThemes: Array<ThemeModel>;
  adminListTopics: Array<TopicModel>;
  adminListUserFeatureFlags: Array<UserFlagModel>;
  adminListUsers: PaginatedUser;
  adminSearchEvents: PaginatedEvent;
  articlesWritten: Scalars["Int"]["output"];
  attendanceRecord: AttendanceRecord;
  attendedEvents: AttendedEvents;
  canPause: PauseResponse;
  chatCreateUserToken: Scalars["Any"]["output"];
  communityDistinctSparks: Scalars["Int"]["output"];
  completedVerificationForm: Array<VideoAskModel>;
  dailyEvents: Array<EventsPerDay>;
  friendlyFaces: Array<FriendlyFaces>;
  getArticle: ArticleModel;
  getAssessmentResultById?: Maybe<AssessmentResultModel>;
  getAssessmentResultsCount: Scalars["Int"]["output"];
  getAvalibilityMatchForUsers: Array<AvailabilityMatchForUsers>;
  getChannelVideoCall?: Maybe<VideoCallModel>;
  getComment?: Maybe<CommentModel>;
  getCurrentBanner?: Maybe<BannerModel>;
  getCurrentZine?: Maybe<ZineModel>;
  getCustomNotification: CustomNotificationModel;
  getEvent: EventModel;
  getEventAdmin?: Maybe<EventModel>;
  getFriend: UserModel;
  getGroupedOccupations: Array<OccupationModel>;
  getInvitedFriendsCount: Scalars["Int"]["output"];
  getInvitedFriendsStats: InvitedFriendsStats;
  getLastAssessmentResult?: Maybe<AssessmentResultModel>;
  getLiveTableById: LiveTableModel;
  getLiveTables: Array<LiveTablesResponse>;
  getOccupations: Array<OccupationModel>;
  getParticipationStatus: ParticipationStatusResult;
  getPopupNotifications: Array<NotificationBaseModel>;
  getPreviousAssessmentScore?: Maybe<Scalars["Float"]["output"]>;
  getTheme: ThemeModel;
  getTopic: TopicModel;
  getTotalDistinctSparks: Scalars["Int"]["output"];
  getUser: UserModel;
  getUserAdmin?: Maybe<UserModel>;
  getUserAssessmentResults: PaginatedAssessmentResult;
  getUserMatchingInfo: UserMatchingModel;
  getUserSettings: UserSettingsModel;
  getUserSignUp: UserSignUpModel;
  getUsersWithSameSpeakingLevel: UsersWithSameSpeakingLevel;
  getVideoCall: VideoCallModel;
  getVideos: Array<VideoModel>;
  globalNetwork: Array<Coordinates>;
  hostedEvents: Scalars["Int"]["output"];
  invoices?: Maybe<Array<Invoice>>;
  ladiesAttended: Scalars["Int"]["output"];
  listArticles: PaginatedArticles;
  listAttachment: Array<EventAttachmentModel>;
  listMatchedUsers: PaginatedMatchedUser;
  listMessages: Array<MessageModel>;
  listNotificationsForUser: CursorPaginatedNotifications;
  listPaginatedAssessmentResults: PaginatedAssessmentResult;
  listPaginatedEvents: PaginatedEvent;
  listRecommendedEvents: Array<EventModel>;
  listRecommendedUsers: Array<UserMatchingModel>;
  listThemes: Array<ThemeModel>;
  listTopics: Array<TopicModel>;
  listTotp: Array<UserTotpModel>;
  listUserPhotos: Array<UserPhotoModel>;
  me: UserModel;
  monthlyRecapSteps: Array<MonthlyRecapSteps>;
  participationTime: Scalars["Int"]["output"];
  paymentMethod?: Maybe<Array<PaymentMethod>>;
  planDetails?: Maybe<CbPlanDetails>;
  searchMemberzine: Array<ArticleSearchResult>;
  searchTopics: Array<TopicSearchResult>;
  searchUsers: Array<UserSearchResult>;
  seenMonthlyReport: Scalars["Boolean"]["output"];
  userAttendedDistinctSparks: Scalars["Int"]["output"];
  userDistinctSparks: Scalars["Int"]["output"];
  userGaveSparkInEvent: Scalars["Boolean"]["output"];
  userHasNoAcceptedInvites: Scalars["Boolean"]["output"];
  userHostedDistinctSparks: Scalars["Int"]["output"];
  userHostedEvents: PaginatedEvent;
  userHostedEventsToShareCount: Scalars["Int"]["output"];
}

export interface QueryAdminGetArticleArgs {
  articleID: Scalars["String"]["input"];
}

export interface QueryAdminGetBannerArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetCoverArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetIllustrationArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetLessonArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetPlanDetailsArgs {
  userId: Scalars["String"]["input"];
}

export interface QueryAdminGetSelectedUsersArgs {
  filters: AdminFilterUsersInput;
}

export interface QueryAdminGetSlideArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetThemeArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetTopicArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetTopicByLessonIdArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetUserFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetVideoSetArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetZineCoverArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminListPaginatedArticlesArgs {
  filters: FilterArticlesInput;
  order: OrderArticlesInput;
}

export interface QueryAdminListPaginatedBannersArgs {
  filters: AdminFilterBannersInput;
}

export interface QueryAdminListPaginatedCoversArgs {
  filters: AdminFilterCoversInput;
}

export interface QueryAdminListPaginatedCustomNotificationsArgs {
  filters: FilterCustomNotificationsInput;
}

export interface QueryAdminListPaginatedFeatureFlagsArgs {
  filters: AdminFilterFeatureFlagsInput;
}

export interface QueryAdminListPaginatedIllustrationsArgs {
  filters: AdminFilterIllustrationsInput;
}

export interface QueryAdminListPaginatedReportsUsersArgs {
  filters: AdminFilterReportsUserFilterInput;
}

export interface QueryAdminListPaginatedThemesArgs {
  filters: AdminFilterThemesInput;
}

export interface QueryAdminListPaginatedTopicsArgs {
  filters: AdminFilterTopicsInput;
}

export interface QueryAdminListPaginatedVideoSetsArgs {
  filters: AdminFilterVideoSetInput;
}

export interface QueryAdminListPaginatedZineCoversArgs {
  filters: AdminFilterZineCoversInput;
}

export interface QueryAdminListUsersArgs {
  filters: AdminFilterUsersInput;
}

export interface QueryAdminSearchEventsArgs {
  filters: AdminFilterEventsInput;
}

export interface QueryArticlesWrittenArgs {
  input: StatisticsInput;
}

export interface QueryAttendanceRecordArgs {
  input: StatisticsInput;
}

export interface QueryAttendedEventsArgs {
  input: StatisticsInput;
}

export interface QueryCommunityDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryDailyEventsArgs {
  input: StatisticsInput;
}

export interface QueryFriendlyFacesArgs {
  input: StatisticsInput;
}

export interface QueryGetArticleArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetAssessmentResultByIdArgs {
  assessmentResultId: Scalars["String"]["input"];
}

export interface QueryGetAvalibilityMatchForUsersArgs {
  input: AvailabilityForUsersInput;
}

export interface QueryGetChannelVideoCallArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetCommentArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetCustomNotificationArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetEventArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetEventAdminArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetFriendArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetInvitedFriendsStatsArgs {
  userId: Scalars["String"]["input"];
}

export interface QueryGetLiveTableByIdArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetParticipationStatusArgs {
  input: ParticipationStatusInput;
}

export interface QueryGetPreviousAssessmentScoreArgs {
  assessmentDate: Scalars["Date"]["input"];
}

export interface QueryGetThemeArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetTopicArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserAdminArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserAssessmentResultsArgs {
  filters: PaginatedAssessmentResultInput;
  userId: Scalars["String"]["input"];
}

export interface QueryGetUserMatchingInfoArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserSignUpArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUsersWithSameSpeakingLevelArgs {
  speakingLevel: SpeakingLevelCategories;
}

export interface QueryGetVideoCallArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGlobalNetworkArgs {
  input: StatisticsInput;
}

export interface QueryHostedEventsArgs {
  input: StatisticsInput;
}

export interface QueryLadiesAttendedArgs {
  input: StatisticsInput;
}

export interface QueryListArticlesArgs {
  filters: FilterArticlesInput;
}

export interface QueryListAttachmentArgs {
  eventID: Scalars["String"]["input"];
}

export interface QueryListMatchedUsersArgs {
  filters: FilterUsersInput;
}

export interface QueryListNotificationsForUserArgs {
  cursor: NotificationCursorPaginatedInput;
  unreadOnly: Scalars["Boolean"]["input"];
}

export interface QueryListPaginatedAssessmentResultsArgs {
  filters: PaginatedAssessmentResultInput;
}

export interface QueryListPaginatedEventsArgs {
  filters: FilterPaginatedEventInput;
  order?: InputMaybe<OrderEventInput>;
}

export interface QueryListThemesArgs {
  filters: FilterThemesInput;
}

export interface QueryListTopicsArgs {
  filters: FilterTopicsInput;
}

export interface QueryListUserPhotosArgs {
  userID: Scalars["String"]["input"];
}

export interface QueryMonthlyRecapStepsArgs {
  input: StatisticsInput;
}

export interface QueryParticipationTimeArgs {
  input: StatisticsInput;
}

export interface QuerySearchMemberzineArgs {
  query: Scalars["String"]["input"];
}

export interface QuerySearchTopicsArgs {
  query: Scalars["String"]["input"];
}

export interface QuerySearchUsersArgs {
  eventId?: InputMaybe<Scalars["String"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  query: Scalars["String"]["input"];
}

export interface QueryUserAttendedDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryUserDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryUserGaveSparkInEventArgs {
  eventId: Scalars["String"]["input"];
}

export interface QueryUserHostedDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryUserHostedEventsArgs {
  filters: FilterPaginatedEventInput;
  order?: InputMaybe<OrderEventInput>;
}

export interface ReadArticleModel {
  article: ArticleModel;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export enum RefundReasons {
  Assistance = "ASSISTANCE",
  Custom = "CUSTOM",
  HelpFindingEvents = "HELP_FINDING_EVENTS",
  HelpFindingPeople = "HELP_FINDING_PEOPLE",
  NeedMoreTime = "NEED_MORE_TIME",
  SomethingElse = "SOMETHING_ELSE",
}

export interface ReportAbuseInput {
  abuserID: Scalars["String"]["input"];
  articleId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  reason: UserReportReason;
}

export interface RequestPasswordInput {
  email: Scalars["String"]["input"];
}

export interface RequestRefundInput {
  customReason?: InputMaybe<Scalars["String"]["input"]>;
  reason: Array<RefundReasons>;
}

export interface ResendConfirmationEmailInput {
  email: Scalars["String"]["input"];
}

export interface ResetPasswordInput {
  newPassword: Scalars["String"]["input"];
  resetToken: Scalars["String"]["input"];
}

export interface ResponsiveInput {
  desktop?: InputMaybe<Scalars["String"]["input"]>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ResponsiveType {
  desktop?: Maybe<Scalars["String"]["output"]>;
  mobile?: Maybe<Scalars["String"]["output"]>;
}

export interface SaveDraftArticleInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  myEnglishJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishedAt?: InputMaybe<Scalars["Date"]["input"]>;
  scheduledFor?: InputMaybe<Scalars["Date"]["input"]>;
  tags?: InputMaybe<Array<Interest>>;
  theme?: InputMaybe<ArticleThemes>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SearchMatch {
  indices: Array<Array<Scalars["Int"]["output"]>>;
  key?: Maybe<Scalars["String"]["output"]>;
  score?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
}

export interface SectionModel {
  createdAt: Scalars["Date"]["output"];
  elements?: Maybe<Array<Element>>;
  id: Scalars["String"]["output"];
  lesson: LessonModel;
  order?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface SendDirectChatMessageInput {
  receiverId: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
}

export interface SetPictureInput {
  channelId: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface SliderModel {
  background?: Maybe<ResponsiveType>;
  banner: BannerModel;
  buttonText?: Maybe<Scalars["String"]["output"]>;
  buttonURL?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  featureImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  imageAlignment?: Maybe<ImageAlignment>;
  position?: Maybe<Scalars["Int"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  textColour?: Maybe<TextColour>;
  title?: Maybe<Scalars["String"]["output"]>;
  titleStyle?: Maybe<TitleStyle>;
  updatedAt: Scalars["Date"]["output"];
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export interface SparkModel {
  createdAt: Scalars["Date"]["output"];
  event?: Maybe<EventModel>;
  id: Scalars["String"]["output"];
  liveTable?: Maybe<LiveTableModel>;
  user: UserModel;
  videoCall?: Maybe<VideoCallModel>;
}

export enum SpeakingLevelCategories {
  AdvancedHigh = "AdvancedHigh",
  AdvancedLow = "AdvancedLow",
  AdvancedMid = "AdvancedMid",
  Dependent = "Dependent",
  IntermediateHigh = "IntermediateHigh",
  IntermediateLow = "IntermediateLow",
  IntermediateMid = "IntermediateMid",
  Proficient = "Proficient",
}

export interface StatisticsInput {
  end: Scalars["Date"]["input"];
  start: Scalars["Date"]["input"];
  userId: Scalars["String"]["input"];
}

export interface SubscribeInput {
  charges?: InputMaybe<Array<Scalars["String"]["input"]>>;
  couponIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  planId?: InputMaybe<Scalars["String"]["input"]>;
  redirectPage?: InputMaybe<Scalars["String"]["input"]>;
  referredById?: InputMaybe<Scalars["String"]["input"]>;
  signUpId?: InputMaybe<Scalars["String"]["input"]>;
  trial?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface Subscription {
  assessmentChargesAreSavedForUser: Scalars["String"]["output"];
  assessmentResultsAreSaved: AssessmentResultModel;
  channelVideoCall: VideoCallModel;
  commentCreated: CommentModel;
  commentDeleted: CommentModel;
  communitySparkCreated: SparkModel;
  eventUpdated: EventModel;
  liveTableAboutToEnd: LiveTableModel;
  liveTableHasEnded: LiveTableModel;
  liveTableHasStarted: LiveTableStartedMessage;
  liveTableUpdated: LiveTableModel;
  notificationAdded: NotificationBaseModel;
  popupNotificationAdded: NotificationBaseModel;
  reactionCreated: CommentReactionsModel;
  reactionDeleted: CommentReactionsModel;
  sparkCreated: SparkModel;
  videoAskFormCreated: VideoAskModel;
  videoCallUpdated: VideoCallModel;
}

export interface SubscriptionAssessmentChargesAreSavedForUserArgs {
  userId: Scalars["String"]["input"];
}

export interface SubscriptionAssessmentResultsAreSavedArgs {
  assessmentId: Scalars["String"]["input"];
}

export interface SubscriptionChannelVideoCallArgs {
  id: Scalars["String"]["input"];
}

export interface SubscriptionCommentCreatedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionCommentDeletedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionEventUpdatedArgs {
  eventId: Scalars["String"]["input"];
}

export interface SubscriptionLiveTableAboutToEndArgs {
  liveTableId: Scalars["String"]["input"];
}

export interface SubscriptionLiveTableHasEndedArgs {
  liveTableId: Scalars["String"]["input"];
}

export interface SubscriptionLiveTableUpdatedArgs {
  liveTableId: Scalars["String"]["input"];
}

export interface SubscriptionReactionCreatedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionReactionDeletedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionSparkCreatedArgs {
  eventId: Scalars["String"]["input"];
}

export interface SubscriptionVideoCallUpdatedArgs {
  id: Scalars["String"]["input"];
}

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  Future = "FUTURE",
  Incomplete = "INCOMPLETE",
  IncompleteExpired = "INCOMPLETE_EXPIRED",
  InTrial = "IN_TRIAL",
  NonRenewing = "NON_RENEWING",
  PastDue = "PAST_DUE",
  Paused = "PAUSED",
  Unpaid = "UNPAID",
}

export enum SubscriptionTier {
  Free = "Free",
  Study = "Study",
  Supporter = "Supporter",
}

export interface SystemNotificationModel {
  customNotification: CustomNotificationModel;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export interface SystemNotificationResponseType {
  sentTo: Scalars["Int"]["output"];
  warningMessages: Array<Scalars["String"]["output"]>;
}

export enum TextColour {
  Dark = "Dark",
  Light = "Light",
}

export interface ThemeModel {
  cover?: Maybe<CoverModel>;
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isCurrent?: Maybe<Scalars["Boolean"]["output"]>;
  isGrammarLibrary?: Maybe<Scalars["Boolean"]["output"]>;
  isResource?: Maybe<Scalars["Boolean"]["output"]>;
  isStandard?: Maybe<Scalars["Boolean"]["output"]>;
  isTopicLibrary?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  topics?: Maybe<Array<TopicModel>>;
  updatedAt: Scalars["Date"]["output"];
}

export enum TitleStyle {
  Bold = "Bold",
  Standard = "Standard",
}

export interface TopicInPreviewInput {
  lessonKind?: InputMaybe<Scalars["Int"]["input"]>;
  lessonTitle?: InputMaybe<Scalars["String"]["input"]>;
  sectionsData?: InputMaybe<Array<EditSectionInput>>;
  topicId: Scalars["String"]["input"];
}

export interface TopicModel {
  comments?: Maybe<Array<CommentModel>>;
  createdAt: Scalars["Date"]["output"];
  duplicateOf?: Maybe<Scalars["String"]["output"]>;
  events?: Maybe<Array<EventModel>>;
  id: Scalars["String"]["output"];
  ideasToDiscuss: Array<Scalars["String"]["output"]>;
  illustration?: Maybe<IllustrationModel>;
  isPreviewMode?: Maybe<Scalars["Boolean"]["output"]>;
  lessons?: Maybe<Array<LessonModel>>;
  likes?: Maybe<Array<LikeModel>>;
  stars?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<TopicStatus>;
  theme?: Maybe<ThemeModel>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface TopicSearchResult {
  item: TopicModel;
  matches?: Maybe<Array<SearchMatch>>;
  refIndex: Scalars["Int"]["output"];
}

export enum TopicStatus {
  ComingSoon = "COMING_SOON",
  Current = "CURRENT",
  Inactive = "INACTIVE",
}

export interface UpdateEventAttachmentInput {
  description: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface UpdateEventInput {
  conversationKind?: InputMaybe<ConversationKind>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  customTopic?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  englishLevel?: InputMaybe<EventEnglishLevel>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  eventCategory?: InputMaybe<EventCategory>;
  hostID?: InputMaybe<Scalars["String"]["input"]>;
  ideasToDiscuss?: InputMaybe<Array<Scalars["String"]["input"]>>;
  illustrationUrl?: InputMaybe<Scalars["String"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForConfidentUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForFoundation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForRise?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForUnverified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventKind>;
  links?: InputMaybe<Array<LinkInput>>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  locationLat?: InputMaybe<Scalars["Float"]["input"]>;
  locationLong?: InputMaybe<Scalars["Float"]["input"]>;
  maxParticipants?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  occupationIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  permissions?: InputMaybe<Array<EventPermissions>>;
  recurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpdateLiveTableInput {
  englishLevel?: InputMaybe<EventEnglishLevelNamed>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpdatePasswordInput {
  currentPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}

export interface UpdatePhotoInput {
  url: Scalars["String"]["input"];
}

export interface UpdateSettingsInput {
  emailNotifications?: InputMaybe<EmailNotifications>;
  receiveChatMentionsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveChatMentionsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRemindersEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRemindersNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRsvpEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRsvpNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventUpdatesAndInvitationsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventUpdatesAndInvitationsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveFollowsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveFollowsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveLiveTableStartedNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveMentionsAndCommentsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveMentionsAndCommentsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receivePlatformStatusEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveSubscriptionChangesEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface UpdateUserInput {
  availability?: InputMaybe<Array<AvailabilityInput>>;
  avatarUrl?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  cambridgeEnglishLevel?: InputMaybe<EnglishLevel>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  cityId?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isAgeVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastOnlineAt?: InputMaybe<Scalars["Date"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  long?: InputMaybe<Scalars["Float"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  nativeLanguage?: InputMaybe<Scalars["String"]["input"]>;
  needSpeakingPartner?: InputMaybe<Scalars["Boolean"]["input"]>;
  occupationId?: InputMaybe<Scalars["String"]["input"]>;
  otherLanguage?: InputMaybe<Scalars["String"]["input"]>;
  showBirthday?: InputMaybe<Scalars["Boolean"]["input"]>;
  showBirthdayYear?: InputMaybe<Scalars["Boolean"]["input"]>;
  speakingLevelScore?: InputMaybe<Scalars["Float"]["input"]>;
  story?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  wantsNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface UploadActiveDaysInput {
  file: Scalars["Upload_Scalar"]["input"];
}

export interface UploadCoverZineInput {
  file: Scalars["Upload_Scalar"]["input"];
  zineId: Scalars["String"]["input"];
}

export interface UploadEventImageInput {
  file: Scalars["Upload_Scalar"]["input"];
  height?: InputMaybe<Scalars["Int"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface UploadImageArticleInput {
  articleId: Scalars["String"]["input"];
  cropHeight?: InputMaybe<Scalars["Int"]["input"]>;
  cropWidth?: InputMaybe<Scalars["Int"]["input"]>;
  file: Scalars["Upload_Scalar"]["input"];
  height?: InputMaybe<Scalars["Int"]["input"]>;
  left?: InputMaybe<Scalars["Int"]["input"]>;
  top?: InputMaybe<Scalars["Int"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface UploadPhotoInput {
  filename: Scalars["String"]["input"];
}

export interface UploadPictureInput {
  channelId: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
}

export interface UploadUrlsType {
  contentURL?: Maybe<Scalars["String"]["output"]>;
  uploadURL?: Maybe<Scalars["String"]["output"]>;
}

export enum UserBadge {
  BigSister = "BIG_SISTER",
  CommunitySuccess = "COMMUNITY_SUCCESS",
  EnglishCoach = "ENGLISH_COACH",
  Founder = "FOUNDER",
  InactiveMember = "INACTIVE_MEMBER",
  LittleSister = "LITTLE_SISTER",
  Member = "MEMBER",
  MemberSupport = "MEMBER_SUPPORT",
  TechnicalSupport = "TECHNICAL_SUPPORT",
}

export interface UserBadgeModel {
  achievedDate?: Maybe<Scalars["Date"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  type: UserBadgeType;
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export enum UserBadgeType {
  Ambassador = "Ambassador",
  ArticlesPublished_1 = "ArticlesPublished_1",
  BigSister = "BigSister",
  BroughtAFriend = "BroughtAFriend",
  CertifiedHost = "CertifiedHost",
  DiamondAmbassador = "DiamondAmbassador",
  EventsParticipated_10 = "EventsParticipated_10",
  EventsParticipated_100 = "EventsParticipated_100",
  EventsParticipated_500 = "EventsParticipated_500",
  EventsParticipated_1000 = "EventsParticipated_1000",
  FoundingMember = "FoundingMember",
  HeyLadyWay = "HeyLadyWay",
  Hosted_1 = "Hosted_1",
  Hosted_10 = "Hosted_10",
  Hosted_50 = "Hosted_50",
  Hosted_100 = "Hosted_100",
  Hosted_500 = "Hosted_500",
  LegendaryLady = "LegendaryLady",
  Membership_1 = "Membership_1",
  Membership_2 = "Membership_2",
  Membership_3 = "Membership_3",
  PerfectProfile = "PerfectProfile",
  PlatinumAmbassador = "PlatinumAmbassador",
  Verified = "Verified",
}

export enum UserDeactivationReason {
  Custom = "CUSTOM",
  HardToUse = "HARD_TO_USE",
  NoSpeakingPartner = "NO_SPEAKING_PARTNER",
  NoTimeForEnglishPractice = "NO_TIME_FOR_ENGLISH_PRACTICE",
  ToExpensive = "TO_EXPENSIVE",
  TutorOrLanguageCourse = "TUTOR_OR_LANGUAGE_COURSE",
}

export interface UserEngagementMetrics {
  attendanceMetrics?: Maybe<AttendanceMetrics>;
  attendanceRatio?: Maybe<Scalars["Float"]["output"]>;
  publishedArticles?: Maybe<Scalars["Int"]["output"]>;
  recentArticles?: Maybe<Array<ArticleModel>>;
  totalHostedParticipants?: Maybe<Scalars["Int"]["output"]>;
  totalRsvps?: Maybe<Scalars["Int"]["output"]>;
}

export interface UserFlagModel {
  featureFlag: FeatureFlagModel;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export interface UserInviteStatus {
  inviteStatus: InviteStatus;
  userId: Scalars["String"]["input"];
  withHostedEvents?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface UserLocation {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UserMatchingModel {
  defaultMatchScore?: Maybe<Scalars["Float"]["output"]>;
  matchDetails?: Maybe<MatchDetails>;
  matchScores?: Maybe<MatchScores>;
  matchWeights?: Maybe<MatchScores>;
  score?: Maybe<Scalars["Float"]["output"]>;
  user: UserModel;
  userId: Scalars["String"]["output"];
}

export interface UserModel {
  abusers?: Maybe<Array<AbuseModel>>;
  activationKey?: Maybe<Scalars["String"]["output"]>;
  availability?: Maybe<Array<Availability>>;
  availabilityWeekdayFrom?: Maybe<Scalars["Int"]["output"]>;
  availabilityWeekdayUntil?: Maybe<Scalars["Int"]["output"]>;
  availabilityWeekendsFrom?: Maybe<Scalars["Int"]["output"]>;
  availabilityWeekendsUntil?: Maybe<Scalars["Int"]["output"]>;
  availableAssessmentCharges?: Maybe<Scalars["Int"]["output"]>;
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  badge?: Maybe<UserBadge>;
  badges: Array<UserBadgeModel>;
  birthday?: Maybe<Scalars["Date"]["output"]>;
  cambridgeEnglishLevel?: Maybe<Scalars["Int"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  cityId?: Maybe<Scalars["String"]["output"]>;
  completedHomeTour?: Maybe<Scalars["Boolean"]["output"]>;
  completedPercentage: Scalars["Int"]["output"];
  country?: Maybe<Scalars["String"]["output"]>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  customFlags: Array<UserFlagModel>;
  customers: Array<ChargebeeCustomerModel>;
  deletedAt?: Maybe<Scalars["Date"]["output"]>;
  email: Scalars["String"]["output"];
  engagementMetrics?: Maybe<UserEngagementMetrics>;
  englishSkills?: Maybe<Array<EnglishSkill>>;
  extraConversations?: Maybe<Scalars["Int"]["output"]>;
  extraHostedEvents?: Maybe<Scalars["Int"]["output"]>;
  extraInvitedFriends?: Maybe<Scalars["Int"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  followers?: Maybe<Array<FollowerModel>>;
  following?: Maybe<Array<FollowerModel>>;
  googleRefreshToken?: Maybe<Scalars["String"]["output"]>;
  hasActivePlan: Scalars["Boolean"]["output"];
  hasGoogleRefreshToken: Scalars["Boolean"]["output"];
  hasPassword: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  impersonated: Scalars["Boolean"]["output"];
  interests?: Maybe<Array<Interest>>;
  invites: Array<InviteModel>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isAgeVisible?: Maybe<Scalars["Boolean"]["output"]>;
  isBanned: Scalars["Boolean"]["output"];
  isComplete?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  isHeyLady?: Maybe<Scalars["Boolean"]["output"]>;
  isOnline?: Maybe<Scalars["Boolean"]["output"]>;
  isVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  kind?: Maybe<EventPermissions>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastOnlineAt?: Maybe<Scalars["Date"]["output"]>;
  lat?: Maybe<Scalars["Float"]["output"]>;
  long?: Maybe<Scalars["Float"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  nationality?: Maybe<Scalars["String"]["output"]>;
  nativeLanguage?: Maybe<Scalars["String"]["output"]>;
  needSpeakingPartner?: Maybe<Scalars["Boolean"]["output"]>;
  notes: Array<NoteModel>;
  occupation?: Maybe<OccupationModel>;
  onboarding: Onboarding;
  otherLanguage?: Maybe<Scalars["String"]["output"]>;
  partners?: Maybe<Scalars["Int"]["output"]>;
  pauseCredits?: Maybe<Scalars["Int"]["output"]>;
  photos: Array<UserPhotoModel>;
  planSlug?: Maybe<Scalars["String"]["output"]>;
  promotionalCredits?: Maybe<Scalars["Int"]["output"]>;
  seenCompletedAt?: Maybe<Scalars["Date"]["output"]>;
  showBirthday?: Maybe<Scalars["Boolean"]["output"]>;
  showBirthdayYear?: Maybe<Scalars["Boolean"]["output"]>;
  speakingLevel: SpeakingLevelCategories;
  speakingLevelScore: Scalars["Float"]["output"];
  speakingPartners?: Maybe<Scalars["String"]["output"]>;
  stepsCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  story?: Maybe<Scalars["String"]["output"]>;
  subscriptionTier: SubscriptionTier;
  timezone?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  updatedLocation?: Maybe<Scalars["Boolean"]["output"]>;
  victims?: Maybe<Array<AbuseModel>>;
  wantsNewsletter?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface UserPhotoModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  url: Scalars["String"]["output"];
  user: UserModel;
}

export enum UserReportReason {
  MaleGenderOrFalseIdentityOrPreviouslyBanned = "MALE_GENDER_OR_FALSE_IDENTITY_OR_PREVIOUSLY_BANNED",
  OffensiveOrDisrespectful = "OFFENSIVE_OR_DISRESPECTFUL",
  Other = "OTHER",
  UnacceptableBehaviour = "UNACCEPTABLE_BEHAVIOUR",
}

export interface UserSearchResult {
  item: UserModel;
  matches?: Maybe<Array<SearchMatch>>;
  refIndex: Scalars["Int"]["output"];
}

export interface UserSettingsModel {
  createdAt: Scalars["Date"]["output"];
  emailNotifications?: Maybe<EmailNotifications>;
  id: Scalars["String"]["output"];
  receiveChatMentionsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveChatMentionsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRemindersEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRemindersNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRsvpEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRsvpNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventUpdatesAndInvitationsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventUpdatesAndInvitationsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveFollowsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveFollowsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveLiveTableStartedNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveMentionsAndCommentsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveMentionsAndCommentsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receivePlatformStatusEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveSubscriptionChangesEmails?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export interface UserSignUpModel {
  createdAt: Scalars["Date"]["output"];
  email: Scalars["String"]["output"];
  englishLevel?: Maybe<Scalars["Int"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  hasVerifiedEmail: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  planParam: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface UserTotpModel {
  algorithm: Scalars["String"]["output"];
  createdAt: Scalars["Date"]["output"];
  deviceName?: Maybe<Scalars["String"]["output"]>;
  digits: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  period: Scalars["Int"]["output"];
  secret: Scalars["String"]["output"];
  uri?: Maybe<Scalars["String"]["output"]>;
  user: UserModel;
  verified: Scalars["Boolean"]["output"];
}

export interface UsersWithSameSpeakingLevel {
  randomUsersAvatars?: Maybe<Array<Scalars["String"]["output"]>>;
  total?: Maybe<Scalars["Int"]["output"]>;
}

export interface ValidateBulkInviteResponse {
  userIds: Array<Scalars["String"]["output"]>;
  warningMessages: Array<Scalars["String"]["output"]>;
}

export interface ValidateSelectionBulkInviteResponse {
  eventTitle?: Maybe<Scalars["String"]["output"]>;
  userIds: Array<Scalars["String"]["output"]>;
  warningMessages: Array<Scalars["String"]["output"]>;
}

export interface VerifyUserInput {
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export enum VideoAskFormType {
  Verification = "Verification",
}

export interface VideoAskModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  type: VideoAskFormType;
  user: UserModel;
}

export interface VideoCallModel {
  chatRoomId: Scalars["String"]["output"];
  closedDoorAt?: Maybe<Scalars["Date"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  dailyRoomId: Scalars["String"]["output"];
  endedAt?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["String"]["output"];
  lastSingleUserAt?: Maybe<Scalars["Date"]["output"]>;
  participants: Array<VideoParticipantModel>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface VideoModel {
  completedByMe: Scalars["Boolean"]["output"];
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  position: Scalars["Int"]["output"];
  set: VideoSetModel;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  videoURL?: Maybe<Scalars["String"]["output"]>;
}

export interface VideoParticipantModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isHost: Scalars["Boolean"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
  userId: Scalars["String"]["output"];
  videoCall: VideoCallModel;
}

export interface VideoSetModel {
  audience?: Maybe<Array<Audience>>;
  colour?: Maybe<ColourCode>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  position: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  videos?: Maybe<Array<VideoModel>>;
}

export interface ZineModel {
  coverUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isCurrent?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface CbPlanDetails {
  amount?: Maybe<Scalars["Int"]["output"]>;
  cancelledAt?: Maybe<Scalars["Date"]["output"]>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  customerChargebeeId?: Maybe<Scalars["String"]["output"]>;
  end?: Maybe<Scalars["Date"]["output"]>;
  interval?: Maybe<Scalars["String"]["output"]>;
  intervalCount?: Maybe<Scalars["Int"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  resumesAt?: Maybe<Scalars["Date"]["output"]>;
  start?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionChargebeeId?: Maybe<Scalars["String"]["output"]>;
  userAmount?: Maybe<Scalars["Int"]["output"]>;
}

export type AdminDeleteArticleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteArticleMutation = { adminDeleteArticle?: { status: MutationStatus } | null };

export type AdminEditArticleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditArticleInput;
}>;

export type AdminEditArticleMutation = {
  adminEditArticle?: {
    id: string;
    title?: string | null;
    isVisible?: boolean | null;
    isFeatured?: boolean | null;
    isDraft?: boolean | null;
  } | null;
};

export type AdminGenerateAttachmentUploadUrlMutationVariables = Exact<{
  filename: Scalars["String"]["input"];
}>;

export type AdminGenerateAttachmentUploadUrlMutation = {
  adminGenerateAttachmentUploadUrl?: {
    contentURL?: string | null;
    uploadURL?: string | null;
  } | null;
};

export type DeleteCommentMutationVariables = Exact<{
  commentID: Scalars["String"]["input"];
}>;

export type DeleteCommentMutation = { deleteComment?: { status: MutationStatus } | null };

export type AdminCreateCoverMutationVariables = Exact<{
  input: CreateCoverInput;
}>;

export type AdminCreateCoverMutation = {
  adminCreateCover?: {
    id: string;
    title: string;
    primary?: { mobile?: string | null; desktop?: string | null } | null;
    secondary?: { mobile?: string | null; desktop?: string | null } | null;
    tertiary?: { mobile?: string | null; desktop?: string | null } | null;
  } | null;
};

export type AdminEditCoverMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditCoverInput;
}>;

export type AdminEditCoverMutation = {
  adminEditCover?: {
    id: string;
    title: string;
    primary?: { mobile?: string | null; desktop?: string | null } | null;
    secondary?: { mobile?: string | null; desktop?: string | null } | null;
    tertiary?: { mobile?: string | null; desktop?: string | null } | null;
  } | null;
};

export type AdminDeleteCoverMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteCoverMutation = { adminDeleteCover?: { status: MutationStatus } | null };

export type AdminDeleteEventMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteEventMutation = { adminDeleteEvent?: { status: MutationStatus } | null };

export type AdminEditEventMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditEventInput;
}>;

export type AdminEditEventMutation = { adminEditEvent?: { status: MutationStatus } | null };

export type AdminRepublishEventMutationVariables = Exact<{
  eventId: Scalars["String"]["input"];
}>;

export type AdminRepublishEventMutation = { adminRepublishEvent: { status: MutationStatus } };

export type ValidateUsersForEmailBulkInviteMutationVariables = Exact<{
  eventId: Scalars["String"]["input"];
  input: BulkInviteWithEmailsInput;
}>;

export type ValidateUsersForEmailBulkInviteMutation = {
  validateUsersForEmailBulkInvite?: {
    userIds: Array<string>;
    warningMessages: Array<string>;
  } | null;
};

export type BulkInviteWithUserIdsMutationVariables = Exact<{
  eventId: Scalars["String"]["input"];
  input: BulkInviteWithUserIdsInput;
}>;

export type BulkInviteWithUserIdsMutation = {
  bulkInviteWithUserIds?: { warningMessages: Array<string> } | null;
};

export type ValidateForSelectionBulkInviteMutationVariables = Exact<{
  eventId: Scalars["String"]["input"];
  filters: AdminFilterUsersInput;
}>;

export type ValidateForSelectionBulkInviteMutation = {
  validateForSelectionBulkInvite: {
    eventTitle?: string | null;
    userIds: Array<string>;
    warningMessages: Array<string>;
  };
};

export type AdminCreateFeatureFlagMutationVariables = Exact<{
  input: CreateFeatureFlagInput;
}>;

export type AdminCreateFeatureFlagMutation = {
  adminCreateFeatureFlag?: { id: string; label: string; active?: boolean | null } | null;
};

export type AdminEditFeatureFlagMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditFeatureFlagInput;
}>;

export type AdminEditFeatureFlagMutation = {
  adminEditFeatureFlag?: { id: string; label: string; active?: boolean | null } | null;
};

export type AdminDeleteFeatureFlagMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteFeatureFlagMutation = {
  adminDeleteFeatureFlag?: { reason?: string | null; status: MutationStatus } | null;
};

export type AdminDeleteIllustrationMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteIllustrationMutation = {
  adminDeleteIllustration?: { status: MutationStatus } | null;
};

export type AdminCreateIllustrationMutationVariables = Exact<{
  input: CreateIllustrationInput;
}>;

export type AdminCreateIllustrationMutation = {
  adminCreateIllustration?: { id: string; title: string; url?: string | null } | null;
};

export type AdminEditIllustrationMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditIllustrationInput;
}>;

export type AdminEditIllustrationMutation = {
  adminEditIllustration?: { id: string; title: string; url?: string | null } | null;
};

export type AdminDeleteLessonMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteLessonMutation = {
  adminDeleteLesson?: { reason?: string | null; status: MutationStatus } | null;
};

export type AdminCreateLessonMutationVariables = Exact<{
  input: CreateLessonInput;
}>;

export type AdminCreateLessonMutation = {
  adminCreateLesson?: {
    id: string;
    sections?: Array<{
      id: string;
      order?: number | null;
      elements?: Array<{ kind: ElementKind; meta: any }> | null;
    }> | null;
  } | null;
};

export type AdminEditLessonMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditLessonInput;
}>;

export type AdminEditLessonMutation = {
  adminEditLesson?: { id: string; deepDiveKind?: DeepDiveKind | null; kind: LessonKind } | null;
};

export type TriggerBackupEventsParticipantsMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerBackupEventsParticipantsMutation = {
  triggerBackupEventsParticipants: { status: MutationStatus };
};

export type TriggerUserPermissionPromotionMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerUserPermissionPromotionMutation = {
  triggerUserPermissionPromotion: { status: MutationStatus };
};

export type TriggerUserDeletionAfterRetentionMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerUserDeletionAfterRetentionMutation = {
  triggerUserDeletionAfterRetention: { status: MutationStatus };
};

export type TriggerChatServiceSyncMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerChatServiceSyncMutation = {
  triggerChatServiceSync?: { status: MutationStatus } | null;
};

export type TriggerRecreateZoomMeetingsMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerRecreateZoomMeetingsMutation = {
  triggerRecreateZoomMeetings: { status: MutationStatus };
};

export type TriggerRecreateDailyMeetingsMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerRecreateDailyMeetingsMutation = {
  triggerRecreateDailyMeetings: { status: MutationStatus };
};

export type TriggerAssignAllUserBadgesMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerAssignAllUserBadgesMutation = {
  triggerAssignAllUserBadges: { status: MutationStatus };
};

export type IssueCompleteProfileNotificationMutationVariables = Exact<{ [key: string]: never }>;

export type IssueCompleteProfileNotificationMutation = {
  issueCompleteProfileNotification: { status: MutationStatus };
};

export type UploadActiveDaysMutationVariables = Exact<{
  input: UploadActiveDaysInput;
}>;

export type UploadActiveDaysMutation = { uploadActiveDays: { status: MutationStatus } };

export type AssignAllExtraReferralBadgesMutationVariables = Exact<{ [key: string]: never }>;

export type AssignAllExtraReferralBadgesMutation = {
  assignAllExtraReferralBadges: { status: MutationStatus };
};

export type AdminCreateCustomNotificationMutationVariables = Exact<{ [key: string]: never }>;

export type AdminCreateCustomNotificationMutation = {
  adminCreateCustomNotification?: { id: string } | null;
};

export type AdminEditCustomNotificationMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditCustomNotificationInput;
}>;

export type AdminEditCustomNotificationMutation = {
  adminEditCustomNotification?: { status: MutationStatus } | null;
};

export type SendSystemNotificationsMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  emails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type SendSystemNotificationsMutation = {
  sendSystemNotifications?: { sentTo: number; warningMessages: Array<string> } | null;
};

export type AdminDeleteUserReportMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteUserReportMutation = {
  adminDeleteUserReport?: { status: MutationStatus } | null;
};

export type AdminCreateSectionMutationVariables = Exact<{
  input: CreateSectionInput;
}>;

export type AdminCreateSectionMutation = {
  adminCreateSection?: {
    id: string;
    order?: number | null;
    elements?: Array<{ kind: ElementKind; meta: any }> | null;
  } | null;
};

export type AdminEditSectionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditSectionInput;
}>;

export type AdminEditSectionMutation = {
  adminEditSection?: {
    id: string;
    order?: number | null;
    elements?: Array<{ kind: ElementKind; meta: any }> | null;
  } | null;
};

export type AdminDeleteSectionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteSectionMutation = {
  adminDeleteSection?: { reason?: string | null; status: MutationStatus } | null;
};

export type AdminCreateBannerMutationVariables = Exact<{
  input: CreateBannerInput;
}>;

export type AdminCreateBannerMutation = {
  adminCreateBanner?: { id: string; isActive: boolean } | null;
};

export type AdminEditBannerMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditBannerInput;
}>;

export type AdminEditBannerMutation = {
  adminEditBanner?: { id: string; isActive: boolean } | null;
};

export type AdminDeleteBannerMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteBannerMutation = { adminDeleteBanner?: { status: MutationStatus } | null };

export type AdminCreateSliderMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminCreateSliderMutation = { adminCreateSlider?: { status: MutationStatus } | null };

export type AdminDeleteSliderMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteSliderMutation = { adminDeleteSlider?: { status: MutationStatus } | null };

export type AdminEditSlideMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditSliderInput;
}>;

export type AdminEditSlideMutation = { adminEditSlide?: { id: string } | null };

export type AdminOrderSliderMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}>;

export type AdminOrderSliderMutation = { adminOrderSlider?: { status: MutationStatus } | null };

export type AdminCreateThemeMutationVariables = Exact<{
  input: CreateThemeInput;
}>;

export type AdminCreateThemeMutation = {
  adminCreateTheme?: {
    id: string;
    title: string;
    description?: string | null;
    isGrammarLibrary?: boolean | null;
    isTopicLibrary?: boolean | null;
    isResource?: boolean | null;
    isStandard?: boolean | null;
    isCurrent?: boolean | null;
    cover?: {
      id: string;
      title: string;
      primary?: { mobile?: string | null; desktop?: string | null } | null;
      secondary?: { mobile?: string | null; desktop?: string | null } | null;
      tertiary?: { mobile?: string | null; desktop?: string | null } | null;
    } | null;
  } | null;
};

export type AdminEditThemeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditThemeInput;
}>;

export type AdminEditThemeMutation = {
  adminEditTheme?: {
    id: string;
    title: string;
    description?: string | null;
    isCurrent?: boolean | null;
    isStandard?: boolean | null;
    isGrammarLibrary?: boolean | null;
    isTopicLibrary?: boolean | null;
    cover?: {
      id: string;
      title: string;
      primary?: { mobile?: string | null; desktop?: string | null } | null;
      secondary?: { mobile?: string | null; desktop?: string | null } | null;
      tertiary?: { mobile?: string | null; desktop?: string | null } | null;
    } | null;
  } | null;
};

export type AdminDeleteThemeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteThemeMutation = { adminDeleteTheme?: { status: MutationStatus } | null };

export type AdminEditTopicMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditTopicInput;
}>;

export type AdminEditTopicMutation = {
  adminEditTopic?: {
    id: string;
    title: string;
    stars?: number | null;
    theme?: { id: string; title: string } | null;
    illustration?: { id: string; title: string; url?: string | null } | null;
    lessons?: Array<{ id: string; kind: LessonKind }> | null;
  } | null;
};

export type AdminDeleteTopicMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteTopicMutation = {
  adminDeleteTopic?: { status: MutationStatus; reason?: string | null } | null;
};

export type AdminDeleteTopicsMutationVariables = Exact<{
  input: DeleteTopicsInput;
}>;

export type AdminDeleteTopicsMutation = { adminDeleteTopics?: { status: MutationStatus } | null };

export type AdminCreateTopicMutationVariables = Exact<{
  input: CreateTopicInput;
}>;

export type AdminCreateTopicMutation = {
  adminCreateTopic?: {
    id: string;
    title: string;
    stars?: number | null;
    illustration?: { id: string; title: string; url?: string | null } | null;
    theme?: { id: string; title: string } | null;
    lessons?: Array<{ id: string; kind: LessonKind }> | null;
  } | null;
};

export type DuplicateTopicMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DuplicateTopicMutation = { duplicateTopic?: { status: MutationStatus } | null };

export type DuplicateLessonMutationVariables = Exact<{
  input: DuplicateLessonInput;
}>;

export type DuplicateLessonMutation = { duplicateLesson?: { status: MutationStatus } | null };

export type CreateTopicForPreviewModeMutationVariables = Exact<{
  input: TopicInPreviewInput;
}>;

export type CreateTopicForPreviewModeMutation = {
  createTopicForPreviewMode?: { id: string } | null;
};

export type CreateTotpMutationVariables = Exact<{ [key: string]: never }>;

export type CreateTotpMutation = { createTotp: { id: string; uri?: string | null } };

export type ConfirmTotpMutationVariables = Exact<{
  input: ConfirmTotpInput;
}>;

export type ConfirmTotpMutation = { confirmTotp: { status: MutationStatus } };

export type RemoveTotpMutationVariables = Exact<{
  totpId: Scalars["String"]["input"];
}>;

export type RemoveTotpMutation = { removeTotp: { status: MutationStatus } };

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteAccountMutation = { deleteAccount?: { status: MutationStatus } | null };

export type AdminEditUserMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditUserInput;
}>;

export type AdminEditUserMutation = { adminEditUser?: { status: MutationStatus } | null };

export type AdminCreateNoteMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: NoteInput;
}>;

export type AdminCreateNoteMutation = {
  adminCreateNote?: { id: string; description: string } | null;
};

export type AdminBanUserMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminBanUserMutation = { adminBanUser?: { status: MutationStatus } | null };

export type AdminUnbanUserMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminUnbanUserMutation = { adminUnbanUser?: { status: MutationStatus } | null };

export type AssignUserBadgeMutationVariables = Exact<{
  input: AssignOrDeleteBadgeInput;
}>;

export type AssignUserBadgeMutation = { assignUserBadge?: { status: MutationStatus } | null };

export type DeleteUserBadgeMutationVariables = Exact<{
  input: AssignOrDeleteBadgeInput;
}>;

export type DeleteUserBadgeMutation = { deleteUserBadge?: { status: MutationStatus } | null };

export type AdminCreateVideoSetMutationVariables = Exact<{
  input: CreateVideoSetInput;
}>;

export type AdminCreateVideoSetMutation = {
  adminCreateVideoSet?: { id: string; title: string } | null;
};

export type AdminDeleteVideoSetMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteVideoSetMutation = {
  adminDeleteVideoSet?: { status: MutationStatus } | null;
};

export type AdminCreateVideoMutationVariables = Exact<{
  input: CreateVideoInput;
}>;

export type AdminCreateVideoMutation = { adminCreateVideo?: { status: MutationStatus } | null };

export type AdminDeleteVideoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteVideoMutation = { adminDeleteVideo?: { status: MutationStatus } | null };

export type AdminEditVideoSetMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditVideoSetInput;
}>;

export type AdminEditVideoSetMutation = { adminEditVideoSet?: { id: string } | null };

export type AdminOrderVideoSetMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}>;

export type AdminOrderVideoSetMutation = { adminOrderVideoSet?: { status: MutationStatus } | null };

export type AdminEditVideoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditVideoInput;
}>;

export type AdminEditVideoMutation = { adminEditVideo?: { id: string } | null };

export type AdminOrderVideoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}>;

export type AdminOrderVideoMutation = { adminOrderVideo?: { status: MutationStatus } | null };

export type AdminDeleteZineCoverMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminDeleteZineCoverMutation = {
  adminDeleteZineCover?: { status: MutationStatus } | null;
};

export type AdminCreateZineCoverMutationVariables = Exact<{
  input: CreateZineInput;
}>;

export type AdminCreateZineCoverMutation = {
  adminCreateZineCover?: {
    id: string;
    coverUrl?: string | null;
    isCurrent?: boolean | null;
  } | null;
};

export type AdminEditZineCoverMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: EditZineInput;
}>;

export type AdminEditZineCoverMutation = {
  adminEditZineCover?: { id: string; coverUrl?: string | null; isCurrent?: boolean | null } | null;
};

export type UploadZineCoverMutationVariables = Exact<{
  input: UploadCoverZineInput;
}>;

export type UploadZineCoverMutation = {
  uploadZineCover?: { id: string; coverUrl?: string | null } | null;
};

export type AdminGetArticleQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  filters: AdminFilterUsersInput;
}>;

export type AdminGetArticleQuery = {
  adminGetArticle: {
    id: string;
    title?: string | null;
    content?: string | null;
    coverUrl?: string | null;
    isFeatured?: boolean | null;
    tags?: Array<Interest> | null;
    isVisible?: boolean | null;
    isDraft?: boolean | null;
    author?: { id: string; name: string } | null;
  };
  adminListUsers: { total: number; items?: Array<{ id: string; name: string }> | null };
};

export type AdminListPaginatedArticlesQueryVariables = Exact<{
  order: OrderArticlesInput;
  filters: FilterArticlesInput;
}>;

export type AdminListPaginatedArticlesQuery = {
  adminListPaginatedArticles: {
    total: number;
    items?: Array<{
      id: string;
      title?: string | null;
      content?: string | null;
      coverUrl?: string | null;
      isFeatured?: boolean | null;
      publishedAt?: any | null;
      tags?: Array<Interest> | null;
      isVisible?: boolean | null;
      isDraft?: boolean | null;
      author?: { id: string; name: string } | null;
    }> | null;
  };
};

export type AdminListPaginatedCoversQueryVariables = Exact<{
  filters: AdminFilterCoversInput;
}>;

export type AdminListPaginatedCoversQuery = {
  adminListPaginatedCovers: {
    total: number;
    items?: Array<{
      id: string;
      title: string;
      primary?: { mobile?: string | null; desktop?: string | null } | null;
      secondary?: { mobile?: string | null; desktop?: string | null } | null;
      tertiary?: { mobile?: string | null; desktop?: string | null } | null;
    }> | null;
  };
};

export type AdminGetCoverQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetCoverQuery = {
  adminGetCover: {
    id: string;
    title: string;
    primary?: { mobile?: string | null; desktop?: string | null } | null;
    secondary?: { mobile?: string | null; desktop?: string | null } | null;
    tertiary?: { mobile?: string | null; desktop?: string | null } | null;
  };
};

export type AdminSearchEventsQueryVariables = Exact<{
  filters: AdminFilterEventsInput;
}>;

export type AdminSearchEventsQuery = {
  adminSearchEvents: {
    total: number;
    items?: Array<{
      title?: string | null;
      id: string;
      endDate?: any | null;
      coverUrl?: string | null;
      startDate?: any | null;
      organizer?: { id: string; firstName?: string | null; lastName?: string | null } | null;
    }> | null;
  };
};

export type GetEventAdminQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetEventAdminQuery = {
  getEventAdmin?: {
    id: string;
    coverUrl?: string | null;
    createdAt: any;
    description?: string | null;
    endDate?: any | null;
    isDraft?: boolean | null;
    isOnline?: boolean | null;
    isPublic?: boolean | null;
    kind?: EventKind | null;
    meta?: any | null;
    location?: string | null;
    noParticipants?: number | null;
    recurring?: boolean | null;
    startDate?: any | null;
    title?: string | null;
    updatedAt: any;
    videoUrl?: string | null;
    isForUnverified?: boolean | null;
    permissions?: Array<EventPermissions> | null;
    englishLevel?: EventEnglishLevel | null;
    organizer?: { id: string; name: string; avatarUrl?: string | null } | null;
  } | null;
};

export type GetEventAndTopicsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetEventAndTopicsQuery = {
  getEventAdmin?: {
    id: string;
    coverUrl?: string | null;
    createdAt: any;
    description?: string | null;
    endDate?: any | null;
    eventCategory?: EventCategory | null;
    isDraft?: boolean | null;
    isDeleted?: boolean | null;
    isOnline?: boolean | null;
    isPublic?: boolean | null;
    kind?: EventKind | null;
    meta?: any | null;
    location?: string | null;
    noParticipants?: number | null;
    recurring?: boolean | null;
    englishLevel?: EventEnglishLevel | null;
    isForUnverified?: boolean | null;
    permissions?: Array<EventPermissions> | null;
    startDate?: any | null;
    title?: string | null;
    updatedAt: any;
    videoUrl?: string | null;
    host?: { kind?: EventPermissions | null } | null;
    organizer?: { id: string; name: string; avatarUrl?: string | null } | null;
    topic?: { id: string; title: string } | null;
  } | null;
  adminListTopics: Array<{ id: string; title: string }>;
};

export type GetEventParticipantsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetEventParticipantsQuery = {
  getEventAdmin?: {
    id: string;
    participants: Array<{
      id: string;
      name: string;
      createdAt: any;
      email: string;
      city?: string | null;
      isVerified?: boolean | null;
      country?: string | null;
      avatarUrl?: string | null;
      badge?: UserBadge | null;
    }>;
    invited: Array<{
      id: string;
      name: string;
      createdAt: any;
      email: string;
      city?: string | null;
      isVerified?: boolean | null;
      country?: string | null;
      avatarUrl?: string | null;
      badge?: UserBadge | null;
    }>;
    declined: Array<{
      id: string;
      name: string;
      createdAt: any;
      email: string;
      city?: string | null;
      isVerified?: boolean | null;
      country?: string | null;
      avatarUrl?: string | null;
      badge?: UserBadge | null;
    }>;
  } | null;
};

export type GetEventCommentsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetEventCommentsQuery = {
  getEventAdmin?: {
    id: string;
    comments?: Array<{
      id: string;
      message: string;
      author?: { id: string; avatarUrl?: string | null; name: string } | null;
    }> | null;
  } | null;
};

export type AdminGetFeatureFlagQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetFeatureFlagQuery = {
  adminGetFeatureFlag: { id: string; label: string; active?: boolean | null };
};

export type AdminListPaginatedFeatureFlagsQueryVariables = Exact<{
  filters: AdminFilterFeatureFlagsInput;
}>;

export type AdminListPaginatedFeatureFlagsQuery = {
  adminListPaginatedFeatureFlags: {
    total: number;
    items?: Array<{ id: string; label: string; active?: boolean | null }> | null;
  };
};

export type AdminGetIllustrationQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetIllustrationQuery = {
  adminGetIllustration: { id: string; title: string; url?: string | null };
};

export type AdminListPaginatedIllustrationsQueryVariables = Exact<{
  filters: AdminFilterIllustrationsInput;
}>;

export type AdminListPaginatedIllustrationsQuery = {
  adminListPaginatedIllustrations: {
    total: number;
    items?: Array<{ id: string; title: string; url?: string | null }> | null;
  };
};

export type AdminGetLessonQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetLessonQuery = {
  adminGetLesson: {
    id: string;
    kind: LessonKind;
    title?: string | null;
    sections?: Array<{
      id: string;
      order?: number | null;
      elements?: Array<{ kind: ElementKind; value?: any | null; meta: any }> | null;
    }> | null;
  };
  adminGetTopicByLessonId: { id: string; title: string };
};

export type GetCustomNotificationQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetCustomNotificationQuery = {
  getCustomNotification: {
    id: string;
    illustration?: string | null;
    title?: string | null;
    text?: string | null;
    buttonText?: string | null;
    buttonUrl?: string | null;
  };
};

export type AdminListPaginatedCustomNotificationsQueryVariables = Exact<{
  filters: FilterCustomNotificationsInput;
}>;

export type AdminListPaginatedCustomNotificationsQuery = {
  adminListPaginatedCustomNotifications: {
    total: number;
    items?: Array<{
      id: string;
      illustration?: string | null;
      title?: string | null;
      text?: string | null;
      buttonText?: string | null;
      buttonUrl?: string | null;
    }> | null;
  };
};

export type AdminListPaginatedReportsUsersQueryVariables = Exact<{
  filters: AdminFilterReportsUserFilterInput;
}>;

export type AdminListPaginatedReportsUsersQuery = {
  adminListPaginatedReportsUsers: {
    total: number;
    items?: Array<{
      id: string;
      description?: string | null;
      reason?: UserReportReason | null;
      createdAt: any;
      abuser?: { id: string; name: string } | null;
      victim?: { id: string; name: string } | null;
    }> | null;
  };
};

export type AdminListPaginatedBannersQueryVariables = Exact<{
  filters: AdminFilterBannersInput;
}>;

export type AdminListPaginatedBannersQuery = {
  adminListPaginatedBanners: {
    total: number;
    items?: Array<{ id: string; title: string; isActive: boolean }> | null;
  };
};

export type AdminGetBannerQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetBannerQuery = {
  adminGetBanner: {
    id: string;
    title: string;
    isActive: boolean;
    createdAt: any;
    updatedAt: any;
    audience?: Array<EventPermissions> | null;
    sliders?: Array<{ id: string; position?: number | null; title?: string | null }> | null;
  };
};

export type AdminGetSlideQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetSlideQuery = {
  adminGetSlide: {
    buttonText?: string | null;
    buttonURL?: string | null;
    createdAt: any;
    featureImage?: string | null;
    id: string;
    imageAlignment?: ImageAlignment | null;
    subtitle?: string | null;
    textColour?: TextColour | null;
    title?: string | null;
    titleStyle?: TitleStyle | null;
    updatedAt: any;
    background?: { desktop?: string | null; mobile?: string | null } | null;
    banner: { id: string; title: string };
  };
};

export type AdminListPaginatedThemesQueryVariables = Exact<{
  filters: AdminFilterThemesInput;
}>;

export type AdminListPaginatedThemesQuery = {
  adminListPaginatedThemes: {
    total: number;
    items?: Array<{
      title: string;
      id: string;
      isCurrent?: boolean | null;
      isStandard?: boolean | null;
      isGrammarLibrary?: boolean | null;
      isTopicLibrary?: boolean | null;
      isResource?: boolean | null;
      topics?: Array<{ id: string; title: string }> | null;
    }> | null;
  };
};

export type AdminListThemesQueryVariables = Exact<{ [key: string]: never }>;

export type AdminListThemesQuery = {
  adminListThemes: Array<{
    title: string;
    id: string;
    isCurrent?: boolean | null;
    isStandard?: boolean | null;
    isGrammarLibrary?: boolean | null;
    isTopicLibrary?: boolean | null;
    isResource?: boolean | null;
    topics?: Array<{ id: string; title: string }> | null;
  }>;
};

export type AdminGetThemeAndCoversQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetThemeAndCoversQuery = {
  adminGetTheme: {
    id: string;
    title: string;
    description?: string | null;
    isCurrent?: boolean | null;
    isStandard?: boolean | null;
    isGrammarLibrary?: boolean | null;
    isTopicLibrary?: boolean | null;
    isResource?: boolean | null;
    cover?: {
      id: string;
      title: string;
      primary?: { mobile?: string | null; desktop?: string | null } | null;
      secondary?: { mobile?: string | null; desktop?: string | null } | null;
      tertiary?: { mobile?: string | null; desktop?: string | null } | null;
    } | null;
  };
  adminListCovers: Array<{
    id: string;
    title: string;
    primary?: { mobile?: string | null; desktop?: string | null } | null;
    secondary?: { mobile?: string | null; desktop?: string | null } | null;
    tertiary?: { mobile?: string | null; desktop?: string | null } | null;
  }>;
};

export type AdminListPaginatedTopicsQueryVariables = Exact<{
  filters: AdminFilterTopicsInput;
}>;

export type AdminListPaginatedTopicsQuery = {
  adminListPaginatedTopics: {
    total: number;
    items?: Array<{
      title: string;
      id: string;
      status?: TopicStatus | null;
      updatedAt: any;
      theme?: {
        id: string;
        title: string;
        isGrammarLibrary?: boolean | null;
        isTopicLibrary?: boolean | null;
        isStandard?: boolean | null;
        isResource?: boolean | null;
      } | null;
    }> | null;
  };
};

export type AdminGetTopicThemesAndIllustrationsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetTopicThemesAndIllustrationsQuery = {
  adminGetTopic: {
    id: string;
    title: string;
    status?: TopicStatus | null;
    theme?: { id: string; title: string } | null;
    comments?: Array<{
      id: string;
      createdAt: any;
      message: string;
      author?: { id: string; name: string; avatarUrl?: string | null } | null;
      replies?: Array<{
        id: string;
        createdAt: any;
        message: string;
        author?: { id: string; name: string; avatarUrl?: string | null } | null;
      }> | null;
    }> | null;
    illustration?: { id: string; title: string; url?: string | null } | null;
    lessons?: Array<{ id: string; kind: LessonKind; title?: string | null }> | null;
  };
  adminListThemes: Array<{ id: string; title: string }>;
  adminListIllustrations: Array<{ id: string; title: string; url?: string | null }>;
};

export type ListTotpQueryVariables = Exact<{ [key: string]: never }>;

export type ListTotpQuery = {
  listTotp: Array<{ id: string; deviceName?: string | null; createdAt: any }>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  me: {
    id: string;
    name: string;
    email: string;
    avatarUrl?: string | null;
    needSpeakingPartner?: boolean | null;
    cambridgeEnglishLevel?: number | null;
    kind?: EventPermissions | null;
    firstName?: string | null;
    otherLanguage?: string | null;
    isComplete?: boolean | null;
    badge?: UserBadge | null;
    createdAt: any;
    lastName?: string | null;
    city?: string | null;
    country?: string | null;
    timezone?: string | null;
    story?: string | null;
    birthday?: any | null;
    interests?: Array<Interest> | null;
    showBirthday?: boolean | null;
    showBirthdayYear?: boolean | null;
    nationality?: string | null;
    nativeLanguage?: string | null;
    completedPercentage: number;
    isVisible?: boolean | null;
    wantsNewsletter?: boolean | null;
    promotionalCredits?: number | null;
    occupation?: { id: string; name?: string | null } | null;
    availability?: Array<{ from: string; until: string }> | null;
  };
};

export type AdminListUsersQueryVariables = Exact<{
  filters: AdminFilterUsersInput;
}>;

export type AdminListUsersQuery = {
  adminListUsers: {
    total: number;
    items?: Array<{
      id: string;
      name: string;
      email: string;
      avatarUrl?: string | null;
      needSpeakingPartner?: boolean | null;
      cambridgeEnglishLevel?: number | null;
      otherLanguage?: string | null;
      isComplete?: boolean | null;
      isVerified?: boolean | null;
      badge?: UserBadge | null;
      createdAt: any;
      city?: string | null;
      country?: string | null;
      timezone?: string | null;
      story?: string | null;
      birthday?: any | null;
      interests?: Array<Interest> | null;
      showBirthday?: boolean | null;
      nativeLanguage?: string | null;
      completedPercentage: number;
      isVisible?: boolean | null;
      wantsNewsletter?: boolean | null;
      occupation?: { id: string; name?: string | null } | null;
      availability?: Array<{ from: string; until: string }> | null;
    }> | null;
  };
};

export type AdminGetSelectedUsersQueryVariables = Exact<{
  filters: AdminFilterUsersInput;
}>;

export type AdminGetSelectedUsersQuery = {
  adminGetSelectedUsers: Array<{
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    cambridgeEnglishLevel?: number | null;
    speakingLevelScore: number;
    speakingLevel: SpeakingLevelCategories;
    city?: string | null;
    country?: string | null;
    birthday?: any | null;
    nativeLanguage?: string | null;
    needSpeakingPartner?: boolean | null;
    badge?: UserBadge | null;
    isVerified?: boolean | null;
    lastOnlineAt?: any | null;
    timezone?: string | null;
    isActive?: boolean | null;
    isComplete?: boolean | null;
    interests?: Array<Interest> | null;
    englishSkills?: Array<EnglishSkill> | null;
    createdAt: any;
    kind?: EventPermissions | null;
    pauseCredits?: number | null;
    showBirthday?: boolean | null;
    completedPercentage: number;
    isVisible?: boolean | null;
    wantsNewsletter?: boolean | null;
    availability?: Array<{ from: string; until: string }> | null;
    occupation?: { id: string; name?: string | null } | null;
    customers: Array<{
      subscriptions: Array<{
        status?: string | null;
        plan?: any | null;
        currentPeriodStart?: any | null;
        currentPeriodEnd?: any | null;
        resumesAt?: any | null;
        cancelledAt?: any | null;
      }>;
    }>;
  }>;
};

export type AdminGetPlanDetailsQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type AdminGetPlanDetailsQuery = {
  adminGetPlanDetails: {
    name?: string | null;
    cbId?: string | null;
    subscriptionChargebeeId?: string | null;
    customerChargebeeId?: string | null;
  };
};

export type GetUserAdminQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetUserAdminQuery = {
  getUserAdmin?: {
    id: string;
    email: string;
    name: string;
    createdAt: any;
    kind?: EventPermissions | null;
    avatarUrl?: string | null;
    needSpeakingPartner?: boolean | null;
    cambridgeEnglishLevel?: number | null;
    speakingLevelScore: number;
    speakingLevel: SpeakingLevelCategories;
    firstName?: string | null;
    otherLanguage?: string | null;
    isComplete?: boolean | null;
    badge?: UserBadge | null;
    lastName?: string | null;
    isActive?: boolean | null;
    isBanned: boolean;
    isVerified?: boolean | null;
    hasPassword: boolean;
    city?: string | null;
    country?: string | null;
    timezone?: string | null;
    story?: string | null;
    birthday?: any | null;
    interests?: Array<Interest> | null;
    isAgeVisible?: boolean | null;
    showBirthday?: boolean | null;
    nativeLanguage?: string | null;
    completedPercentage: number;
    isVisible?: boolean | null;
    isFeatured?: boolean | null;
    wantsNewsletter?: boolean | null;
    pauseCredits?: number | null;
    hasActivePlan: boolean;
    availableAssessmentCharges?: number | null;
    extraInvitedFriends?: number | null;
    occupation?: { id: string; name?: string | null } | null;
    badges: Array<{ id: string; type: UserBadgeType; achievedDate?: any | null }>;
    availability?: Array<{ from: string; until: string }> | null;
    notes: Array<{ id: string; description: string }>;
    engagementMetrics?: {
      totalRsvps?: number | null;
      publishedArticles?: number | null;
      totalHostedParticipants?: number | null;
      attendanceRatio?: number | null;
      attendanceMetrics?: {
        totalEvents?: number | null;
        totalOneToOnes?: number | null;
        totalGroup?: number | null;
        totalWebinars?: number | null;
        totalInPerson?: number | null;
        totalOther?: number | null;
        totalDuration?: number | null;
        totalHostedEvents?: number | null;
        totalHostedOneToOnes?: number | null;
        totalHostedGroup?: number | null;
        totalHostedWebinars?: number | null;
        totalHostedInPerson?: number | null;
        totalHostedOther?: number | null;
        totalHostedDuration?: number | null;
        totalParticipatedEvents?: number | null;
        totalParticipatedScheduledEvents?: number | null;
        totalParticipatedOneToOnes?: number | null;
        totalParticipatedGroup?: number | null;
        totalParticipatedWebinars?: number | null;
        totalParticipatedInPerson?: number | null;
        totalParticipatedOther?: number | null;
        totalParticipatedDuration?: number | null;
        totalVideoCalls?: number | null;
        totalParticipatedNonScheduledEvents?: number | null;
      } | null;
    } | null;
  } | null;
};

export type AdminGetOccupationsQueryVariables = Exact<{ [key: string]: never }>;

export type AdminGetOccupationsQuery = {
  adminGetOccupations: Array<{ id: string; name?: string | null }>;
};

export type AdminGetGroupedOccupationsQueryVariables = Exact<{ [key: string]: never }>;

export type AdminGetGroupedOccupationsQuery = {
  adminGetGroupedOccupations: Array<{
    id: string;
    name?: string | null;
    industry?: { id: string; name: string } | null;
  }>;
};

export type GetInvitedFriendsStatsQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetInvitedFriendsStatsQuery = {
  getInvitedFriendsStats: {
    invitedFriendsTotal?: number | null;
    invitedPaidFriends?: number | null;
  };
};

export type ListPaginatedAssessmentResultsQueryVariables = Exact<{
  filters: PaginatedAssessmentResultInput;
}>;

export type ListPaginatedAssessmentResultsQuery = {
  listPaginatedAssessmentResults: {
    total: number;
    items?: Array<{
      id: string;
      assessmentId: string;
      cefrScore?: string | null;
      halloScore: number;
      createdAt: any;
    }> | null;
  };
};

export type GetUserAssessmentResultsQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
  filters: PaginatedAssessmentResultInput;
}>;

export type GetUserAssessmentResultsQuery = {
  getUserAssessmentResults: {
    total: number;
    items?: Array<{
      id: string;
      assessmentId: string;
      cefrScore?: string | null;
      halloScore: number;
      createdAt: any;
      isAddedByAdmin: boolean;
    }> | null;
  };
};

export type AdminListPaginatedVideoSetsQueryVariables = Exact<{
  filters: AdminFilterVideoSetInput;
}>;

export type AdminListPaginatedVideoSetsQuery = {
  adminListPaginatedVideoSets: {
    total: number;
    items?: Array<{
      id: string;
      title: string;
      isActive: boolean;
      audience?: Array<Audience> | null;
      colour?: ColourCode | null;
      updatedAt: any;
    }> | null;
  };
};

export type AdminGetVideoSetQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetVideoSetQuery = {
  adminGetVideoSet?: {
    id: string;
    title: string;
    isActive: boolean;
    audience?: Array<Audience> | null;
    colour?: ColourCode | null;
    updatedAt: any;
    videos?: Array<{
      id: string;
      title?: string | null;
      videoURL?: string | null;
      thumbnail?: string | null;
      isActive: boolean;
      position: number;
    }> | null;
  } | null;
};

export type AdminGetZineCoverQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetZineCoverQuery = {
  adminGetZineCover: { id: string; coverUrl?: string | null; isCurrent?: boolean | null };
};

export type AdminListPaginatedZineCoversQueryVariables = Exact<{
  filters: AdminFilterZineCoversInput;
}>;

export type AdminListPaginatedZineCoversQuery = {
  adminListPaginatedZineCovers: {
    total: number;
    items?: Array<{ id: string; coverUrl?: string | null; isCurrent?: boolean | null }> | null;
  };
};

export const AdminDeleteArticleDocument = gql`
    mutation adminDeleteArticle($id: String!) {
  adminDeleteArticle(id: $id) {
    status
  }
}
    `;
export type AdminDeleteArticleMutationFn = Apollo.MutationFunction<
  AdminDeleteArticleMutation,
  AdminDeleteArticleMutationVariables
>;

/**
 * __useAdminDeleteArticleMutation__
 *
 * To run a mutation, you first call `useAdminDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteArticleMutation, { data, loading, error }] = useAdminDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteArticleMutation,
    AdminDeleteArticleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteArticleMutation, AdminDeleteArticleMutationVariables>(
    AdminDeleteArticleDocument,
    options,
  );
}
export type AdminDeleteArticleMutationHookResult = ReturnType<typeof useAdminDeleteArticleMutation>;
export type AdminDeleteArticleMutationResult = Apollo.MutationResult<AdminDeleteArticleMutation>;
export type AdminDeleteArticleMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteArticleMutation,
  AdminDeleteArticleMutationVariables
>;
export const AdminEditArticleDocument = gql`
    mutation adminEditArticle($id: String!, $input: EditArticleInput!) {
  adminEditArticle(id: $id, input: $input) {
    id
    title
    isVisible
    isFeatured
    isDraft
  }
}
    `;
export type AdminEditArticleMutationFn = Apollo.MutationFunction<
  AdminEditArticleMutation,
  AdminEditArticleMutationVariables
>;

/**
 * __useAdminEditArticleMutation__
 *
 * To run a mutation, you first call `useAdminEditArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditArticleMutation, { data, loading, error }] = useAdminEditArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditArticleMutation,
    AdminEditArticleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditArticleMutation, AdminEditArticleMutationVariables>(
    AdminEditArticleDocument,
    options,
  );
}
export type AdminEditArticleMutationHookResult = ReturnType<typeof useAdminEditArticleMutation>;
export type AdminEditArticleMutationResult = Apollo.MutationResult<AdminEditArticleMutation>;
export type AdminEditArticleMutationOptions = Apollo.BaseMutationOptions<
  AdminEditArticleMutation,
  AdminEditArticleMutationVariables
>;
export const AdminGenerateAttachmentUploadUrlDocument = gql`
    mutation adminGenerateAttachmentUploadUrl($filename: String!) {
  adminGenerateAttachmentUploadUrl(filename: $filename) {
    contentURL
    uploadURL
  }
}
    `;
export type AdminGenerateAttachmentUploadUrlMutationFn = Apollo.MutationFunction<
  AdminGenerateAttachmentUploadUrlMutation,
  AdminGenerateAttachmentUploadUrlMutationVariables
>;

/**
 * __useAdminGenerateAttachmentUploadUrlMutation__
 *
 * To run a mutation, you first call `useAdminGenerateAttachmentUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminGenerateAttachmentUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminGenerateAttachmentUploadUrlMutation, { data, loading, error }] = useAdminGenerateAttachmentUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useAdminGenerateAttachmentUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminGenerateAttachmentUploadUrlMutation,
    AdminGenerateAttachmentUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminGenerateAttachmentUploadUrlMutation,
    AdminGenerateAttachmentUploadUrlMutationVariables
  >(AdminGenerateAttachmentUploadUrlDocument, options);
}
export type AdminGenerateAttachmentUploadUrlMutationHookResult = ReturnType<
  typeof useAdminGenerateAttachmentUploadUrlMutation
>;
export type AdminGenerateAttachmentUploadUrlMutationResult =
  Apollo.MutationResult<AdminGenerateAttachmentUploadUrlMutation>;
export type AdminGenerateAttachmentUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  AdminGenerateAttachmentUploadUrlMutation,
  AdminGenerateAttachmentUploadUrlMutationVariables
>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($commentID: String!) {
  deleteComment(commentID: $commentID) {
    status
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentID: // value for 'commentID'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(
    DeleteCommentDocument,
    options,
  );
}
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const AdminCreateCoverDocument = gql`
    mutation adminCreateCover($input: CreateCoverInput!) {
  adminCreateCover(input: $input) {
    id
    title
    primary {
      mobile
      desktop
    }
    secondary {
      mobile
      desktop
    }
    tertiary {
      mobile
      desktop
    }
  }
}
    `;
export type AdminCreateCoverMutationFn = Apollo.MutationFunction<
  AdminCreateCoverMutation,
  AdminCreateCoverMutationVariables
>;

/**
 * __useAdminCreateCoverMutation__
 *
 * To run a mutation, you first call `useAdminCreateCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateCoverMutation, { data, loading, error }] = useAdminCreateCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateCoverMutation,
    AdminCreateCoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateCoverMutation, AdminCreateCoverMutationVariables>(
    AdminCreateCoverDocument,
    options,
  );
}
export type AdminCreateCoverMutationHookResult = ReturnType<typeof useAdminCreateCoverMutation>;
export type AdminCreateCoverMutationResult = Apollo.MutationResult<AdminCreateCoverMutation>;
export type AdminCreateCoverMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateCoverMutation,
  AdminCreateCoverMutationVariables
>;
export const AdminEditCoverDocument = gql`
    mutation adminEditCover($id: String!, $input: EditCoverInput!) {
  adminEditCover(id: $id, input: $input) {
    id
    title
    primary {
      mobile
      desktop
    }
    secondary {
      mobile
      desktop
    }
    tertiary {
      mobile
      desktop
    }
  }
}
    `;
export type AdminEditCoverMutationFn = Apollo.MutationFunction<
  AdminEditCoverMutation,
  AdminEditCoverMutationVariables
>;

/**
 * __useAdminEditCoverMutation__
 *
 * To run a mutation, you first call `useAdminEditCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditCoverMutation, { data, loading, error }] = useAdminEditCoverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditCoverMutation, AdminEditCoverMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditCoverMutation, AdminEditCoverMutationVariables>(
    AdminEditCoverDocument,
    options,
  );
}
export type AdminEditCoverMutationHookResult = ReturnType<typeof useAdminEditCoverMutation>;
export type AdminEditCoverMutationResult = Apollo.MutationResult<AdminEditCoverMutation>;
export type AdminEditCoverMutationOptions = Apollo.BaseMutationOptions<
  AdminEditCoverMutation,
  AdminEditCoverMutationVariables
>;
export const AdminDeleteCoverDocument = gql`
    mutation adminDeleteCover($id: String!) {
  adminDeleteCover(id: $id) {
    status
  }
}
    `;
export type AdminDeleteCoverMutationFn = Apollo.MutationFunction<
  AdminDeleteCoverMutation,
  AdminDeleteCoverMutationVariables
>;

/**
 * __useAdminDeleteCoverMutation__
 *
 * To run a mutation, you first call `useAdminDeleteCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteCoverMutation, { data, loading, error }] = useAdminDeleteCoverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteCoverMutation,
    AdminDeleteCoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteCoverMutation, AdminDeleteCoverMutationVariables>(
    AdminDeleteCoverDocument,
    options,
  );
}
export type AdminDeleteCoverMutationHookResult = ReturnType<typeof useAdminDeleteCoverMutation>;
export type AdminDeleteCoverMutationResult = Apollo.MutationResult<AdminDeleteCoverMutation>;
export type AdminDeleteCoverMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteCoverMutation,
  AdminDeleteCoverMutationVariables
>;
export const AdminDeleteEventDocument = gql`
    mutation adminDeleteEvent($id: String!) {
  adminDeleteEvent(id: $id) {
    status
  }
}
    `;
export type AdminDeleteEventMutationFn = Apollo.MutationFunction<
  AdminDeleteEventMutation,
  AdminDeleteEventMutationVariables
>;

/**
 * __useAdminDeleteEventMutation__
 *
 * To run a mutation, you first call `useAdminDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteEventMutation, { data, loading, error }] = useAdminDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteEventMutation,
    AdminDeleteEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteEventMutation, AdminDeleteEventMutationVariables>(
    AdminDeleteEventDocument,
    options,
  );
}
export type AdminDeleteEventMutationHookResult = ReturnType<typeof useAdminDeleteEventMutation>;
export type AdminDeleteEventMutationResult = Apollo.MutationResult<AdminDeleteEventMutation>;
export type AdminDeleteEventMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteEventMutation,
  AdminDeleteEventMutationVariables
>;
export const AdminEditEventDocument = gql`
    mutation adminEditEvent($id: String!, $input: EditEventInput!) {
  adminEditEvent(id: $id, input: $input) {
    status
  }
}
    `;
export type AdminEditEventMutationFn = Apollo.MutationFunction<
  AdminEditEventMutation,
  AdminEditEventMutationVariables
>;

/**
 * __useAdminEditEventMutation__
 *
 * To run a mutation, you first call `useAdminEditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditEventMutation, { data, loading, error }] = useAdminEditEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditEventMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditEventMutation, AdminEditEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditEventMutation, AdminEditEventMutationVariables>(
    AdminEditEventDocument,
    options,
  );
}
export type AdminEditEventMutationHookResult = ReturnType<typeof useAdminEditEventMutation>;
export type AdminEditEventMutationResult = Apollo.MutationResult<AdminEditEventMutation>;
export type AdminEditEventMutationOptions = Apollo.BaseMutationOptions<
  AdminEditEventMutation,
  AdminEditEventMutationVariables
>;
export const AdminRepublishEventDocument = gql`
    mutation adminRepublishEvent($eventId: String!) {
  adminRepublishEvent(eventId: $eventId) {
    status
  }
}
    `;
export type AdminRepublishEventMutationFn = Apollo.MutationFunction<
  AdminRepublishEventMutation,
  AdminRepublishEventMutationVariables
>;

/**
 * __useAdminRepublishEventMutation__
 *
 * To run a mutation, you first call `useAdminRepublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRepublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRepublishEventMutation, { data, loading, error }] = useAdminRepublishEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useAdminRepublishEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRepublishEventMutation,
    AdminRepublishEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminRepublishEventMutation, AdminRepublishEventMutationVariables>(
    AdminRepublishEventDocument,
    options,
  );
}
export type AdminRepublishEventMutationHookResult = ReturnType<
  typeof useAdminRepublishEventMutation
>;
export type AdminRepublishEventMutationResult = Apollo.MutationResult<AdminRepublishEventMutation>;
export type AdminRepublishEventMutationOptions = Apollo.BaseMutationOptions<
  AdminRepublishEventMutation,
  AdminRepublishEventMutationVariables
>;
export const ValidateUsersForEmailBulkInviteDocument = gql`
    mutation validateUsersForEmailBulkInvite($eventId: String!, $input: BulkInviteWithEmailsInput!) {
  validateUsersForEmailBulkInvite(eventId: $eventId, input: $input) {
    userIds
    warningMessages
  }
}
    `;
export type ValidateUsersForEmailBulkInviteMutationFn = Apollo.MutationFunction<
  ValidateUsersForEmailBulkInviteMutation,
  ValidateUsersForEmailBulkInviteMutationVariables
>;

/**
 * __useValidateUsersForEmailBulkInviteMutation__
 *
 * To run a mutation, you first call `useValidateUsersForEmailBulkInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateUsersForEmailBulkInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateUsersForEmailBulkInviteMutation, { data, loading, error }] = useValidateUsersForEmailBulkInviteMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateUsersForEmailBulkInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateUsersForEmailBulkInviteMutation,
    ValidateUsersForEmailBulkInviteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateUsersForEmailBulkInviteMutation,
    ValidateUsersForEmailBulkInviteMutationVariables
  >(ValidateUsersForEmailBulkInviteDocument, options);
}
export type ValidateUsersForEmailBulkInviteMutationHookResult = ReturnType<
  typeof useValidateUsersForEmailBulkInviteMutation
>;
export type ValidateUsersForEmailBulkInviteMutationResult =
  Apollo.MutationResult<ValidateUsersForEmailBulkInviteMutation>;
export type ValidateUsersForEmailBulkInviteMutationOptions = Apollo.BaseMutationOptions<
  ValidateUsersForEmailBulkInviteMutation,
  ValidateUsersForEmailBulkInviteMutationVariables
>;
export const BulkInviteWithUserIdsDocument = gql`
    mutation bulkInviteWithUserIds($eventId: String!, $input: BulkInviteWithUserIdsInput!) {
  bulkInviteWithUserIds(eventId: $eventId, input: $input) {
    warningMessages
  }
}
    `;
export type BulkInviteWithUserIdsMutationFn = Apollo.MutationFunction<
  BulkInviteWithUserIdsMutation,
  BulkInviteWithUserIdsMutationVariables
>;

/**
 * __useBulkInviteWithUserIdsMutation__
 *
 * To run a mutation, you first call `useBulkInviteWithUserIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkInviteWithUserIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkInviteWithUserIdsMutation, { data, loading, error }] = useBulkInviteWithUserIdsMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkInviteWithUserIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkInviteWithUserIdsMutation,
    BulkInviteWithUserIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkInviteWithUserIdsMutation, BulkInviteWithUserIdsMutationVariables>(
    BulkInviteWithUserIdsDocument,
    options,
  );
}
export type BulkInviteWithUserIdsMutationHookResult = ReturnType<
  typeof useBulkInviteWithUserIdsMutation
>;
export type BulkInviteWithUserIdsMutationResult =
  Apollo.MutationResult<BulkInviteWithUserIdsMutation>;
export type BulkInviteWithUserIdsMutationOptions = Apollo.BaseMutationOptions<
  BulkInviteWithUserIdsMutation,
  BulkInviteWithUserIdsMutationVariables
>;
export const ValidateForSelectionBulkInviteDocument = gql`
    mutation validateForSelectionBulkInvite($eventId: String!, $filters: AdminFilterUsersInput!) {
  validateForSelectionBulkInvite(eventId: $eventId, filters: $filters) {
    eventTitle
    userIds
    warningMessages
  }
}
    `;
export type ValidateForSelectionBulkInviteMutationFn = Apollo.MutationFunction<
  ValidateForSelectionBulkInviteMutation,
  ValidateForSelectionBulkInviteMutationVariables
>;

/**
 * __useValidateForSelectionBulkInviteMutation__
 *
 * To run a mutation, you first call `useValidateForSelectionBulkInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateForSelectionBulkInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateForSelectionBulkInviteMutation, { data, loading, error }] = useValidateForSelectionBulkInviteMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useValidateForSelectionBulkInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateForSelectionBulkInviteMutation,
    ValidateForSelectionBulkInviteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateForSelectionBulkInviteMutation,
    ValidateForSelectionBulkInviteMutationVariables
  >(ValidateForSelectionBulkInviteDocument, options);
}
export type ValidateForSelectionBulkInviteMutationHookResult = ReturnType<
  typeof useValidateForSelectionBulkInviteMutation
>;
export type ValidateForSelectionBulkInviteMutationResult =
  Apollo.MutationResult<ValidateForSelectionBulkInviteMutation>;
export type ValidateForSelectionBulkInviteMutationOptions = Apollo.BaseMutationOptions<
  ValidateForSelectionBulkInviteMutation,
  ValidateForSelectionBulkInviteMutationVariables
>;
export const AdminCreateFeatureFlagDocument = gql`
    mutation adminCreateFeatureFlag($input: CreateFeatureFlagInput!) {
  adminCreateFeatureFlag(input: $input) {
    id
    label
    active
  }
}
    `;
export type AdminCreateFeatureFlagMutationFn = Apollo.MutationFunction<
  AdminCreateFeatureFlagMutation,
  AdminCreateFeatureFlagMutationVariables
>;

/**
 * __useAdminCreateFeatureFlagMutation__
 *
 * To run a mutation, you first call `useAdminCreateFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateFeatureFlagMutation, { data, loading, error }] = useAdminCreateFeatureFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateFeatureFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateFeatureFlagMutation,
    AdminCreateFeatureFlagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateFeatureFlagMutation,
    AdminCreateFeatureFlagMutationVariables
  >(AdminCreateFeatureFlagDocument, options);
}
export type AdminCreateFeatureFlagMutationHookResult = ReturnType<
  typeof useAdminCreateFeatureFlagMutation
>;
export type AdminCreateFeatureFlagMutationResult =
  Apollo.MutationResult<AdminCreateFeatureFlagMutation>;
export type AdminCreateFeatureFlagMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateFeatureFlagMutation,
  AdminCreateFeatureFlagMutationVariables
>;
export const AdminEditFeatureFlagDocument = gql`
    mutation adminEditFeatureFlag($id: String!, $input: EditFeatureFlagInput!) {
  adminEditFeatureFlag(id: $id, input: $input) {
    id
    label
    active
  }
}
    `;
export type AdminEditFeatureFlagMutationFn = Apollo.MutationFunction<
  AdminEditFeatureFlagMutation,
  AdminEditFeatureFlagMutationVariables
>;

/**
 * __useAdminEditFeatureFlagMutation__
 *
 * To run a mutation, you first call `useAdminEditFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditFeatureFlagMutation, { data, loading, error }] = useAdminEditFeatureFlagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditFeatureFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditFeatureFlagMutation,
    AdminEditFeatureFlagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditFeatureFlagMutation, AdminEditFeatureFlagMutationVariables>(
    AdminEditFeatureFlagDocument,
    options,
  );
}
export type AdminEditFeatureFlagMutationHookResult = ReturnType<
  typeof useAdminEditFeatureFlagMutation
>;
export type AdminEditFeatureFlagMutationResult =
  Apollo.MutationResult<AdminEditFeatureFlagMutation>;
export type AdminEditFeatureFlagMutationOptions = Apollo.BaseMutationOptions<
  AdminEditFeatureFlagMutation,
  AdminEditFeatureFlagMutationVariables
>;
export const AdminDeleteFeatureFlagDocument = gql`
    mutation adminDeleteFeatureFlag($id: String!) {
  adminDeleteFeatureFlag(id: $id) {
    reason
    status
  }
}
    `;
export type AdminDeleteFeatureFlagMutationFn = Apollo.MutationFunction<
  AdminDeleteFeatureFlagMutation,
  AdminDeleteFeatureFlagMutationVariables
>;

/**
 * __useAdminDeleteFeatureFlagMutation__
 *
 * To run a mutation, you first call `useAdminDeleteFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteFeatureFlagMutation, { data, loading, error }] = useAdminDeleteFeatureFlagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteFeatureFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteFeatureFlagMutation,
    AdminDeleteFeatureFlagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminDeleteFeatureFlagMutation,
    AdminDeleteFeatureFlagMutationVariables
  >(AdminDeleteFeatureFlagDocument, options);
}
export type AdminDeleteFeatureFlagMutationHookResult = ReturnType<
  typeof useAdminDeleteFeatureFlagMutation
>;
export type AdminDeleteFeatureFlagMutationResult =
  Apollo.MutationResult<AdminDeleteFeatureFlagMutation>;
export type AdminDeleteFeatureFlagMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteFeatureFlagMutation,
  AdminDeleteFeatureFlagMutationVariables
>;
export const AdminDeleteIllustrationDocument = gql`
    mutation adminDeleteIllustration($id: String!) {
  adminDeleteIllustration(id: $id) {
    status
  }
}
    `;
export type AdminDeleteIllustrationMutationFn = Apollo.MutationFunction<
  AdminDeleteIllustrationMutation,
  AdminDeleteIllustrationMutationVariables
>;

/**
 * __useAdminDeleteIllustrationMutation__
 *
 * To run a mutation, you first call `useAdminDeleteIllustrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteIllustrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteIllustrationMutation, { data, loading, error }] = useAdminDeleteIllustrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteIllustrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteIllustrationMutation,
    AdminDeleteIllustrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminDeleteIllustrationMutation,
    AdminDeleteIllustrationMutationVariables
  >(AdminDeleteIllustrationDocument, options);
}
export type AdminDeleteIllustrationMutationHookResult = ReturnType<
  typeof useAdminDeleteIllustrationMutation
>;
export type AdminDeleteIllustrationMutationResult =
  Apollo.MutationResult<AdminDeleteIllustrationMutation>;
export type AdminDeleteIllustrationMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteIllustrationMutation,
  AdminDeleteIllustrationMutationVariables
>;
export const AdminCreateIllustrationDocument = gql`
    mutation adminCreateIllustration($input: CreateIllustrationInput!) {
  adminCreateIllustration(input: $input) {
    id
    title
    url
  }
}
    `;
export type AdminCreateIllustrationMutationFn = Apollo.MutationFunction<
  AdminCreateIllustrationMutation,
  AdminCreateIllustrationMutationVariables
>;

/**
 * __useAdminCreateIllustrationMutation__
 *
 * To run a mutation, you first call `useAdminCreateIllustrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateIllustrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateIllustrationMutation, { data, loading, error }] = useAdminCreateIllustrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateIllustrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateIllustrationMutation,
    AdminCreateIllustrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateIllustrationMutation,
    AdminCreateIllustrationMutationVariables
  >(AdminCreateIllustrationDocument, options);
}
export type AdminCreateIllustrationMutationHookResult = ReturnType<
  typeof useAdminCreateIllustrationMutation
>;
export type AdminCreateIllustrationMutationResult =
  Apollo.MutationResult<AdminCreateIllustrationMutation>;
export type AdminCreateIllustrationMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateIllustrationMutation,
  AdminCreateIllustrationMutationVariables
>;
export const AdminEditIllustrationDocument = gql`
    mutation adminEditIllustration($id: String!, $input: EditIllustrationInput!) {
  adminEditIllustration(id: $id, input: $input) {
    id
    title
    url
  }
}
    `;
export type AdminEditIllustrationMutationFn = Apollo.MutationFunction<
  AdminEditIllustrationMutation,
  AdminEditIllustrationMutationVariables
>;

/**
 * __useAdminEditIllustrationMutation__
 *
 * To run a mutation, you first call `useAdminEditIllustrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditIllustrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditIllustrationMutation, { data, loading, error }] = useAdminEditIllustrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditIllustrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditIllustrationMutation,
    AdminEditIllustrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditIllustrationMutation, AdminEditIllustrationMutationVariables>(
    AdminEditIllustrationDocument,
    options,
  );
}
export type AdminEditIllustrationMutationHookResult = ReturnType<
  typeof useAdminEditIllustrationMutation
>;
export type AdminEditIllustrationMutationResult =
  Apollo.MutationResult<AdminEditIllustrationMutation>;
export type AdminEditIllustrationMutationOptions = Apollo.BaseMutationOptions<
  AdminEditIllustrationMutation,
  AdminEditIllustrationMutationVariables
>;
export const AdminDeleteLessonDocument = gql`
    mutation adminDeleteLesson($id: String!) {
  adminDeleteLesson(id: $id) {
    reason
    status
  }
}
    `;
export type AdminDeleteLessonMutationFn = Apollo.MutationFunction<
  AdminDeleteLessonMutation,
  AdminDeleteLessonMutationVariables
>;

/**
 * __useAdminDeleteLessonMutation__
 *
 * To run a mutation, you first call `useAdminDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteLessonMutation, { data, loading, error }] = useAdminDeleteLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteLessonMutation,
    AdminDeleteLessonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteLessonMutation, AdminDeleteLessonMutationVariables>(
    AdminDeleteLessonDocument,
    options,
  );
}
export type AdminDeleteLessonMutationHookResult = ReturnType<typeof useAdminDeleteLessonMutation>;
export type AdminDeleteLessonMutationResult = Apollo.MutationResult<AdminDeleteLessonMutation>;
export type AdminDeleteLessonMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteLessonMutation,
  AdminDeleteLessonMutationVariables
>;
export const AdminCreateLessonDocument = gql`
    mutation adminCreateLesson($input: CreateLessonInput!) {
  adminCreateLesson(input: $input) {
    id
    sections {
      id
      order
      elements {
        kind
        meta
      }
    }
  }
}
    `;
export type AdminCreateLessonMutationFn = Apollo.MutationFunction<
  AdminCreateLessonMutation,
  AdminCreateLessonMutationVariables
>;

/**
 * __useAdminCreateLessonMutation__
 *
 * To run a mutation, you first call `useAdminCreateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateLessonMutation, { data, loading, error }] = useAdminCreateLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateLessonMutation,
    AdminCreateLessonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateLessonMutation, AdminCreateLessonMutationVariables>(
    AdminCreateLessonDocument,
    options,
  );
}
export type AdminCreateLessonMutationHookResult = ReturnType<typeof useAdminCreateLessonMutation>;
export type AdminCreateLessonMutationResult = Apollo.MutationResult<AdminCreateLessonMutation>;
export type AdminCreateLessonMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateLessonMutation,
  AdminCreateLessonMutationVariables
>;
export const AdminEditLessonDocument = gql`
    mutation adminEditLesson($id: String!, $input: EditLessonInput!) {
  adminEditLesson(id: $id, input: $input) {
    id
    deepDiveKind
    kind
  }
}
    `;
export type AdminEditLessonMutationFn = Apollo.MutationFunction<
  AdminEditLessonMutation,
  AdminEditLessonMutationVariables
>;

/**
 * __useAdminEditLessonMutation__
 *
 * To run a mutation, you first call `useAdminEditLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditLessonMutation, { data, loading, error }] = useAdminEditLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditLessonMutation,
    AdminEditLessonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditLessonMutation, AdminEditLessonMutationVariables>(
    AdminEditLessonDocument,
    options,
  );
}
export type AdminEditLessonMutationHookResult = ReturnType<typeof useAdminEditLessonMutation>;
export type AdminEditLessonMutationResult = Apollo.MutationResult<AdminEditLessonMutation>;
export type AdminEditLessonMutationOptions = Apollo.BaseMutationOptions<
  AdminEditLessonMutation,
  AdminEditLessonMutationVariables
>;
export const TriggerBackupEventsParticipantsDocument = gql`
    mutation triggerBackupEventsParticipants {
  triggerBackupEventsParticipants {
    status
  }
}
    `;
export type TriggerBackupEventsParticipantsMutationFn = Apollo.MutationFunction<
  TriggerBackupEventsParticipantsMutation,
  TriggerBackupEventsParticipantsMutationVariables
>;

/**
 * __useTriggerBackupEventsParticipantsMutation__
 *
 * To run a mutation, you first call `useTriggerBackupEventsParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerBackupEventsParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerBackupEventsParticipantsMutation, { data, loading, error }] = useTriggerBackupEventsParticipantsMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerBackupEventsParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerBackupEventsParticipantsMutation,
    TriggerBackupEventsParticipantsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerBackupEventsParticipantsMutation,
    TriggerBackupEventsParticipantsMutationVariables
  >(TriggerBackupEventsParticipantsDocument, options);
}
export type TriggerBackupEventsParticipantsMutationHookResult = ReturnType<
  typeof useTriggerBackupEventsParticipantsMutation
>;
export type TriggerBackupEventsParticipantsMutationResult =
  Apollo.MutationResult<TriggerBackupEventsParticipantsMutation>;
export type TriggerBackupEventsParticipantsMutationOptions = Apollo.BaseMutationOptions<
  TriggerBackupEventsParticipantsMutation,
  TriggerBackupEventsParticipantsMutationVariables
>;
export const TriggerUserPermissionPromotionDocument = gql`
    mutation triggerUserPermissionPromotion {
  triggerUserPermissionPromotion {
    status
  }
}
    `;
export type TriggerUserPermissionPromotionMutationFn = Apollo.MutationFunction<
  TriggerUserPermissionPromotionMutation,
  TriggerUserPermissionPromotionMutationVariables
>;

/**
 * __useTriggerUserPermissionPromotionMutation__
 *
 * To run a mutation, you first call `useTriggerUserPermissionPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerUserPermissionPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerUserPermissionPromotionMutation, { data, loading, error }] = useTriggerUserPermissionPromotionMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerUserPermissionPromotionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerUserPermissionPromotionMutation,
    TriggerUserPermissionPromotionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerUserPermissionPromotionMutation,
    TriggerUserPermissionPromotionMutationVariables
  >(TriggerUserPermissionPromotionDocument, options);
}
export type TriggerUserPermissionPromotionMutationHookResult = ReturnType<
  typeof useTriggerUserPermissionPromotionMutation
>;
export type TriggerUserPermissionPromotionMutationResult =
  Apollo.MutationResult<TriggerUserPermissionPromotionMutation>;
export type TriggerUserPermissionPromotionMutationOptions = Apollo.BaseMutationOptions<
  TriggerUserPermissionPromotionMutation,
  TriggerUserPermissionPromotionMutationVariables
>;
export const TriggerUserDeletionAfterRetentionDocument = gql`
    mutation triggerUserDeletionAfterRetention {
  triggerUserDeletionAfterRetention {
    status
  }
}
    `;
export type TriggerUserDeletionAfterRetentionMutationFn = Apollo.MutationFunction<
  TriggerUserDeletionAfterRetentionMutation,
  TriggerUserDeletionAfterRetentionMutationVariables
>;

/**
 * __useTriggerUserDeletionAfterRetentionMutation__
 *
 * To run a mutation, you first call `useTriggerUserDeletionAfterRetentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerUserDeletionAfterRetentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerUserDeletionAfterRetentionMutation, { data, loading, error }] = useTriggerUserDeletionAfterRetentionMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerUserDeletionAfterRetentionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerUserDeletionAfterRetentionMutation,
    TriggerUserDeletionAfterRetentionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerUserDeletionAfterRetentionMutation,
    TriggerUserDeletionAfterRetentionMutationVariables
  >(TriggerUserDeletionAfterRetentionDocument, options);
}
export type TriggerUserDeletionAfterRetentionMutationHookResult = ReturnType<
  typeof useTriggerUserDeletionAfterRetentionMutation
>;
export type TriggerUserDeletionAfterRetentionMutationResult =
  Apollo.MutationResult<TriggerUserDeletionAfterRetentionMutation>;
export type TriggerUserDeletionAfterRetentionMutationOptions = Apollo.BaseMutationOptions<
  TriggerUserDeletionAfterRetentionMutation,
  TriggerUserDeletionAfterRetentionMutationVariables
>;
export const TriggerChatServiceSyncDocument = gql`
    mutation triggerChatServiceSync {
  triggerChatServiceSync {
    status
  }
}
    `;
export type TriggerChatServiceSyncMutationFn = Apollo.MutationFunction<
  TriggerChatServiceSyncMutation,
  TriggerChatServiceSyncMutationVariables
>;

/**
 * __useTriggerChatServiceSyncMutation__
 *
 * To run a mutation, you first call `useTriggerChatServiceSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerChatServiceSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerChatServiceSyncMutation, { data, loading, error }] = useTriggerChatServiceSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerChatServiceSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerChatServiceSyncMutation,
    TriggerChatServiceSyncMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerChatServiceSyncMutation,
    TriggerChatServiceSyncMutationVariables
  >(TriggerChatServiceSyncDocument, options);
}
export type TriggerChatServiceSyncMutationHookResult = ReturnType<
  typeof useTriggerChatServiceSyncMutation
>;
export type TriggerChatServiceSyncMutationResult =
  Apollo.MutationResult<TriggerChatServiceSyncMutation>;
export type TriggerChatServiceSyncMutationOptions = Apollo.BaseMutationOptions<
  TriggerChatServiceSyncMutation,
  TriggerChatServiceSyncMutationVariables
>;
export const TriggerRecreateZoomMeetingsDocument = gql`
    mutation triggerRecreateZoomMeetings {
  triggerRecreateZoomMeetings {
    status
  }
}
    `;
export type TriggerRecreateZoomMeetingsMutationFn = Apollo.MutationFunction<
  TriggerRecreateZoomMeetingsMutation,
  TriggerRecreateZoomMeetingsMutationVariables
>;

/**
 * __useTriggerRecreateZoomMeetingsMutation__
 *
 * To run a mutation, you first call `useTriggerRecreateZoomMeetingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerRecreateZoomMeetingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerRecreateZoomMeetingsMutation, { data, loading, error }] = useTriggerRecreateZoomMeetingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerRecreateZoomMeetingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerRecreateZoomMeetingsMutation,
    TriggerRecreateZoomMeetingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerRecreateZoomMeetingsMutation,
    TriggerRecreateZoomMeetingsMutationVariables
  >(TriggerRecreateZoomMeetingsDocument, options);
}
export type TriggerRecreateZoomMeetingsMutationHookResult = ReturnType<
  typeof useTriggerRecreateZoomMeetingsMutation
>;
export type TriggerRecreateZoomMeetingsMutationResult =
  Apollo.MutationResult<TriggerRecreateZoomMeetingsMutation>;
export type TriggerRecreateZoomMeetingsMutationOptions = Apollo.BaseMutationOptions<
  TriggerRecreateZoomMeetingsMutation,
  TriggerRecreateZoomMeetingsMutationVariables
>;
export const TriggerRecreateDailyMeetingsDocument = gql`
    mutation triggerRecreateDailyMeetings {
  triggerRecreateDailyMeetings {
    status
  }
}
    `;
export type TriggerRecreateDailyMeetingsMutationFn = Apollo.MutationFunction<
  TriggerRecreateDailyMeetingsMutation,
  TriggerRecreateDailyMeetingsMutationVariables
>;

/**
 * __useTriggerRecreateDailyMeetingsMutation__
 *
 * To run a mutation, you first call `useTriggerRecreateDailyMeetingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerRecreateDailyMeetingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerRecreateDailyMeetingsMutation, { data, loading, error }] = useTriggerRecreateDailyMeetingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerRecreateDailyMeetingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerRecreateDailyMeetingsMutation,
    TriggerRecreateDailyMeetingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerRecreateDailyMeetingsMutation,
    TriggerRecreateDailyMeetingsMutationVariables
  >(TriggerRecreateDailyMeetingsDocument, options);
}
export type TriggerRecreateDailyMeetingsMutationHookResult = ReturnType<
  typeof useTriggerRecreateDailyMeetingsMutation
>;
export type TriggerRecreateDailyMeetingsMutationResult =
  Apollo.MutationResult<TriggerRecreateDailyMeetingsMutation>;
export type TriggerRecreateDailyMeetingsMutationOptions = Apollo.BaseMutationOptions<
  TriggerRecreateDailyMeetingsMutation,
  TriggerRecreateDailyMeetingsMutationVariables
>;
export const TriggerAssignAllUserBadgesDocument = gql`
    mutation triggerAssignAllUserBadges {
  triggerAssignAllUserBadges {
    status
  }
}
    `;
export type TriggerAssignAllUserBadgesMutationFn = Apollo.MutationFunction<
  TriggerAssignAllUserBadgesMutation,
  TriggerAssignAllUserBadgesMutationVariables
>;

/**
 * __useTriggerAssignAllUserBadgesMutation__
 *
 * To run a mutation, you first call `useTriggerAssignAllUserBadgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerAssignAllUserBadgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerAssignAllUserBadgesMutation, { data, loading, error }] = useTriggerAssignAllUserBadgesMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerAssignAllUserBadgesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerAssignAllUserBadgesMutation,
    TriggerAssignAllUserBadgesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerAssignAllUserBadgesMutation,
    TriggerAssignAllUserBadgesMutationVariables
  >(TriggerAssignAllUserBadgesDocument, options);
}
export type TriggerAssignAllUserBadgesMutationHookResult = ReturnType<
  typeof useTriggerAssignAllUserBadgesMutation
>;
export type TriggerAssignAllUserBadgesMutationResult =
  Apollo.MutationResult<TriggerAssignAllUserBadgesMutation>;
export type TriggerAssignAllUserBadgesMutationOptions = Apollo.BaseMutationOptions<
  TriggerAssignAllUserBadgesMutation,
  TriggerAssignAllUserBadgesMutationVariables
>;
export const IssueCompleteProfileNotificationDocument = gql`
    mutation issueCompleteProfileNotification {
  issueCompleteProfileNotification {
    status
  }
}
    `;
export type IssueCompleteProfileNotificationMutationFn = Apollo.MutationFunction<
  IssueCompleteProfileNotificationMutation,
  IssueCompleteProfileNotificationMutationVariables
>;

/**
 * __useIssueCompleteProfileNotificationMutation__
 *
 * To run a mutation, you first call `useIssueCompleteProfileNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueCompleteProfileNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueCompleteProfileNotificationMutation, { data, loading, error }] = useIssueCompleteProfileNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useIssueCompleteProfileNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IssueCompleteProfileNotificationMutation,
    IssueCompleteProfileNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IssueCompleteProfileNotificationMutation,
    IssueCompleteProfileNotificationMutationVariables
  >(IssueCompleteProfileNotificationDocument, options);
}
export type IssueCompleteProfileNotificationMutationHookResult = ReturnType<
  typeof useIssueCompleteProfileNotificationMutation
>;
export type IssueCompleteProfileNotificationMutationResult =
  Apollo.MutationResult<IssueCompleteProfileNotificationMutation>;
export type IssueCompleteProfileNotificationMutationOptions = Apollo.BaseMutationOptions<
  IssueCompleteProfileNotificationMutation,
  IssueCompleteProfileNotificationMutationVariables
>;
export const UploadActiveDaysDocument = gql`
    mutation uploadActiveDays($input: UploadActiveDaysInput!) {
  uploadActiveDays(input: $input) {
    status
  }
}
    `;
export type UploadActiveDaysMutationFn = Apollo.MutationFunction<
  UploadActiveDaysMutation,
  UploadActiveDaysMutationVariables
>;

/**
 * __useUploadActiveDaysMutation__
 *
 * To run a mutation, you first call `useUploadActiveDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadActiveDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadActiveDaysMutation, { data, loading, error }] = useUploadActiveDaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadActiveDaysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadActiveDaysMutation,
    UploadActiveDaysMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadActiveDaysMutation, UploadActiveDaysMutationVariables>(
    UploadActiveDaysDocument,
    options,
  );
}
export type UploadActiveDaysMutationHookResult = ReturnType<typeof useUploadActiveDaysMutation>;
export type UploadActiveDaysMutationResult = Apollo.MutationResult<UploadActiveDaysMutation>;
export type UploadActiveDaysMutationOptions = Apollo.BaseMutationOptions<
  UploadActiveDaysMutation,
  UploadActiveDaysMutationVariables
>;
export const AssignAllExtraReferralBadgesDocument = gql`
    mutation assignAllExtraReferralBadges {
  assignAllExtraReferralBadges {
    status
  }
}
    `;
export type AssignAllExtraReferralBadgesMutationFn = Apollo.MutationFunction<
  AssignAllExtraReferralBadgesMutation,
  AssignAllExtraReferralBadgesMutationVariables
>;

/**
 * __useAssignAllExtraReferralBadgesMutation__
 *
 * To run a mutation, you first call `useAssignAllExtraReferralBadgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAllExtraReferralBadgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAllExtraReferralBadgesMutation, { data, loading, error }] = useAssignAllExtraReferralBadgesMutation({
 *   variables: {
 *   },
 * });
 */
export function useAssignAllExtraReferralBadgesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAllExtraReferralBadgesMutation,
    AssignAllExtraReferralBadgesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAllExtraReferralBadgesMutation,
    AssignAllExtraReferralBadgesMutationVariables
  >(AssignAllExtraReferralBadgesDocument, options);
}
export type AssignAllExtraReferralBadgesMutationHookResult = ReturnType<
  typeof useAssignAllExtraReferralBadgesMutation
>;
export type AssignAllExtraReferralBadgesMutationResult =
  Apollo.MutationResult<AssignAllExtraReferralBadgesMutation>;
export type AssignAllExtraReferralBadgesMutationOptions = Apollo.BaseMutationOptions<
  AssignAllExtraReferralBadgesMutation,
  AssignAllExtraReferralBadgesMutationVariables
>;
export const AdminCreateCustomNotificationDocument = gql`
    mutation adminCreateCustomNotification {
  adminCreateCustomNotification {
    id
  }
}
    `;
export type AdminCreateCustomNotificationMutationFn = Apollo.MutationFunction<
  AdminCreateCustomNotificationMutation,
  AdminCreateCustomNotificationMutationVariables
>;

/**
 * __useAdminCreateCustomNotificationMutation__
 *
 * To run a mutation, you first call `useAdminCreateCustomNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateCustomNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateCustomNotificationMutation, { data, loading, error }] = useAdminCreateCustomNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminCreateCustomNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateCustomNotificationMutation,
    AdminCreateCustomNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateCustomNotificationMutation,
    AdminCreateCustomNotificationMutationVariables
  >(AdminCreateCustomNotificationDocument, options);
}
export type AdminCreateCustomNotificationMutationHookResult = ReturnType<
  typeof useAdminCreateCustomNotificationMutation
>;
export type AdminCreateCustomNotificationMutationResult =
  Apollo.MutationResult<AdminCreateCustomNotificationMutation>;
export type AdminCreateCustomNotificationMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateCustomNotificationMutation,
  AdminCreateCustomNotificationMutationVariables
>;
export const AdminEditCustomNotificationDocument = gql`
    mutation adminEditCustomNotification($id: String!, $input: EditCustomNotificationInput!) {
  adminEditCustomNotification(id: $id, input: $input) {
    status
  }
}
    `;
export type AdminEditCustomNotificationMutationFn = Apollo.MutationFunction<
  AdminEditCustomNotificationMutation,
  AdminEditCustomNotificationMutationVariables
>;

/**
 * __useAdminEditCustomNotificationMutation__
 *
 * To run a mutation, you first call `useAdminEditCustomNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditCustomNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditCustomNotificationMutation, { data, loading, error }] = useAdminEditCustomNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditCustomNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditCustomNotificationMutation,
    AdminEditCustomNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminEditCustomNotificationMutation,
    AdminEditCustomNotificationMutationVariables
  >(AdminEditCustomNotificationDocument, options);
}
export type AdminEditCustomNotificationMutationHookResult = ReturnType<
  typeof useAdminEditCustomNotificationMutation
>;
export type AdminEditCustomNotificationMutationResult =
  Apollo.MutationResult<AdminEditCustomNotificationMutation>;
export type AdminEditCustomNotificationMutationOptions = Apollo.BaseMutationOptions<
  AdminEditCustomNotificationMutation,
  AdminEditCustomNotificationMutationVariables
>;
export const SendSystemNotificationsDocument = gql`
    mutation sendSystemNotifications($id: String!, $emails: [String!]!) {
  sendSystemNotifications(id: $id, emails: $emails) {
    sentTo
    warningMessages
  }
}
    `;
export type SendSystemNotificationsMutationFn = Apollo.MutationFunction<
  SendSystemNotificationsMutation,
  SendSystemNotificationsMutationVariables
>;

/**
 * __useSendSystemNotificationsMutation__
 *
 * To run a mutation, you first call `useSendSystemNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSystemNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSystemNotificationsMutation, { data, loading, error }] = useSendSystemNotificationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useSendSystemNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSystemNotificationsMutation,
    SendSystemNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSystemNotificationsMutation,
    SendSystemNotificationsMutationVariables
  >(SendSystemNotificationsDocument, options);
}
export type SendSystemNotificationsMutationHookResult = ReturnType<
  typeof useSendSystemNotificationsMutation
>;
export type SendSystemNotificationsMutationResult =
  Apollo.MutationResult<SendSystemNotificationsMutation>;
export type SendSystemNotificationsMutationOptions = Apollo.BaseMutationOptions<
  SendSystemNotificationsMutation,
  SendSystemNotificationsMutationVariables
>;
export const AdminDeleteUserReportDocument = gql`
    mutation adminDeleteUserReport($id: String!) {
  adminDeleteUserReport(id: $id) {
    status
  }
}
    `;
export type AdminDeleteUserReportMutationFn = Apollo.MutationFunction<
  AdminDeleteUserReportMutation,
  AdminDeleteUserReportMutationVariables
>;

/**
 * __useAdminDeleteUserReportMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserReportMutation, { data, loading, error }] = useAdminDeleteUserReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteUserReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteUserReportMutation,
    AdminDeleteUserReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteUserReportMutation, AdminDeleteUserReportMutationVariables>(
    AdminDeleteUserReportDocument,
    options,
  );
}
export type AdminDeleteUserReportMutationHookResult = ReturnType<
  typeof useAdminDeleteUserReportMutation
>;
export type AdminDeleteUserReportMutationResult =
  Apollo.MutationResult<AdminDeleteUserReportMutation>;
export type AdminDeleteUserReportMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteUserReportMutation,
  AdminDeleteUserReportMutationVariables
>;
export const AdminCreateSectionDocument = gql`
    mutation adminCreateSection($input: CreateSectionInput!) {
  adminCreateSection(input: $input) {
    id
    order
    elements {
      kind
      meta
    }
  }
}
    `;
export type AdminCreateSectionMutationFn = Apollo.MutationFunction<
  AdminCreateSectionMutation,
  AdminCreateSectionMutationVariables
>;

/**
 * __useAdminCreateSectionMutation__
 *
 * To run a mutation, you first call `useAdminCreateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateSectionMutation, { data, loading, error }] = useAdminCreateSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateSectionMutation,
    AdminCreateSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateSectionMutation, AdminCreateSectionMutationVariables>(
    AdminCreateSectionDocument,
    options,
  );
}
export type AdminCreateSectionMutationHookResult = ReturnType<typeof useAdminCreateSectionMutation>;
export type AdminCreateSectionMutationResult = Apollo.MutationResult<AdminCreateSectionMutation>;
export type AdminCreateSectionMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateSectionMutation,
  AdminCreateSectionMutationVariables
>;
export const AdminEditSectionDocument = gql`
    mutation adminEditSection($id: String!, $input: EditSectionInput!) {
  adminEditSection(id: $id, input: $input) {
    id
    order
    elements {
      kind
      meta
    }
  }
}
    `;
export type AdminEditSectionMutationFn = Apollo.MutationFunction<
  AdminEditSectionMutation,
  AdminEditSectionMutationVariables
>;

/**
 * __useAdminEditSectionMutation__
 *
 * To run a mutation, you first call `useAdminEditSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditSectionMutation, { data, loading, error }] = useAdminEditSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditSectionMutation,
    AdminEditSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditSectionMutation, AdminEditSectionMutationVariables>(
    AdminEditSectionDocument,
    options,
  );
}
export type AdminEditSectionMutationHookResult = ReturnType<typeof useAdminEditSectionMutation>;
export type AdminEditSectionMutationResult = Apollo.MutationResult<AdminEditSectionMutation>;
export type AdminEditSectionMutationOptions = Apollo.BaseMutationOptions<
  AdminEditSectionMutation,
  AdminEditSectionMutationVariables
>;
export const AdminDeleteSectionDocument = gql`
    mutation adminDeleteSection($id: String!) {
  adminDeleteSection(id: $id) {
    reason
    status
  }
}
    `;
export type AdminDeleteSectionMutationFn = Apollo.MutationFunction<
  AdminDeleteSectionMutation,
  AdminDeleteSectionMutationVariables
>;

/**
 * __useAdminDeleteSectionMutation__
 *
 * To run a mutation, you first call `useAdminDeleteSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteSectionMutation, { data, loading, error }] = useAdminDeleteSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteSectionMutation,
    AdminDeleteSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteSectionMutation, AdminDeleteSectionMutationVariables>(
    AdminDeleteSectionDocument,
    options,
  );
}
export type AdminDeleteSectionMutationHookResult = ReturnType<typeof useAdminDeleteSectionMutation>;
export type AdminDeleteSectionMutationResult = Apollo.MutationResult<AdminDeleteSectionMutation>;
export type AdminDeleteSectionMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteSectionMutation,
  AdminDeleteSectionMutationVariables
>;
export const AdminCreateBannerDocument = gql`
    mutation adminCreateBanner($input: CreateBannerInput!) {
  adminCreateBanner(input: $input) {
    id
    isActive
  }
}
    `;
export type AdminCreateBannerMutationFn = Apollo.MutationFunction<
  AdminCreateBannerMutation,
  AdminCreateBannerMutationVariables
>;

/**
 * __useAdminCreateBannerMutation__
 *
 * To run a mutation, you first call `useAdminCreateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateBannerMutation, { data, loading, error }] = useAdminCreateBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateBannerMutation,
    AdminCreateBannerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateBannerMutation, AdminCreateBannerMutationVariables>(
    AdminCreateBannerDocument,
    options,
  );
}
export type AdminCreateBannerMutationHookResult = ReturnType<typeof useAdminCreateBannerMutation>;
export type AdminCreateBannerMutationResult = Apollo.MutationResult<AdminCreateBannerMutation>;
export type AdminCreateBannerMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateBannerMutation,
  AdminCreateBannerMutationVariables
>;
export const AdminEditBannerDocument = gql`
    mutation adminEditBanner($id: String!, $input: EditBannerInput!) {
  adminEditBanner(id: $id, input: $input) {
    id
    isActive
  }
}
    `;
export type AdminEditBannerMutationFn = Apollo.MutationFunction<
  AdminEditBannerMutation,
  AdminEditBannerMutationVariables
>;

/**
 * __useAdminEditBannerMutation__
 *
 * To run a mutation, you first call `useAdminEditBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditBannerMutation, { data, loading, error }] = useAdminEditBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditBannerMutation,
    AdminEditBannerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditBannerMutation, AdminEditBannerMutationVariables>(
    AdminEditBannerDocument,
    options,
  );
}
export type AdminEditBannerMutationHookResult = ReturnType<typeof useAdminEditBannerMutation>;
export type AdminEditBannerMutationResult = Apollo.MutationResult<AdminEditBannerMutation>;
export type AdminEditBannerMutationOptions = Apollo.BaseMutationOptions<
  AdminEditBannerMutation,
  AdminEditBannerMutationVariables
>;
export const AdminDeleteBannerDocument = gql`
    mutation adminDeleteBanner($id: String!) {
  adminDeleteBanner(id: $id) {
    status
  }
}
    `;
export type AdminDeleteBannerMutationFn = Apollo.MutationFunction<
  AdminDeleteBannerMutation,
  AdminDeleteBannerMutationVariables
>;

/**
 * __useAdminDeleteBannerMutation__
 *
 * To run a mutation, you first call `useAdminDeleteBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteBannerMutation, { data, loading, error }] = useAdminDeleteBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteBannerMutation,
    AdminDeleteBannerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteBannerMutation, AdminDeleteBannerMutationVariables>(
    AdminDeleteBannerDocument,
    options,
  );
}
export type AdminDeleteBannerMutationHookResult = ReturnType<typeof useAdminDeleteBannerMutation>;
export type AdminDeleteBannerMutationResult = Apollo.MutationResult<AdminDeleteBannerMutation>;
export type AdminDeleteBannerMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteBannerMutation,
  AdminDeleteBannerMutationVariables
>;
export const AdminCreateSliderDocument = gql`
    mutation adminCreateSlider($id: String!) {
  adminCreateSlider(id: $id) {
    status
  }
}
    `;
export type AdminCreateSliderMutationFn = Apollo.MutationFunction<
  AdminCreateSliderMutation,
  AdminCreateSliderMutationVariables
>;

/**
 * __useAdminCreateSliderMutation__
 *
 * To run a mutation, you first call `useAdminCreateSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateSliderMutation, { data, loading, error }] = useAdminCreateSliderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminCreateSliderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateSliderMutation,
    AdminCreateSliderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateSliderMutation, AdminCreateSliderMutationVariables>(
    AdminCreateSliderDocument,
    options,
  );
}
export type AdminCreateSliderMutationHookResult = ReturnType<typeof useAdminCreateSliderMutation>;
export type AdminCreateSliderMutationResult = Apollo.MutationResult<AdminCreateSliderMutation>;
export type AdminCreateSliderMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateSliderMutation,
  AdminCreateSliderMutationVariables
>;
export const AdminDeleteSliderDocument = gql`
    mutation adminDeleteSlider($id: String!) {
  adminDeleteSlider(id: $id) {
    status
  }
}
    `;
export type AdminDeleteSliderMutationFn = Apollo.MutationFunction<
  AdminDeleteSliderMutation,
  AdminDeleteSliderMutationVariables
>;

/**
 * __useAdminDeleteSliderMutation__
 *
 * To run a mutation, you first call `useAdminDeleteSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteSliderMutation, { data, loading, error }] = useAdminDeleteSliderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteSliderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteSliderMutation,
    AdminDeleteSliderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteSliderMutation, AdminDeleteSliderMutationVariables>(
    AdminDeleteSliderDocument,
    options,
  );
}
export type AdminDeleteSliderMutationHookResult = ReturnType<typeof useAdminDeleteSliderMutation>;
export type AdminDeleteSliderMutationResult = Apollo.MutationResult<AdminDeleteSliderMutation>;
export type AdminDeleteSliderMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteSliderMutation,
  AdminDeleteSliderMutationVariables
>;
export const AdminEditSlideDocument = gql`
    mutation adminEditSlide($id: String!, $input: EditSliderInput!) {
  adminEditSlide(id: $id, input: $input) {
    id
  }
}
    `;
export type AdminEditSlideMutationFn = Apollo.MutationFunction<
  AdminEditSlideMutation,
  AdminEditSlideMutationVariables
>;

/**
 * __useAdminEditSlideMutation__
 *
 * To run a mutation, you first call `useAdminEditSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditSlideMutation, { data, loading, error }] = useAdminEditSlideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditSlideMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditSlideMutation, AdminEditSlideMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditSlideMutation, AdminEditSlideMutationVariables>(
    AdminEditSlideDocument,
    options,
  );
}
export type AdminEditSlideMutationHookResult = ReturnType<typeof useAdminEditSlideMutation>;
export type AdminEditSlideMutationResult = Apollo.MutationResult<AdminEditSlideMutation>;
export type AdminEditSlideMutationOptions = Apollo.BaseMutationOptions<
  AdminEditSlideMutation,
  AdminEditSlideMutationVariables
>;
export const AdminOrderSliderDocument = gql`
    mutation adminOrderSlider($id: String!, $input: String!) {
  adminOrderSlider(id: $id, input: $input) {
    status
  }
}
    `;
export type AdminOrderSliderMutationFn = Apollo.MutationFunction<
  AdminOrderSliderMutation,
  AdminOrderSliderMutationVariables
>;

/**
 * __useAdminOrderSliderMutation__
 *
 * To run a mutation, you first call `useAdminOrderSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminOrderSliderMutation, { data, loading, error }] = useAdminOrderSliderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminOrderSliderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminOrderSliderMutation,
    AdminOrderSliderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminOrderSliderMutation, AdminOrderSliderMutationVariables>(
    AdminOrderSliderDocument,
    options,
  );
}
export type AdminOrderSliderMutationHookResult = ReturnType<typeof useAdminOrderSliderMutation>;
export type AdminOrderSliderMutationResult = Apollo.MutationResult<AdminOrderSliderMutation>;
export type AdminOrderSliderMutationOptions = Apollo.BaseMutationOptions<
  AdminOrderSliderMutation,
  AdminOrderSliderMutationVariables
>;
export const AdminCreateThemeDocument = gql`
    mutation adminCreateTheme($input: CreateThemeInput!) {
  adminCreateTheme(input: $input) {
    id
    title
    description
    isGrammarLibrary
    isTopicLibrary
    isResource
    isStandard
    isCurrent
    cover {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
  }
}
    `;
export type AdminCreateThemeMutationFn = Apollo.MutationFunction<
  AdminCreateThemeMutation,
  AdminCreateThemeMutationVariables
>;

/**
 * __useAdminCreateThemeMutation__
 *
 * To run a mutation, you first call `useAdminCreateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateThemeMutation, { data, loading, error }] = useAdminCreateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateThemeMutation,
    AdminCreateThemeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateThemeMutation, AdminCreateThemeMutationVariables>(
    AdminCreateThemeDocument,
    options,
  );
}
export type AdminCreateThemeMutationHookResult = ReturnType<typeof useAdminCreateThemeMutation>;
export type AdminCreateThemeMutationResult = Apollo.MutationResult<AdminCreateThemeMutation>;
export type AdminCreateThemeMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateThemeMutation,
  AdminCreateThemeMutationVariables
>;
export const AdminEditThemeDocument = gql`
    mutation adminEditTheme($id: String!, $input: EditThemeInput!) {
  adminEditTheme(id: $id, input: $input) {
    id
    title
    description
    isCurrent
    isStandard
    isGrammarLibrary
    isTopicLibrary
    cover {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
  }
}
    `;
export type AdminEditThemeMutationFn = Apollo.MutationFunction<
  AdminEditThemeMutation,
  AdminEditThemeMutationVariables
>;

/**
 * __useAdminEditThemeMutation__
 *
 * To run a mutation, you first call `useAdminEditThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditThemeMutation, { data, loading, error }] = useAdminEditThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditThemeMutation, AdminEditThemeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditThemeMutation, AdminEditThemeMutationVariables>(
    AdminEditThemeDocument,
    options,
  );
}
export type AdminEditThemeMutationHookResult = ReturnType<typeof useAdminEditThemeMutation>;
export type AdminEditThemeMutationResult = Apollo.MutationResult<AdminEditThemeMutation>;
export type AdminEditThemeMutationOptions = Apollo.BaseMutationOptions<
  AdminEditThemeMutation,
  AdminEditThemeMutationVariables
>;
export const AdminDeleteThemeDocument = gql`
    mutation adminDeleteTheme($id: String!) {
  adminDeleteTheme(id: $id) {
    status
  }
}
    `;
export type AdminDeleteThemeMutationFn = Apollo.MutationFunction<
  AdminDeleteThemeMutation,
  AdminDeleteThemeMutationVariables
>;

/**
 * __useAdminDeleteThemeMutation__
 *
 * To run a mutation, you first call `useAdminDeleteThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteThemeMutation, { data, loading, error }] = useAdminDeleteThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteThemeMutation,
    AdminDeleteThemeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteThemeMutation, AdminDeleteThemeMutationVariables>(
    AdminDeleteThemeDocument,
    options,
  );
}
export type AdminDeleteThemeMutationHookResult = ReturnType<typeof useAdminDeleteThemeMutation>;
export type AdminDeleteThemeMutationResult = Apollo.MutationResult<AdminDeleteThemeMutation>;
export type AdminDeleteThemeMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteThemeMutation,
  AdminDeleteThemeMutationVariables
>;
export const AdminEditTopicDocument = gql`
    mutation adminEditTopic($id: String!, $input: EditTopicInput!) {
  adminEditTopic(id: $id, input: $input) {
    id
    title
    stars
    theme {
      id
      title
    }
    illustration {
      id
      title
      url
    }
    lessons {
      id
      kind
    }
  }
}
    `;
export type AdminEditTopicMutationFn = Apollo.MutationFunction<
  AdminEditTopicMutation,
  AdminEditTopicMutationVariables
>;

/**
 * __useAdminEditTopicMutation__
 *
 * To run a mutation, you first call `useAdminEditTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditTopicMutation, { data, loading, error }] = useAdminEditTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditTopicMutation, AdminEditTopicMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditTopicMutation, AdminEditTopicMutationVariables>(
    AdminEditTopicDocument,
    options,
  );
}
export type AdminEditTopicMutationHookResult = ReturnType<typeof useAdminEditTopicMutation>;
export type AdminEditTopicMutationResult = Apollo.MutationResult<AdminEditTopicMutation>;
export type AdminEditTopicMutationOptions = Apollo.BaseMutationOptions<
  AdminEditTopicMutation,
  AdminEditTopicMutationVariables
>;
export const AdminDeleteTopicDocument = gql`
    mutation adminDeleteTopic($id: String!) {
  adminDeleteTopic(id: $id) {
    status
    reason
  }
}
    `;
export type AdminDeleteTopicMutationFn = Apollo.MutationFunction<
  AdminDeleteTopicMutation,
  AdminDeleteTopicMutationVariables
>;

/**
 * __useAdminDeleteTopicMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTopicMutation, { data, loading, error }] = useAdminDeleteTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteTopicMutation,
    AdminDeleteTopicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteTopicMutation, AdminDeleteTopicMutationVariables>(
    AdminDeleteTopicDocument,
    options,
  );
}
export type AdminDeleteTopicMutationHookResult = ReturnType<typeof useAdminDeleteTopicMutation>;
export type AdminDeleteTopicMutationResult = Apollo.MutationResult<AdminDeleteTopicMutation>;
export type AdminDeleteTopicMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteTopicMutation,
  AdminDeleteTopicMutationVariables
>;
export const AdminDeleteTopicsDocument = gql`
    mutation adminDeleteTopics($input: DeleteTopicsInput!) {
  adminDeleteTopics(input: $input) {
    status
  }
}
    `;
export type AdminDeleteTopicsMutationFn = Apollo.MutationFunction<
  AdminDeleteTopicsMutation,
  AdminDeleteTopicsMutationVariables
>;

/**
 * __useAdminDeleteTopicsMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTopicsMutation, { data, loading, error }] = useAdminDeleteTopicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeleteTopicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteTopicsMutation,
    AdminDeleteTopicsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteTopicsMutation, AdminDeleteTopicsMutationVariables>(
    AdminDeleteTopicsDocument,
    options,
  );
}
export type AdminDeleteTopicsMutationHookResult = ReturnType<typeof useAdminDeleteTopicsMutation>;
export type AdminDeleteTopicsMutationResult = Apollo.MutationResult<AdminDeleteTopicsMutation>;
export type AdminDeleteTopicsMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteTopicsMutation,
  AdminDeleteTopicsMutationVariables
>;
export const AdminCreateTopicDocument = gql`
    mutation adminCreateTopic($input: CreateTopicInput!) {
  adminCreateTopic(input: $input) {
    id
    title
    stars
    illustration {
      id
      title
      url
    }
    theme {
      id
      title
    }
    lessons {
      id
      kind
    }
  }
}
    `;
export type AdminCreateTopicMutationFn = Apollo.MutationFunction<
  AdminCreateTopicMutation,
  AdminCreateTopicMutationVariables
>;

/**
 * __useAdminCreateTopicMutation__
 *
 * To run a mutation, you first call `useAdminCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTopicMutation, { data, loading, error }] = useAdminCreateTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateTopicMutation,
    AdminCreateTopicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateTopicMutation, AdminCreateTopicMutationVariables>(
    AdminCreateTopicDocument,
    options,
  );
}
export type AdminCreateTopicMutationHookResult = ReturnType<typeof useAdminCreateTopicMutation>;
export type AdminCreateTopicMutationResult = Apollo.MutationResult<AdminCreateTopicMutation>;
export type AdminCreateTopicMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateTopicMutation,
  AdminCreateTopicMutationVariables
>;
export const DuplicateTopicDocument = gql`
    mutation duplicateTopic($id: String!) {
  duplicateTopic(id: $id) {
    status
  }
}
    `;
export type DuplicateTopicMutationFn = Apollo.MutationFunction<
  DuplicateTopicMutation,
  DuplicateTopicMutationVariables
>;

/**
 * __useDuplicateTopicMutation__
 *
 * To run a mutation, you first call `useDuplicateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTopicMutation, { data, loading, error }] = useDuplicateTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<DuplicateTopicMutation, DuplicateTopicMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateTopicMutation, DuplicateTopicMutationVariables>(
    DuplicateTopicDocument,
    options,
  );
}
export type DuplicateTopicMutationHookResult = ReturnType<typeof useDuplicateTopicMutation>;
export type DuplicateTopicMutationResult = Apollo.MutationResult<DuplicateTopicMutation>;
export type DuplicateTopicMutationOptions = Apollo.BaseMutationOptions<
  DuplicateTopicMutation,
  DuplicateTopicMutationVariables
>;
export const DuplicateLessonDocument = gql`
    mutation duplicateLesson($input: DuplicateLessonInput!) {
  duplicateLesson(input: $input) {
    status
  }
}
    `;
export type DuplicateLessonMutationFn = Apollo.MutationFunction<
  DuplicateLessonMutation,
  DuplicateLessonMutationVariables
>;

/**
 * __useDuplicateLessonMutation__
 *
 * To run a mutation, you first call `useDuplicateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateLessonMutation, { data, loading, error }] = useDuplicateLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateLessonMutation,
    DuplicateLessonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateLessonMutation, DuplicateLessonMutationVariables>(
    DuplicateLessonDocument,
    options,
  );
}
export type DuplicateLessonMutationHookResult = ReturnType<typeof useDuplicateLessonMutation>;
export type DuplicateLessonMutationResult = Apollo.MutationResult<DuplicateLessonMutation>;
export type DuplicateLessonMutationOptions = Apollo.BaseMutationOptions<
  DuplicateLessonMutation,
  DuplicateLessonMutationVariables
>;
export const CreateTopicForPreviewModeDocument = gql`
    mutation createTopicForPreviewMode($input: TopicInPreviewInput!) {
  createTopicForPreviewMode(input: $input) {
    id
  }
}
    `;
export type CreateTopicForPreviewModeMutationFn = Apollo.MutationFunction<
  CreateTopicForPreviewModeMutation,
  CreateTopicForPreviewModeMutationVariables
>;

/**
 * __useCreateTopicForPreviewModeMutation__
 *
 * To run a mutation, you first call `useCreateTopicForPreviewModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicForPreviewModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicForPreviewModeMutation, { data, loading, error }] = useCreateTopicForPreviewModeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTopicForPreviewModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTopicForPreviewModeMutation,
    CreateTopicForPreviewModeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTopicForPreviewModeMutation,
    CreateTopicForPreviewModeMutationVariables
  >(CreateTopicForPreviewModeDocument, options);
}
export type CreateTopicForPreviewModeMutationHookResult = ReturnType<
  typeof useCreateTopicForPreviewModeMutation
>;
export type CreateTopicForPreviewModeMutationResult =
  Apollo.MutationResult<CreateTopicForPreviewModeMutation>;
export type CreateTopicForPreviewModeMutationOptions = Apollo.BaseMutationOptions<
  CreateTopicForPreviewModeMutation,
  CreateTopicForPreviewModeMutationVariables
>;
export const CreateTotpDocument = gql`
    mutation createTotp {
  createTotp {
    id
    uri
  }
}
    `;
export type CreateTotpMutationFn = Apollo.MutationFunction<
  CreateTotpMutation,
  CreateTotpMutationVariables
>;

/**
 * __useCreateTotpMutation__
 *
 * To run a mutation, you first call `useCreateTotpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTotpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTotpMutation, { data, loading, error }] = useCreateTotpMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateTotpMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTotpMutation, CreateTotpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTotpMutation, CreateTotpMutationVariables>(
    CreateTotpDocument,
    options,
  );
}
export type CreateTotpMutationHookResult = ReturnType<typeof useCreateTotpMutation>;
export type CreateTotpMutationResult = Apollo.MutationResult<CreateTotpMutation>;
export type CreateTotpMutationOptions = Apollo.BaseMutationOptions<
  CreateTotpMutation,
  CreateTotpMutationVariables
>;
export const ConfirmTotpDocument = gql`
    mutation confirmTotp($input: ConfirmTotpInput!) {
  confirmTotp(input: $input) {
    status
  }
}
    `;
export type ConfirmTotpMutationFn = Apollo.MutationFunction<
  ConfirmTotpMutation,
  ConfirmTotpMutationVariables
>;

/**
 * __useConfirmTotpMutation__
 *
 * To run a mutation, you first call `useConfirmTotpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmTotpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmTotpMutation, { data, loading, error }] = useConfirmTotpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmTotpMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmTotpMutation, ConfirmTotpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmTotpMutation, ConfirmTotpMutationVariables>(
    ConfirmTotpDocument,
    options,
  );
}
export type ConfirmTotpMutationHookResult = ReturnType<typeof useConfirmTotpMutation>;
export type ConfirmTotpMutationResult = Apollo.MutationResult<ConfirmTotpMutation>;
export type ConfirmTotpMutationOptions = Apollo.BaseMutationOptions<
  ConfirmTotpMutation,
  ConfirmTotpMutationVariables
>;
export const RemoveTotpDocument = gql`
    mutation removeTotp($totpId: String!) {
  removeTotp(totpId: $totpId) {
    status
  }
}
    `;
export type RemoveTotpMutationFn = Apollo.MutationFunction<
  RemoveTotpMutation,
  RemoveTotpMutationVariables
>;

/**
 * __useRemoveTotpMutation__
 *
 * To run a mutation, you first call `useRemoveTotpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTotpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTotpMutation, { data, loading, error }] = useRemoveTotpMutation({
 *   variables: {
 *      totpId: // value for 'totpId'
 *   },
 * });
 */
export function useRemoveTotpMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTotpMutation, RemoveTotpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTotpMutation, RemoveTotpMutationVariables>(
    RemoveTotpDocument,
    options,
  );
}
export type RemoveTotpMutationHookResult = ReturnType<typeof useRemoveTotpMutation>;
export type RemoveTotpMutationResult = Apollo.MutationResult<RemoveTotpMutation>;
export type RemoveTotpMutationOptions = Apollo.BaseMutationOptions<
  RemoveTotpMutation,
  RemoveTotpMutationVariables
>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($id: String!) {
  deleteAccount(id: $id) {
    status
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(
    DeleteAccountDocument,
    options,
  );
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const AdminEditUserDocument = gql`
    mutation adminEditUser($id: String!, $input: EditUserInput!) {
  adminEditUser(id: $id, input: $input) {
    status
  }
}
    `;
export type AdminEditUserMutationFn = Apollo.MutationFunction<
  AdminEditUserMutation,
  AdminEditUserMutationVariables
>;

/**
 * __useAdminEditUserMutation__
 *
 * To run a mutation, you first call `useAdminEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditUserMutation, { data, loading, error }] = useAdminEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditUserMutation, AdminEditUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditUserMutation, AdminEditUserMutationVariables>(
    AdminEditUserDocument,
    options,
  );
}
export type AdminEditUserMutationHookResult = ReturnType<typeof useAdminEditUserMutation>;
export type AdminEditUserMutationResult = Apollo.MutationResult<AdminEditUserMutation>;
export type AdminEditUserMutationOptions = Apollo.BaseMutationOptions<
  AdminEditUserMutation,
  AdminEditUserMutationVariables
>;
export const AdminCreateNoteDocument = gql`
    mutation adminCreateNote($id: String!, $input: NoteInput!) {
  adminCreateNote(userID: $id, input: $input) {
    id
    description
  }
}
    `;
export type AdminCreateNoteMutationFn = Apollo.MutationFunction<
  AdminCreateNoteMutation,
  AdminCreateNoteMutationVariables
>;

/**
 * __useAdminCreateNoteMutation__
 *
 * To run a mutation, you first call `useAdminCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateNoteMutation, { data, loading, error }] = useAdminCreateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateNoteMutation,
    AdminCreateNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateNoteMutation, AdminCreateNoteMutationVariables>(
    AdminCreateNoteDocument,
    options,
  );
}
export type AdminCreateNoteMutationHookResult = ReturnType<typeof useAdminCreateNoteMutation>;
export type AdminCreateNoteMutationResult = Apollo.MutationResult<AdminCreateNoteMutation>;
export type AdminCreateNoteMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateNoteMutation,
  AdminCreateNoteMutationVariables
>;
export const AdminBanUserDocument = gql`
    mutation adminBanUser($id: String!) {
  adminBanUser(id: $id) {
    status
  }
}
    `;
export type AdminBanUserMutationFn = Apollo.MutationFunction<
  AdminBanUserMutation,
  AdminBanUserMutationVariables
>;

/**
 * __useAdminBanUserMutation__
 *
 * To run a mutation, you first call `useAdminBanUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBanUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBanUserMutation, { data, loading, error }] = useAdminBanUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminBanUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminBanUserMutation, AdminBanUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminBanUserMutation, AdminBanUserMutationVariables>(
    AdminBanUserDocument,
    options,
  );
}
export type AdminBanUserMutationHookResult = ReturnType<typeof useAdminBanUserMutation>;
export type AdminBanUserMutationResult = Apollo.MutationResult<AdminBanUserMutation>;
export type AdminBanUserMutationOptions = Apollo.BaseMutationOptions<
  AdminBanUserMutation,
  AdminBanUserMutationVariables
>;
export const AdminUnbanUserDocument = gql`
    mutation adminUnbanUser($id: String!) {
  adminUnbanUser(id: $id) {
    status
  }
}
    `;
export type AdminUnbanUserMutationFn = Apollo.MutationFunction<
  AdminUnbanUserMutation,
  AdminUnbanUserMutationVariables
>;

/**
 * __useAdminUnbanUserMutation__
 *
 * To run a mutation, you first call `useAdminUnbanUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUnbanUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUnbanUserMutation, { data, loading, error }] = useAdminUnbanUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUnbanUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUnbanUserMutation, AdminUnbanUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUnbanUserMutation, AdminUnbanUserMutationVariables>(
    AdminUnbanUserDocument,
    options,
  );
}
export type AdminUnbanUserMutationHookResult = ReturnType<typeof useAdminUnbanUserMutation>;
export type AdminUnbanUserMutationResult = Apollo.MutationResult<AdminUnbanUserMutation>;
export type AdminUnbanUserMutationOptions = Apollo.BaseMutationOptions<
  AdminUnbanUserMutation,
  AdminUnbanUserMutationVariables
>;
export const AssignUserBadgeDocument = gql`
    mutation assignUserBadge($input: AssignOrDeleteBadgeInput!) {
  assignUserBadge(input: $input) {
    status
  }
}
    `;
export type AssignUserBadgeMutationFn = Apollo.MutationFunction<
  AssignUserBadgeMutation,
  AssignUserBadgeMutationVariables
>;

/**
 * __useAssignUserBadgeMutation__
 *
 * To run a mutation, you first call `useAssignUserBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserBadgeMutation, { data, loading, error }] = useAssignUserBadgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignUserBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignUserBadgeMutation,
    AssignUserBadgeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignUserBadgeMutation, AssignUserBadgeMutationVariables>(
    AssignUserBadgeDocument,
    options,
  );
}
export type AssignUserBadgeMutationHookResult = ReturnType<typeof useAssignUserBadgeMutation>;
export type AssignUserBadgeMutationResult = Apollo.MutationResult<AssignUserBadgeMutation>;
export type AssignUserBadgeMutationOptions = Apollo.BaseMutationOptions<
  AssignUserBadgeMutation,
  AssignUserBadgeMutationVariables
>;
export const DeleteUserBadgeDocument = gql`
    mutation deleteUserBadge($input: AssignOrDeleteBadgeInput!) {
  deleteUserBadge(input: $input) {
    status
  }
}
    `;
export type DeleteUserBadgeMutationFn = Apollo.MutationFunction<
  DeleteUserBadgeMutation,
  DeleteUserBadgeMutationVariables
>;

/**
 * __useDeleteUserBadgeMutation__
 *
 * To run a mutation, you first call `useDeleteUserBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBadgeMutation, { data, loading, error }] = useDeleteUserBadgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserBadgeMutation,
    DeleteUserBadgeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserBadgeMutation, DeleteUserBadgeMutationVariables>(
    DeleteUserBadgeDocument,
    options,
  );
}
export type DeleteUserBadgeMutationHookResult = ReturnType<typeof useDeleteUserBadgeMutation>;
export type DeleteUserBadgeMutationResult = Apollo.MutationResult<DeleteUserBadgeMutation>;
export type DeleteUserBadgeMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserBadgeMutation,
  DeleteUserBadgeMutationVariables
>;
export const AdminCreateVideoSetDocument = gql`
    mutation adminCreateVideoSet($input: CreateVideoSetInput!) {
  adminCreateVideoSet(input: $input) {
    id
    title
  }
}
    `;
export type AdminCreateVideoSetMutationFn = Apollo.MutationFunction<
  AdminCreateVideoSetMutation,
  AdminCreateVideoSetMutationVariables
>;

/**
 * __useAdminCreateVideoSetMutation__
 *
 * To run a mutation, you first call `useAdminCreateVideoSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateVideoSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateVideoSetMutation, { data, loading, error }] = useAdminCreateVideoSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateVideoSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateVideoSetMutation,
    AdminCreateVideoSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateVideoSetMutation, AdminCreateVideoSetMutationVariables>(
    AdminCreateVideoSetDocument,
    options,
  );
}
export type AdminCreateVideoSetMutationHookResult = ReturnType<
  typeof useAdminCreateVideoSetMutation
>;
export type AdminCreateVideoSetMutationResult = Apollo.MutationResult<AdminCreateVideoSetMutation>;
export type AdminCreateVideoSetMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateVideoSetMutation,
  AdminCreateVideoSetMutationVariables
>;
export const AdminDeleteVideoSetDocument = gql`
    mutation adminDeleteVideoSet($id: String!) {
  adminDeleteVideoSet(id: $id) {
    status
  }
}
    `;
export type AdminDeleteVideoSetMutationFn = Apollo.MutationFunction<
  AdminDeleteVideoSetMutation,
  AdminDeleteVideoSetMutationVariables
>;

/**
 * __useAdminDeleteVideoSetMutation__
 *
 * To run a mutation, you first call `useAdminDeleteVideoSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteVideoSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteVideoSetMutation, { data, loading, error }] = useAdminDeleteVideoSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteVideoSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteVideoSetMutation,
    AdminDeleteVideoSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteVideoSetMutation, AdminDeleteVideoSetMutationVariables>(
    AdminDeleteVideoSetDocument,
    options,
  );
}
export type AdminDeleteVideoSetMutationHookResult = ReturnType<
  typeof useAdminDeleteVideoSetMutation
>;
export type AdminDeleteVideoSetMutationResult = Apollo.MutationResult<AdminDeleteVideoSetMutation>;
export type AdminDeleteVideoSetMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteVideoSetMutation,
  AdminDeleteVideoSetMutationVariables
>;
export const AdminCreateVideoDocument = gql`
    mutation adminCreateVideo($input: CreateVideoInput!) {
  adminCreateVideo(input: $input) {
    status
  }
}
    `;
export type AdminCreateVideoMutationFn = Apollo.MutationFunction<
  AdminCreateVideoMutation,
  AdminCreateVideoMutationVariables
>;

/**
 * __useAdminCreateVideoMutation__
 *
 * To run a mutation, you first call `useAdminCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateVideoMutation, { data, loading, error }] = useAdminCreateVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateVideoMutation,
    AdminCreateVideoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateVideoMutation, AdminCreateVideoMutationVariables>(
    AdminCreateVideoDocument,
    options,
  );
}
export type AdminCreateVideoMutationHookResult = ReturnType<typeof useAdminCreateVideoMutation>;
export type AdminCreateVideoMutationResult = Apollo.MutationResult<AdminCreateVideoMutation>;
export type AdminCreateVideoMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateVideoMutation,
  AdminCreateVideoMutationVariables
>;
export const AdminDeleteVideoDocument = gql`
    mutation adminDeleteVideo($id: String!) {
  adminDeleteVideo(id: $id) {
    status
  }
}
    `;
export type AdminDeleteVideoMutationFn = Apollo.MutationFunction<
  AdminDeleteVideoMutation,
  AdminDeleteVideoMutationVariables
>;

/**
 * __useAdminDeleteVideoMutation__
 *
 * To run a mutation, you first call `useAdminDeleteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteVideoMutation, { data, loading, error }] = useAdminDeleteVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteVideoMutation,
    AdminDeleteVideoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteVideoMutation, AdminDeleteVideoMutationVariables>(
    AdminDeleteVideoDocument,
    options,
  );
}
export type AdminDeleteVideoMutationHookResult = ReturnType<typeof useAdminDeleteVideoMutation>;
export type AdminDeleteVideoMutationResult = Apollo.MutationResult<AdminDeleteVideoMutation>;
export type AdminDeleteVideoMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteVideoMutation,
  AdminDeleteVideoMutationVariables
>;
export const AdminEditVideoSetDocument = gql`
    mutation adminEditVideoSet($id: String!, $input: EditVideoSetInput!) {
  adminEditVideoSet(id: $id, input: $input) {
    id
  }
}
    `;
export type AdminEditVideoSetMutationFn = Apollo.MutationFunction<
  AdminEditVideoSetMutation,
  AdminEditVideoSetMutationVariables
>;

/**
 * __useAdminEditVideoSetMutation__
 *
 * To run a mutation, you first call `useAdminEditVideoSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditVideoSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditVideoSetMutation, { data, loading, error }] = useAdminEditVideoSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditVideoSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditVideoSetMutation,
    AdminEditVideoSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditVideoSetMutation, AdminEditVideoSetMutationVariables>(
    AdminEditVideoSetDocument,
    options,
  );
}
export type AdminEditVideoSetMutationHookResult = ReturnType<typeof useAdminEditVideoSetMutation>;
export type AdminEditVideoSetMutationResult = Apollo.MutationResult<AdminEditVideoSetMutation>;
export type AdminEditVideoSetMutationOptions = Apollo.BaseMutationOptions<
  AdminEditVideoSetMutation,
  AdminEditVideoSetMutationVariables
>;
export const AdminOrderVideoSetDocument = gql`
    mutation adminOrderVideoSet($id: String!, $input: String!) {
  adminOrderVideoSet(id: $id, input: $input) {
    status
  }
}
    `;
export type AdminOrderVideoSetMutationFn = Apollo.MutationFunction<
  AdminOrderVideoSetMutation,
  AdminOrderVideoSetMutationVariables
>;

/**
 * __useAdminOrderVideoSetMutation__
 *
 * To run a mutation, you first call `useAdminOrderVideoSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderVideoSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminOrderVideoSetMutation, { data, loading, error }] = useAdminOrderVideoSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminOrderVideoSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminOrderVideoSetMutation,
    AdminOrderVideoSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminOrderVideoSetMutation, AdminOrderVideoSetMutationVariables>(
    AdminOrderVideoSetDocument,
    options,
  );
}
export type AdminOrderVideoSetMutationHookResult = ReturnType<typeof useAdminOrderVideoSetMutation>;
export type AdminOrderVideoSetMutationResult = Apollo.MutationResult<AdminOrderVideoSetMutation>;
export type AdminOrderVideoSetMutationOptions = Apollo.BaseMutationOptions<
  AdminOrderVideoSetMutation,
  AdminOrderVideoSetMutationVariables
>;
export const AdminEditVideoDocument = gql`
    mutation adminEditVideo($id: String!, $input: EditVideoInput!) {
  adminEditVideo(id: $id, input: $input) {
    id
  }
}
    `;
export type AdminEditVideoMutationFn = Apollo.MutationFunction<
  AdminEditVideoMutation,
  AdminEditVideoMutationVariables
>;

/**
 * __useAdminEditVideoMutation__
 *
 * To run a mutation, you first call `useAdminEditVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditVideoMutation, { data, loading, error }] = useAdminEditVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditVideoMutation, AdminEditVideoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditVideoMutation, AdminEditVideoMutationVariables>(
    AdminEditVideoDocument,
    options,
  );
}
export type AdminEditVideoMutationHookResult = ReturnType<typeof useAdminEditVideoMutation>;
export type AdminEditVideoMutationResult = Apollo.MutationResult<AdminEditVideoMutation>;
export type AdminEditVideoMutationOptions = Apollo.BaseMutationOptions<
  AdminEditVideoMutation,
  AdminEditVideoMutationVariables
>;
export const AdminOrderVideoDocument = gql`
    mutation adminOrderVideo($id: String!, $input: String!) {
  adminOrderVideo(id: $id, input: $input) {
    status
  }
}
    `;
export type AdminOrderVideoMutationFn = Apollo.MutationFunction<
  AdminOrderVideoMutation,
  AdminOrderVideoMutationVariables
>;

/**
 * __useAdminOrderVideoMutation__
 *
 * To run a mutation, you first call `useAdminOrderVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminOrderVideoMutation, { data, loading, error }] = useAdminOrderVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminOrderVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminOrderVideoMutation,
    AdminOrderVideoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminOrderVideoMutation, AdminOrderVideoMutationVariables>(
    AdminOrderVideoDocument,
    options,
  );
}
export type AdminOrderVideoMutationHookResult = ReturnType<typeof useAdminOrderVideoMutation>;
export type AdminOrderVideoMutationResult = Apollo.MutationResult<AdminOrderVideoMutation>;
export type AdminOrderVideoMutationOptions = Apollo.BaseMutationOptions<
  AdminOrderVideoMutation,
  AdminOrderVideoMutationVariables
>;
export const AdminDeleteZineCoverDocument = gql`
    mutation adminDeleteZineCover($id: String!) {
  adminDeleteZineCover(id: $id) {
    status
  }
}
    `;
export type AdminDeleteZineCoverMutationFn = Apollo.MutationFunction<
  AdminDeleteZineCoverMutation,
  AdminDeleteZineCoverMutationVariables
>;

/**
 * __useAdminDeleteZineCoverMutation__
 *
 * To run a mutation, you first call `useAdminDeleteZineCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteZineCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteZineCoverMutation, { data, loading, error }] = useAdminDeleteZineCoverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteZineCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteZineCoverMutation,
    AdminDeleteZineCoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteZineCoverMutation, AdminDeleteZineCoverMutationVariables>(
    AdminDeleteZineCoverDocument,
    options,
  );
}
export type AdminDeleteZineCoverMutationHookResult = ReturnType<
  typeof useAdminDeleteZineCoverMutation
>;
export type AdminDeleteZineCoverMutationResult =
  Apollo.MutationResult<AdminDeleteZineCoverMutation>;
export type AdminDeleteZineCoverMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteZineCoverMutation,
  AdminDeleteZineCoverMutationVariables
>;
export const AdminCreateZineCoverDocument = gql`
    mutation adminCreateZineCover($input: CreateZineInput!) {
  adminCreateZineCover(input: $input) {
    id
    coverUrl
    isCurrent
  }
}
    `;
export type AdminCreateZineCoverMutationFn = Apollo.MutationFunction<
  AdminCreateZineCoverMutation,
  AdminCreateZineCoverMutationVariables
>;

/**
 * __useAdminCreateZineCoverMutation__
 *
 * To run a mutation, you first call `useAdminCreateZineCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateZineCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateZineCoverMutation, { data, loading, error }] = useAdminCreateZineCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateZineCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateZineCoverMutation,
    AdminCreateZineCoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateZineCoverMutation, AdminCreateZineCoverMutationVariables>(
    AdminCreateZineCoverDocument,
    options,
  );
}
export type AdminCreateZineCoverMutationHookResult = ReturnType<
  typeof useAdminCreateZineCoverMutation
>;
export type AdminCreateZineCoverMutationResult =
  Apollo.MutationResult<AdminCreateZineCoverMutation>;
export type AdminCreateZineCoverMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateZineCoverMutation,
  AdminCreateZineCoverMutationVariables
>;
export const AdminEditZineCoverDocument = gql`
    mutation adminEditZineCover($id: String!, $input: EditZineInput!) {
  adminEditZineCover(id: $id, input: $input) {
    id
    coverUrl
    isCurrent
  }
}
    `;
export type AdminEditZineCoverMutationFn = Apollo.MutationFunction<
  AdminEditZineCoverMutation,
  AdminEditZineCoverMutationVariables
>;

/**
 * __useAdminEditZineCoverMutation__
 *
 * To run a mutation, you first call `useAdminEditZineCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditZineCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditZineCoverMutation, { data, loading, error }] = useAdminEditZineCoverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditZineCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditZineCoverMutation,
    AdminEditZineCoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditZineCoverMutation, AdminEditZineCoverMutationVariables>(
    AdminEditZineCoverDocument,
    options,
  );
}
export type AdminEditZineCoverMutationHookResult = ReturnType<typeof useAdminEditZineCoverMutation>;
export type AdminEditZineCoverMutationResult = Apollo.MutationResult<AdminEditZineCoverMutation>;
export type AdminEditZineCoverMutationOptions = Apollo.BaseMutationOptions<
  AdminEditZineCoverMutation,
  AdminEditZineCoverMutationVariables
>;
export const UploadZineCoverDocument = gql`
    mutation uploadZineCover($input: UploadCoverZineInput!) {
  uploadZineCover(input: $input) {
    id
    coverUrl
  }
}
    `;
export type UploadZineCoverMutationFn = Apollo.MutationFunction<
  UploadZineCoverMutation,
  UploadZineCoverMutationVariables
>;

/**
 * __useUploadZineCoverMutation__
 *
 * To run a mutation, you first call `useUploadZineCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadZineCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadZineCoverMutation, { data, loading, error }] = useUploadZineCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadZineCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadZineCoverMutation,
    UploadZineCoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadZineCoverMutation, UploadZineCoverMutationVariables>(
    UploadZineCoverDocument,
    options,
  );
}
export type UploadZineCoverMutationHookResult = ReturnType<typeof useUploadZineCoverMutation>;
export type UploadZineCoverMutationResult = Apollo.MutationResult<UploadZineCoverMutation>;
export type UploadZineCoverMutationOptions = Apollo.BaseMutationOptions<
  UploadZineCoverMutation,
  UploadZineCoverMutationVariables
>;
export const AdminGetArticleDocument = gql`
    query adminGetArticle($id: String!, $filters: AdminFilterUsersInput!) {
  adminGetArticle(articleID: $id) {
    id
    title
    content
    coverUrl
    isFeatured
    tags
    isVisible
    isDraft
    author {
      id
      name
    }
  }
  adminListUsers(filters: $filters) {
    items {
      id
      name
    }
    total
  }
}
    `;

/**
 * __useAdminGetArticleQuery__
 *
 * To run a query within a React component, call `useAdminGetArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminGetArticleQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetArticleQuery, AdminGetArticleQueryVariables> &
    ({ variables: AdminGetArticleQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetArticleQuery, AdminGetArticleQueryVariables>(
    AdminGetArticleDocument,
    options,
  );
}
export function useAdminGetArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetArticleQuery, AdminGetArticleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetArticleQuery, AdminGetArticleQueryVariables>(
    AdminGetArticleDocument,
    options,
  );
}
export function useAdminGetArticleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetArticleQuery, AdminGetArticleQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetArticleQuery, AdminGetArticleQueryVariables>(
    AdminGetArticleDocument,
    options,
  );
}
export type AdminGetArticleQueryHookResult = ReturnType<typeof useAdminGetArticleQuery>;
export type AdminGetArticleLazyQueryHookResult = ReturnType<typeof useAdminGetArticleLazyQuery>;
export type AdminGetArticleSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetArticleSuspenseQuery
>;
export type AdminGetArticleQueryResult = Apollo.QueryResult<
  AdminGetArticleQuery,
  AdminGetArticleQueryVariables
>;
export const AdminListPaginatedArticlesDocument = gql`
    query adminListPaginatedArticles($order: OrderArticlesInput!, $filters: FilterArticlesInput!) {
  adminListPaginatedArticles(order: $order, filters: $filters) {
    items {
      id
      title
      content
      coverUrl
      isFeatured
      publishedAt
      tags
      isVisible
      isDraft
      author {
        id
        name
      }
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedArticlesQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedArticlesQuery({
 *   variables: {
 *      order: // value for 'order'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedArticlesQuery,
    AdminListPaginatedArticlesQueryVariables
  > &
    ({ variables: AdminListPaginatedArticlesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListPaginatedArticlesQuery, AdminListPaginatedArticlesQueryVariables>(
    AdminListPaginatedArticlesDocument,
    options,
  );
}
export function useAdminListPaginatedArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedArticlesQuery,
    AdminListPaginatedArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedArticlesQuery,
    AdminListPaginatedArticlesQueryVariables
  >(AdminListPaginatedArticlesDocument, options);
}
export function useAdminListPaginatedArticlesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedArticlesQuery,
        AdminListPaginatedArticlesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedArticlesQuery,
    AdminListPaginatedArticlesQueryVariables
  >(AdminListPaginatedArticlesDocument, options);
}
export type AdminListPaginatedArticlesQueryHookResult = ReturnType<
  typeof useAdminListPaginatedArticlesQuery
>;
export type AdminListPaginatedArticlesLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedArticlesLazyQuery
>;
export type AdminListPaginatedArticlesSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedArticlesSuspenseQuery
>;
export type AdminListPaginatedArticlesQueryResult = Apollo.QueryResult<
  AdminListPaginatedArticlesQuery,
  AdminListPaginatedArticlesQueryVariables
>;
export const AdminListPaginatedCoversDocument = gql`
    query adminListPaginatedCovers($filters: AdminFilterCoversInput!) {
  adminListPaginatedCovers(filters: $filters) {
    items {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedCoversQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedCoversQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedCoversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedCoversQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedCoversQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedCoversQuery,
    AdminListPaginatedCoversQueryVariables
  > &
    ({ variables: AdminListPaginatedCoversQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListPaginatedCoversQuery, AdminListPaginatedCoversQueryVariables>(
    AdminListPaginatedCoversDocument,
    options,
  );
}
export function useAdminListPaginatedCoversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedCoversQuery,
    AdminListPaginatedCoversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminListPaginatedCoversQuery, AdminListPaginatedCoversQueryVariables>(
    AdminListPaginatedCoversDocument,
    options,
  );
}
export function useAdminListPaginatedCoversSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedCoversQuery,
        AdminListPaginatedCoversQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedCoversQuery,
    AdminListPaginatedCoversQueryVariables
  >(AdminListPaginatedCoversDocument, options);
}
export type AdminListPaginatedCoversQueryHookResult = ReturnType<
  typeof useAdminListPaginatedCoversQuery
>;
export type AdminListPaginatedCoversLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedCoversLazyQuery
>;
export type AdminListPaginatedCoversSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedCoversSuspenseQuery
>;
export type AdminListPaginatedCoversQueryResult = Apollo.QueryResult<
  AdminListPaginatedCoversQuery,
  AdminListPaginatedCoversQueryVariables
>;
export const AdminGetCoverDocument = gql`
    query adminGetCover($id: String!) {
  adminGetCover(id: $id) {
    id
    title
    primary {
      mobile
      desktop
    }
    secondary {
      mobile
      desktop
    }
    tertiary {
      mobile
      desktop
    }
  }
}
    `;

/**
 * __useAdminGetCoverQuery__
 *
 * To run a query within a React component, call `useAdminGetCoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetCoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetCoverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetCoverQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetCoverQuery, AdminGetCoverQueryVariables> &
    ({ variables: AdminGetCoverQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetCoverQuery, AdminGetCoverQueryVariables>(
    AdminGetCoverDocument,
    options,
  );
}
export function useAdminGetCoverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetCoverQuery, AdminGetCoverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetCoverQuery, AdminGetCoverQueryVariables>(
    AdminGetCoverDocument,
    options,
  );
}
export function useAdminGetCoverSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetCoverQuery, AdminGetCoverQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetCoverQuery, AdminGetCoverQueryVariables>(
    AdminGetCoverDocument,
    options,
  );
}
export type AdminGetCoverQueryHookResult = ReturnType<typeof useAdminGetCoverQuery>;
export type AdminGetCoverLazyQueryHookResult = ReturnType<typeof useAdminGetCoverLazyQuery>;
export type AdminGetCoverSuspenseQueryHookResult = ReturnType<typeof useAdminGetCoverSuspenseQuery>;
export type AdminGetCoverQueryResult = Apollo.QueryResult<
  AdminGetCoverQuery,
  AdminGetCoverQueryVariables
>;
export const AdminSearchEventsDocument = gql`
    query adminSearchEvents($filters: AdminFilterEventsInput!) {
  adminSearchEvents(filters: $filters) {
    items {
      title
      id
      endDate
      coverUrl
      startDate
      organizer {
        id
        firstName
        lastName
      }
    }
    total
  }
}
    `;

/**
 * __useAdminSearchEventsQuery__
 *
 * To run a query within a React component, call `useAdminSearchEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSearchEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSearchEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminSearchEventsQuery(
  baseOptions: Apollo.QueryHookOptions<AdminSearchEventsQuery, AdminSearchEventsQueryVariables> &
    ({ variables: AdminSearchEventsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminSearchEventsQuery, AdminSearchEventsQueryVariables>(
    AdminSearchEventsDocument,
    options,
  );
}
export function useAdminSearchEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminSearchEventsQuery,
    AdminSearchEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminSearchEventsQuery, AdminSearchEventsQueryVariables>(
    AdminSearchEventsDocument,
    options,
  );
}
export function useAdminSearchEventsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminSearchEventsQuery, AdminSearchEventsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminSearchEventsQuery, AdminSearchEventsQueryVariables>(
    AdminSearchEventsDocument,
    options,
  );
}
export type AdminSearchEventsQueryHookResult = ReturnType<typeof useAdminSearchEventsQuery>;
export type AdminSearchEventsLazyQueryHookResult = ReturnType<typeof useAdminSearchEventsLazyQuery>;
export type AdminSearchEventsSuspenseQueryHookResult = ReturnType<
  typeof useAdminSearchEventsSuspenseQuery
>;
export type AdminSearchEventsQueryResult = Apollo.QueryResult<
  AdminSearchEventsQuery,
  AdminSearchEventsQueryVariables
>;
export const GetEventAdminDocument = gql`
    query getEventAdmin($id: String!) {
  getEventAdmin(id: $id) {
    id
    coverUrl
    createdAt
    description
    endDate
    isDraft
    isOnline
    isPublic
    kind
    meta
    location
    noParticipants
    recurring
    startDate
    title
    updatedAt
    videoUrl
    isForUnverified
    permissions
    englishLevel
    organizer {
      id
      name
      avatarUrl
    }
  }
}
    `;

/**
 * __useGetEventAdminQuery__
 *
 * To run a query within a React component, call `useGetEventAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventAdminQuery, GetEventAdminQueryVariables> &
    ({ variables: GetEventAdminQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventAdminQuery, GetEventAdminQueryVariables>(
    GetEventAdminDocument,
    options,
  );
}
export function useGetEventAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventAdminQuery, GetEventAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventAdminQuery, GetEventAdminQueryVariables>(
    GetEventAdminDocument,
    options,
  );
}
export function useGetEventAdminSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetEventAdminQuery, GetEventAdminQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEventAdminQuery, GetEventAdminQueryVariables>(
    GetEventAdminDocument,
    options,
  );
}
export type GetEventAdminQueryHookResult = ReturnType<typeof useGetEventAdminQuery>;
export type GetEventAdminLazyQueryHookResult = ReturnType<typeof useGetEventAdminLazyQuery>;
export type GetEventAdminSuspenseQueryHookResult = ReturnType<typeof useGetEventAdminSuspenseQuery>;
export type GetEventAdminQueryResult = Apollo.QueryResult<
  GetEventAdminQuery,
  GetEventAdminQueryVariables
>;
export const GetEventAndTopicsDocument = gql`
    query getEventAndTopics($id: String!) {
  getEventAdmin(id: $id) {
    id
    coverUrl
    createdAt
    description
    endDate
    eventCategory
    host {
      kind
    }
    isDraft
    isDeleted
    isOnline
    isPublic
    kind
    meta
    location
    noParticipants
    recurring
    englishLevel
    isForUnverified
    permissions
    englishLevel
    startDate
    title
    updatedAt
    videoUrl
    organizer {
      id
      name
      avatarUrl
    }
    topic {
      id
      title
    }
  }
  adminListTopics {
    id
    title
  }
}
    `;

/**
 * __useGetEventAndTopicsQuery__
 *
 * To run a query within a React component, call `useGetEventAndTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventAndTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventAndTopicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventAndTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventAndTopicsQuery, GetEventAndTopicsQueryVariables> &
    ({ variables: GetEventAndTopicsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventAndTopicsQuery, GetEventAndTopicsQueryVariables>(
    GetEventAndTopicsDocument,
    options,
  );
}
export function useGetEventAndTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventAndTopicsQuery,
    GetEventAndTopicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventAndTopicsQuery, GetEventAndTopicsQueryVariables>(
    GetEventAndTopicsDocument,
    options,
  );
}
export function useGetEventAndTopicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetEventAndTopicsQuery, GetEventAndTopicsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEventAndTopicsQuery, GetEventAndTopicsQueryVariables>(
    GetEventAndTopicsDocument,
    options,
  );
}
export type GetEventAndTopicsQueryHookResult = ReturnType<typeof useGetEventAndTopicsQuery>;
export type GetEventAndTopicsLazyQueryHookResult = ReturnType<typeof useGetEventAndTopicsLazyQuery>;
export type GetEventAndTopicsSuspenseQueryHookResult = ReturnType<
  typeof useGetEventAndTopicsSuspenseQuery
>;
export type GetEventAndTopicsQueryResult = Apollo.QueryResult<
  GetEventAndTopicsQuery,
  GetEventAndTopicsQueryVariables
>;
export const GetEventParticipantsDocument = gql`
    query getEventParticipants($id: String!) {
  getEventAdmin(id: $id) {
    id
    participants {
      id
      name
      createdAt
      email
      city
      isVerified
      country
      avatarUrl
      badge
    }
    invited {
      id
      name
      createdAt
      email
      city
      isVerified
      country
      avatarUrl
      badge
    }
    declined {
      id
      name
      createdAt
      email
      city
      isVerified
      country
      avatarUrl
      badge
    }
  }
}
    `;

/**
 * __useGetEventParticipantsQuery__
 *
 * To run a query within a React component, call `useGetEventParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventParticipantsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventParticipantsQuery,
    GetEventParticipantsQueryVariables
  > &
    ({ variables: GetEventParticipantsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventParticipantsQuery, GetEventParticipantsQueryVariables>(
    GetEventParticipantsDocument,
    options,
  );
}
export function useGetEventParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventParticipantsQuery,
    GetEventParticipantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventParticipantsQuery, GetEventParticipantsQueryVariables>(
    GetEventParticipantsDocument,
    options,
  );
}
export function useGetEventParticipantsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetEventParticipantsQuery,
        GetEventParticipantsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEventParticipantsQuery, GetEventParticipantsQueryVariables>(
    GetEventParticipantsDocument,
    options,
  );
}
export type GetEventParticipantsQueryHookResult = ReturnType<typeof useGetEventParticipantsQuery>;
export type GetEventParticipantsLazyQueryHookResult = ReturnType<
  typeof useGetEventParticipantsLazyQuery
>;
export type GetEventParticipantsSuspenseQueryHookResult = ReturnType<
  typeof useGetEventParticipantsSuspenseQuery
>;
export type GetEventParticipantsQueryResult = Apollo.QueryResult<
  GetEventParticipantsQuery,
  GetEventParticipantsQueryVariables
>;
export const GetEventCommentsDocument = gql`
    query getEventComments($id: String!) {
  getEventAdmin(id: $id) {
    id
    comments {
      id
      message
      author {
        id
        avatarUrl
        name
      }
    }
  }
}
    `;

/**
 * __useGetEventCommentsQuery__
 *
 * To run a query within a React component, call `useGetEventCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventCommentsQuery, GetEventCommentsQueryVariables> &
    ({ variables: GetEventCommentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventCommentsQuery, GetEventCommentsQueryVariables>(
    GetEventCommentsDocument,
    options,
  );
}
export function useGetEventCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventCommentsQuery, GetEventCommentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventCommentsQuery, GetEventCommentsQueryVariables>(
    GetEventCommentsDocument,
    options,
  );
}
export function useGetEventCommentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetEventCommentsQuery, GetEventCommentsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEventCommentsQuery, GetEventCommentsQueryVariables>(
    GetEventCommentsDocument,
    options,
  );
}
export type GetEventCommentsQueryHookResult = ReturnType<typeof useGetEventCommentsQuery>;
export type GetEventCommentsLazyQueryHookResult = ReturnType<typeof useGetEventCommentsLazyQuery>;
export type GetEventCommentsSuspenseQueryHookResult = ReturnType<
  typeof useGetEventCommentsSuspenseQuery
>;
export type GetEventCommentsQueryResult = Apollo.QueryResult<
  GetEventCommentsQuery,
  GetEventCommentsQueryVariables
>;
export const AdminGetFeatureFlagDocument = gql`
    query adminGetFeatureFlag($id: String!) {
  adminGetFeatureFlag(id: $id) {
    id
    label
    active
  }
}
    `;

/**
 * __useAdminGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useAdminGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetFeatureFlagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetFeatureFlagQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetFeatureFlagQuery,
    AdminGetFeatureFlagQueryVariables
  > &
    ({ variables: AdminGetFeatureFlagQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetFeatureFlagQuery, AdminGetFeatureFlagQueryVariables>(
    AdminGetFeatureFlagDocument,
    options,
  );
}
export function useAdminGetFeatureFlagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetFeatureFlagQuery,
    AdminGetFeatureFlagQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetFeatureFlagQuery, AdminGetFeatureFlagQueryVariables>(
    AdminGetFeatureFlagDocument,
    options,
  );
}
export function useAdminGetFeatureFlagSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetFeatureFlagQuery, AdminGetFeatureFlagQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetFeatureFlagQuery, AdminGetFeatureFlagQueryVariables>(
    AdminGetFeatureFlagDocument,
    options,
  );
}
export type AdminGetFeatureFlagQueryHookResult = ReturnType<typeof useAdminGetFeatureFlagQuery>;
export type AdminGetFeatureFlagLazyQueryHookResult = ReturnType<
  typeof useAdminGetFeatureFlagLazyQuery
>;
export type AdminGetFeatureFlagSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetFeatureFlagSuspenseQuery
>;
export type AdminGetFeatureFlagQueryResult = Apollo.QueryResult<
  AdminGetFeatureFlagQuery,
  AdminGetFeatureFlagQueryVariables
>;
export const AdminListPaginatedFeatureFlagsDocument = gql`
    query adminListPaginatedFeatureFlags($filters: AdminFilterFeatureFlagsInput!) {
  adminListPaginatedFeatureFlags(filters: $filters) {
    items {
      id
      label
      active
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedFeatureFlagsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedFeatureFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedFeatureFlagsQuery,
    AdminListPaginatedFeatureFlagsQueryVariables
  > &
    (
      | { variables: AdminListPaginatedFeatureFlagsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListPaginatedFeatureFlagsQuery,
    AdminListPaginatedFeatureFlagsQueryVariables
  >(AdminListPaginatedFeatureFlagsDocument, options);
}
export function useAdminListPaginatedFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedFeatureFlagsQuery,
    AdminListPaginatedFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedFeatureFlagsQuery,
    AdminListPaginatedFeatureFlagsQueryVariables
  >(AdminListPaginatedFeatureFlagsDocument, options);
}
export function useAdminListPaginatedFeatureFlagsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedFeatureFlagsQuery,
        AdminListPaginatedFeatureFlagsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedFeatureFlagsQuery,
    AdminListPaginatedFeatureFlagsQueryVariables
  >(AdminListPaginatedFeatureFlagsDocument, options);
}
export type AdminListPaginatedFeatureFlagsQueryHookResult = ReturnType<
  typeof useAdminListPaginatedFeatureFlagsQuery
>;
export type AdminListPaginatedFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedFeatureFlagsLazyQuery
>;
export type AdminListPaginatedFeatureFlagsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedFeatureFlagsSuspenseQuery
>;
export type AdminListPaginatedFeatureFlagsQueryResult = Apollo.QueryResult<
  AdminListPaginatedFeatureFlagsQuery,
  AdminListPaginatedFeatureFlagsQueryVariables
>;
export const AdminGetIllustrationDocument = gql`
    query adminGetIllustration($id: String!) {
  adminGetIllustration(id: $id) {
    id
    title
    url
  }
}
    `;

/**
 * __useAdminGetIllustrationQuery__
 *
 * To run a query within a React component, call `useAdminGetIllustrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetIllustrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetIllustrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetIllustrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetIllustrationQuery,
    AdminGetIllustrationQueryVariables
  > &
    ({ variables: AdminGetIllustrationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetIllustrationQuery, AdminGetIllustrationQueryVariables>(
    AdminGetIllustrationDocument,
    options,
  );
}
export function useAdminGetIllustrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetIllustrationQuery,
    AdminGetIllustrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetIllustrationQuery, AdminGetIllustrationQueryVariables>(
    AdminGetIllustrationDocument,
    options,
  );
}
export function useAdminGetIllustrationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetIllustrationQuery,
        AdminGetIllustrationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetIllustrationQuery, AdminGetIllustrationQueryVariables>(
    AdminGetIllustrationDocument,
    options,
  );
}
export type AdminGetIllustrationQueryHookResult = ReturnType<typeof useAdminGetIllustrationQuery>;
export type AdminGetIllustrationLazyQueryHookResult = ReturnType<
  typeof useAdminGetIllustrationLazyQuery
>;
export type AdminGetIllustrationSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetIllustrationSuspenseQuery
>;
export type AdminGetIllustrationQueryResult = Apollo.QueryResult<
  AdminGetIllustrationQuery,
  AdminGetIllustrationQueryVariables
>;
export const AdminListPaginatedIllustrationsDocument = gql`
    query adminListPaginatedIllustrations($filters: AdminFilterIllustrationsInput!) {
  adminListPaginatedIllustrations(filters: $filters) {
    items {
      id
      title
      url
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedIllustrationsQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedIllustrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedIllustrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedIllustrationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedIllustrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedIllustrationsQuery,
    AdminListPaginatedIllustrationsQueryVariables
  > &
    (
      | { variables: AdminListPaginatedIllustrationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListPaginatedIllustrationsQuery,
    AdminListPaginatedIllustrationsQueryVariables
  >(AdminListPaginatedIllustrationsDocument, options);
}
export function useAdminListPaginatedIllustrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedIllustrationsQuery,
    AdminListPaginatedIllustrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedIllustrationsQuery,
    AdminListPaginatedIllustrationsQueryVariables
  >(AdminListPaginatedIllustrationsDocument, options);
}
export function useAdminListPaginatedIllustrationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedIllustrationsQuery,
        AdminListPaginatedIllustrationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedIllustrationsQuery,
    AdminListPaginatedIllustrationsQueryVariables
  >(AdminListPaginatedIllustrationsDocument, options);
}
export type AdminListPaginatedIllustrationsQueryHookResult = ReturnType<
  typeof useAdminListPaginatedIllustrationsQuery
>;
export type AdminListPaginatedIllustrationsLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedIllustrationsLazyQuery
>;
export type AdminListPaginatedIllustrationsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedIllustrationsSuspenseQuery
>;
export type AdminListPaginatedIllustrationsQueryResult = Apollo.QueryResult<
  AdminListPaginatedIllustrationsQuery,
  AdminListPaginatedIllustrationsQueryVariables
>;
export const AdminGetLessonDocument = gql`
    query adminGetLesson($id: String!) {
  adminGetLesson(id: $id) {
    id
    kind
    title
    sections {
      id
      order
      elements {
        kind
        value
        meta
      }
    }
  }
  adminGetTopicByLessonId(id: $id) {
    id
    title
  }
}
    `;

/**
 * __useAdminGetLessonQuery__
 *
 * To run a query within a React component, call `useAdminGetLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetLessonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetLessonQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetLessonQuery, AdminGetLessonQueryVariables> &
    ({ variables: AdminGetLessonQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetLessonQuery, AdminGetLessonQueryVariables>(
    AdminGetLessonDocument,
    options,
  );
}
export function useAdminGetLessonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetLessonQuery, AdminGetLessonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetLessonQuery, AdminGetLessonQueryVariables>(
    AdminGetLessonDocument,
    options,
  );
}
export function useAdminGetLessonSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetLessonQuery, AdminGetLessonQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetLessonQuery, AdminGetLessonQueryVariables>(
    AdminGetLessonDocument,
    options,
  );
}
export type AdminGetLessonQueryHookResult = ReturnType<typeof useAdminGetLessonQuery>;
export type AdminGetLessonLazyQueryHookResult = ReturnType<typeof useAdminGetLessonLazyQuery>;
export type AdminGetLessonSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetLessonSuspenseQuery
>;
export type AdminGetLessonQueryResult = Apollo.QueryResult<
  AdminGetLessonQuery,
  AdminGetLessonQueryVariables
>;
export const GetCustomNotificationDocument = gql`
    query getCustomNotification($id: String!) {
  getCustomNotification(id: $id) {
    id
    illustration
    title
    text
    buttonText
    buttonUrl
  }
}
    `;

/**
 * __useGetCustomNotificationQuery__
 *
 * To run a query within a React component, call `useGetCustomNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomNotificationQuery,
    GetCustomNotificationQueryVariables
  > &
    ({ variables: GetCustomNotificationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomNotificationQuery, GetCustomNotificationQueryVariables>(
    GetCustomNotificationDocument,
    options,
  );
}
export function useGetCustomNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomNotificationQuery,
    GetCustomNotificationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomNotificationQuery, GetCustomNotificationQueryVariables>(
    GetCustomNotificationDocument,
    options,
  );
}
export function useGetCustomNotificationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCustomNotificationQuery,
        GetCustomNotificationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCustomNotificationQuery, GetCustomNotificationQueryVariables>(
    GetCustomNotificationDocument,
    options,
  );
}
export type GetCustomNotificationQueryHookResult = ReturnType<typeof useGetCustomNotificationQuery>;
export type GetCustomNotificationLazyQueryHookResult = ReturnType<
  typeof useGetCustomNotificationLazyQuery
>;
export type GetCustomNotificationSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomNotificationSuspenseQuery
>;
export type GetCustomNotificationQueryResult = Apollo.QueryResult<
  GetCustomNotificationQuery,
  GetCustomNotificationQueryVariables
>;
export const AdminListPaginatedCustomNotificationsDocument = gql`
    query adminListPaginatedCustomNotifications($filters: FilterCustomNotificationsInput!) {
  adminListPaginatedCustomNotifications(filters: $filters) {
    items {
      id
      illustration
      title
      text
      buttonText
      buttonUrl
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedCustomNotificationsQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedCustomNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedCustomNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedCustomNotificationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedCustomNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedCustomNotificationsQuery,
    AdminListPaginatedCustomNotificationsQueryVariables
  > &
    (
      | { variables: AdminListPaginatedCustomNotificationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListPaginatedCustomNotificationsQuery,
    AdminListPaginatedCustomNotificationsQueryVariables
  >(AdminListPaginatedCustomNotificationsDocument, options);
}
export function useAdminListPaginatedCustomNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedCustomNotificationsQuery,
    AdminListPaginatedCustomNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedCustomNotificationsQuery,
    AdminListPaginatedCustomNotificationsQueryVariables
  >(AdminListPaginatedCustomNotificationsDocument, options);
}
export function useAdminListPaginatedCustomNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedCustomNotificationsQuery,
        AdminListPaginatedCustomNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedCustomNotificationsQuery,
    AdminListPaginatedCustomNotificationsQueryVariables
  >(AdminListPaginatedCustomNotificationsDocument, options);
}
export type AdminListPaginatedCustomNotificationsQueryHookResult = ReturnType<
  typeof useAdminListPaginatedCustomNotificationsQuery
>;
export type AdminListPaginatedCustomNotificationsLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedCustomNotificationsLazyQuery
>;
export type AdminListPaginatedCustomNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedCustomNotificationsSuspenseQuery
>;
export type AdminListPaginatedCustomNotificationsQueryResult = Apollo.QueryResult<
  AdminListPaginatedCustomNotificationsQuery,
  AdminListPaginatedCustomNotificationsQueryVariables
>;
export const AdminListPaginatedReportsUsersDocument = gql`
    query adminListPaginatedReportsUsers($filters: AdminFilterReportsUserFilterInput!) {
  adminListPaginatedReportsUsers(filters: $filters) {
    items {
      id
      description
      reason
      createdAt
      abuser {
        id
        name
      }
      victim {
        id
        name
      }
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedReportsUsersQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedReportsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedReportsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedReportsUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedReportsUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedReportsUsersQuery,
    AdminListPaginatedReportsUsersQueryVariables
  > &
    (
      | { variables: AdminListPaginatedReportsUsersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListPaginatedReportsUsersQuery,
    AdminListPaginatedReportsUsersQueryVariables
  >(AdminListPaginatedReportsUsersDocument, options);
}
export function useAdminListPaginatedReportsUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedReportsUsersQuery,
    AdminListPaginatedReportsUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedReportsUsersQuery,
    AdminListPaginatedReportsUsersQueryVariables
  >(AdminListPaginatedReportsUsersDocument, options);
}
export function useAdminListPaginatedReportsUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedReportsUsersQuery,
        AdminListPaginatedReportsUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedReportsUsersQuery,
    AdminListPaginatedReportsUsersQueryVariables
  >(AdminListPaginatedReportsUsersDocument, options);
}
export type AdminListPaginatedReportsUsersQueryHookResult = ReturnType<
  typeof useAdminListPaginatedReportsUsersQuery
>;
export type AdminListPaginatedReportsUsersLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedReportsUsersLazyQuery
>;
export type AdminListPaginatedReportsUsersSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedReportsUsersSuspenseQuery
>;
export type AdminListPaginatedReportsUsersQueryResult = Apollo.QueryResult<
  AdminListPaginatedReportsUsersQuery,
  AdminListPaginatedReportsUsersQueryVariables
>;
export const AdminListPaginatedBannersDocument = gql`
    query adminListPaginatedBanners($filters: AdminFilterBannersInput!) {
  adminListPaginatedBanners(filters: $filters) {
    items {
      id
      title
      isActive
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedBannersQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedBannersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedBannersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedBannersQuery,
    AdminListPaginatedBannersQueryVariables
  > &
    ({ variables: AdminListPaginatedBannersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListPaginatedBannersQuery, AdminListPaginatedBannersQueryVariables>(
    AdminListPaginatedBannersDocument,
    options,
  );
}
export function useAdminListPaginatedBannersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedBannersQuery,
    AdminListPaginatedBannersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedBannersQuery,
    AdminListPaginatedBannersQueryVariables
  >(AdminListPaginatedBannersDocument, options);
}
export function useAdminListPaginatedBannersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedBannersQuery,
        AdminListPaginatedBannersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedBannersQuery,
    AdminListPaginatedBannersQueryVariables
  >(AdminListPaginatedBannersDocument, options);
}
export type AdminListPaginatedBannersQueryHookResult = ReturnType<
  typeof useAdminListPaginatedBannersQuery
>;
export type AdminListPaginatedBannersLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedBannersLazyQuery
>;
export type AdminListPaginatedBannersSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedBannersSuspenseQuery
>;
export type AdminListPaginatedBannersQueryResult = Apollo.QueryResult<
  AdminListPaginatedBannersQuery,
  AdminListPaginatedBannersQueryVariables
>;
export const AdminGetBannerDocument = gql`
    query adminGetBanner($id: String!) {
  adminGetBanner(id: $id) {
    id
    title
    isActive
    createdAt
    updatedAt
    audience
    sliders {
      id
      position
      title
    }
  }
}
    `;

/**
 * __useAdminGetBannerQuery__
 *
 * To run a query within a React component, call `useAdminGetBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetBannerQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetBannerQuery, AdminGetBannerQueryVariables> &
    ({ variables: AdminGetBannerQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetBannerQuery, AdminGetBannerQueryVariables>(
    AdminGetBannerDocument,
    options,
  );
}
export function useAdminGetBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetBannerQuery, AdminGetBannerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetBannerQuery, AdminGetBannerQueryVariables>(
    AdminGetBannerDocument,
    options,
  );
}
export function useAdminGetBannerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetBannerQuery, AdminGetBannerQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetBannerQuery, AdminGetBannerQueryVariables>(
    AdminGetBannerDocument,
    options,
  );
}
export type AdminGetBannerQueryHookResult = ReturnType<typeof useAdminGetBannerQuery>;
export type AdminGetBannerLazyQueryHookResult = ReturnType<typeof useAdminGetBannerLazyQuery>;
export type AdminGetBannerSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetBannerSuspenseQuery
>;
export type AdminGetBannerQueryResult = Apollo.QueryResult<
  AdminGetBannerQuery,
  AdminGetBannerQueryVariables
>;
export const AdminGetSlideDocument = gql`
    query adminGetSlide($id: String!) {
  adminGetSlide(id: $id) {
    background {
      desktop
      mobile
    }
    banner {
      id
      title
    }
    buttonText
    buttonURL
    createdAt
    featureImage
    id
    imageAlignment
    subtitle
    textColour
    title
    titleStyle
    updatedAt
  }
}
    `;

/**
 * __useAdminGetSlideQuery__
 *
 * To run a query within a React component, call `useAdminGetSlideQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetSlideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetSlideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetSlideQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetSlideQuery, AdminGetSlideQueryVariables> &
    ({ variables: AdminGetSlideQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetSlideQuery, AdminGetSlideQueryVariables>(
    AdminGetSlideDocument,
    options,
  );
}
export function useAdminGetSlideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetSlideQuery, AdminGetSlideQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetSlideQuery, AdminGetSlideQueryVariables>(
    AdminGetSlideDocument,
    options,
  );
}
export function useAdminGetSlideSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetSlideQuery, AdminGetSlideQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetSlideQuery, AdminGetSlideQueryVariables>(
    AdminGetSlideDocument,
    options,
  );
}
export type AdminGetSlideQueryHookResult = ReturnType<typeof useAdminGetSlideQuery>;
export type AdminGetSlideLazyQueryHookResult = ReturnType<typeof useAdminGetSlideLazyQuery>;
export type AdminGetSlideSuspenseQueryHookResult = ReturnType<typeof useAdminGetSlideSuspenseQuery>;
export type AdminGetSlideQueryResult = Apollo.QueryResult<
  AdminGetSlideQuery,
  AdminGetSlideQueryVariables
>;
export const AdminListPaginatedThemesDocument = gql`
    query adminListPaginatedThemes($filters: AdminFilterThemesInput!) {
  adminListPaginatedThemes(filters: $filters) {
    items {
      title
      id
      isCurrent
      isStandard
      isGrammarLibrary
      isTopicLibrary
      isResource
      topics {
        id
        title
      }
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedThemesQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedThemesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedThemesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedThemesQuery,
    AdminListPaginatedThemesQueryVariables
  > &
    ({ variables: AdminListPaginatedThemesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListPaginatedThemesQuery, AdminListPaginatedThemesQueryVariables>(
    AdminListPaginatedThemesDocument,
    options,
  );
}
export function useAdminListPaginatedThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedThemesQuery,
    AdminListPaginatedThemesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminListPaginatedThemesQuery, AdminListPaginatedThemesQueryVariables>(
    AdminListPaginatedThemesDocument,
    options,
  );
}
export function useAdminListPaginatedThemesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedThemesQuery,
        AdminListPaginatedThemesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedThemesQuery,
    AdminListPaginatedThemesQueryVariables
  >(AdminListPaginatedThemesDocument, options);
}
export type AdminListPaginatedThemesQueryHookResult = ReturnType<
  typeof useAdminListPaginatedThemesQuery
>;
export type AdminListPaginatedThemesLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedThemesLazyQuery
>;
export type AdminListPaginatedThemesSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedThemesSuspenseQuery
>;
export type AdminListPaginatedThemesQueryResult = Apollo.QueryResult<
  AdminListPaginatedThemesQuery,
  AdminListPaginatedThemesQueryVariables
>;
export const AdminListThemesDocument = gql`
    query adminListThemes {
  adminListThemes {
    title
    id
    isCurrent
    isStandard
    isGrammarLibrary
    isTopicLibrary
    isResource
    topics {
      id
      title
    }
  }
}
    `;

/**
 * __useAdminListThemesQuery__
 *
 * To run a query within a React component, call `useAdminListThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminListThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminListThemesQuery, AdminListThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListThemesQuery, AdminListThemesQueryVariables>(
    AdminListThemesDocument,
    options,
  );
}
export function useAdminListThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminListThemesQuery, AdminListThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminListThemesQuery, AdminListThemesQueryVariables>(
    AdminListThemesDocument,
    options,
  );
}
export function useAdminListThemesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminListThemesQuery, AdminListThemesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminListThemesQuery, AdminListThemesQueryVariables>(
    AdminListThemesDocument,
    options,
  );
}
export type AdminListThemesQueryHookResult = ReturnType<typeof useAdminListThemesQuery>;
export type AdminListThemesLazyQueryHookResult = ReturnType<typeof useAdminListThemesLazyQuery>;
export type AdminListThemesSuspenseQueryHookResult = ReturnType<
  typeof useAdminListThemesSuspenseQuery
>;
export type AdminListThemesQueryResult = Apollo.QueryResult<
  AdminListThemesQuery,
  AdminListThemesQueryVariables
>;
export const AdminGetThemeAndCoversDocument = gql`
    query adminGetThemeAndCovers($id: String!) {
  adminGetTheme(id: $id) {
    id
    title
    description
    isCurrent
    isStandard
    isGrammarLibrary
    isTopicLibrary
    isResource
    cover {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
  }
  adminListCovers {
    id
    title
    primary {
      mobile
      desktop
    }
    secondary {
      mobile
      desktop
    }
    tertiary {
      mobile
      desktop
    }
  }
}
    `;

/**
 * __useAdminGetThemeAndCoversQuery__
 *
 * To run a query within a React component, call `useAdminGetThemeAndCoversQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetThemeAndCoversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetThemeAndCoversQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetThemeAndCoversQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetThemeAndCoversQuery,
    AdminGetThemeAndCoversQueryVariables
  > &
    ({ variables: AdminGetThemeAndCoversQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetThemeAndCoversQuery, AdminGetThemeAndCoversQueryVariables>(
    AdminGetThemeAndCoversDocument,
    options,
  );
}
export function useAdminGetThemeAndCoversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetThemeAndCoversQuery,
    AdminGetThemeAndCoversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetThemeAndCoversQuery, AdminGetThemeAndCoversQueryVariables>(
    AdminGetThemeAndCoversDocument,
    options,
  );
}
export function useAdminGetThemeAndCoversSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetThemeAndCoversQuery,
        AdminGetThemeAndCoversQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetThemeAndCoversQuery, AdminGetThemeAndCoversQueryVariables>(
    AdminGetThemeAndCoversDocument,
    options,
  );
}
export type AdminGetThemeAndCoversQueryHookResult = ReturnType<
  typeof useAdminGetThemeAndCoversQuery
>;
export type AdminGetThemeAndCoversLazyQueryHookResult = ReturnType<
  typeof useAdminGetThemeAndCoversLazyQuery
>;
export type AdminGetThemeAndCoversSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetThemeAndCoversSuspenseQuery
>;
export type AdminGetThemeAndCoversQueryResult = Apollo.QueryResult<
  AdminGetThemeAndCoversQuery,
  AdminGetThemeAndCoversQueryVariables
>;
export const AdminListPaginatedTopicsDocument = gql`
    query adminListPaginatedTopics($filters: AdminFilterTopicsInput!) {
  adminListPaginatedTopics(filters: $filters) {
    items {
      title
      id
      status
      updatedAt
      theme {
        id
        title
        isGrammarLibrary
        isTopicLibrary
        isStandard
        isResource
      }
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedTopicsQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedTopicsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedTopicsQuery,
    AdminListPaginatedTopicsQueryVariables
  > &
    ({ variables: AdminListPaginatedTopicsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListPaginatedTopicsQuery, AdminListPaginatedTopicsQueryVariables>(
    AdminListPaginatedTopicsDocument,
    options,
  );
}
export function useAdminListPaginatedTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedTopicsQuery,
    AdminListPaginatedTopicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminListPaginatedTopicsQuery, AdminListPaginatedTopicsQueryVariables>(
    AdminListPaginatedTopicsDocument,
    options,
  );
}
export function useAdminListPaginatedTopicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedTopicsQuery,
        AdminListPaginatedTopicsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedTopicsQuery,
    AdminListPaginatedTopicsQueryVariables
  >(AdminListPaginatedTopicsDocument, options);
}
export type AdminListPaginatedTopicsQueryHookResult = ReturnType<
  typeof useAdminListPaginatedTopicsQuery
>;
export type AdminListPaginatedTopicsLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedTopicsLazyQuery
>;
export type AdminListPaginatedTopicsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedTopicsSuspenseQuery
>;
export type AdminListPaginatedTopicsQueryResult = Apollo.QueryResult<
  AdminListPaginatedTopicsQuery,
  AdminListPaginatedTopicsQueryVariables
>;
export const AdminGetTopicThemesAndIllustrationsDocument = gql`
    query adminGetTopicThemesAndIllustrations($id: String!) {
  adminGetTopic(id: $id) {
    id
    title
    theme {
      id
      title
    }
    comments {
      id
      createdAt
      message
      author {
        id
        name
        avatarUrl
      }
      replies {
        id
        createdAt
        message
        author {
          id
          name
          avatarUrl
        }
      }
    }
    status
    illustration {
      id
      title
      url
    }
    lessons {
      id
      kind
      title
    }
  }
  adminListThemes {
    id
    title
  }
  adminListIllustrations {
    id
    title
    url
  }
}
    `;

/**
 * __useAdminGetTopicThemesAndIllustrationsQuery__
 *
 * To run a query within a React component, call `useAdminGetTopicThemesAndIllustrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetTopicThemesAndIllustrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetTopicThemesAndIllustrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetTopicThemesAndIllustrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetTopicThemesAndIllustrationsQuery,
    AdminGetTopicThemesAndIllustrationsQueryVariables
  > &
    (
      | { variables: AdminGetTopicThemesAndIllustrationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetTopicThemesAndIllustrationsQuery,
    AdminGetTopicThemesAndIllustrationsQueryVariables
  >(AdminGetTopicThemesAndIllustrationsDocument, options);
}
export function useAdminGetTopicThemesAndIllustrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetTopicThemesAndIllustrationsQuery,
    AdminGetTopicThemesAndIllustrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetTopicThemesAndIllustrationsQuery,
    AdminGetTopicThemesAndIllustrationsQueryVariables
  >(AdminGetTopicThemesAndIllustrationsDocument, options);
}
export function useAdminGetTopicThemesAndIllustrationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetTopicThemesAndIllustrationsQuery,
        AdminGetTopicThemesAndIllustrationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetTopicThemesAndIllustrationsQuery,
    AdminGetTopicThemesAndIllustrationsQueryVariables
  >(AdminGetTopicThemesAndIllustrationsDocument, options);
}
export type AdminGetTopicThemesAndIllustrationsQueryHookResult = ReturnType<
  typeof useAdminGetTopicThemesAndIllustrationsQuery
>;
export type AdminGetTopicThemesAndIllustrationsLazyQueryHookResult = ReturnType<
  typeof useAdminGetTopicThemesAndIllustrationsLazyQuery
>;
export type AdminGetTopicThemesAndIllustrationsSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetTopicThemesAndIllustrationsSuspenseQuery
>;
export type AdminGetTopicThemesAndIllustrationsQueryResult = Apollo.QueryResult<
  AdminGetTopicThemesAndIllustrationsQuery,
  AdminGetTopicThemesAndIllustrationsQueryVariables
>;
export const ListTotpDocument = gql`
    query listTotp {
  listTotp {
    id
    deviceName
    createdAt
  }
}
    `;

/**
 * __useListTotpQuery__
 *
 * To run a query within a React component, call `useListTotpQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTotpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTotpQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTotpQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTotpQuery, ListTotpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTotpQuery, ListTotpQueryVariables>(ListTotpDocument, options);
}
export function useListTotpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTotpQuery, ListTotpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTotpQuery, ListTotpQueryVariables>(ListTotpDocument, options);
}
export function useListTotpSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListTotpQuery, ListTotpQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListTotpQuery, ListTotpQueryVariables>(ListTotpDocument, options);
}
export type ListTotpQueryHookResult = ReturnType<typeof useListTotpQuery>;
export type ListTotpLazyQueryHookResult = ReturnType<typeof useListTotpLazyQuery>;
export type ListTotpSuspenseQueryHookResult = ReturnType<typeof useListTotpSuspenseQuery>;
export type ListTotpQueryResult = Apollo.QueryResult<ListTotpQuery, ListTotpQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    avatarUrl
    needSpeakingPartner
    cambridgeEnglishLevel
    kind
    firstName
    otherLanguage
    isComplete
    badge
    createdAt
    lastName
    city
    country
    timezone
    story
    birthday
    interests
    occupation {
      id
      name
    }
    showBirthday
    showBirthdayYear
    nationality
    nativeLanguage
    completedPercentage
    isVisible
    wantsNewsletter
    availability {
      from
      until
    }
    promotionalCredits
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AdminListUsersDocument = gql`
    query adminListUsers($filters: AdminFilterUsersInput!) {
  adminListUsers(filters: $filters) {
    items {
      id
      name
      email
      name
      avatarUrl
      needSpeakingPartner
      cambridgeEnglishLevel
      otherLanguage
      isComplete
      isVerified
      occupation {
        id
        name
      }
      badge
      createdAt
      city
      country
      timezone
      story
      birthday
      interests
      showBirthday
      nativeLanguage
      completedPercentage
      isVisible
      wantsNewsletter
      availability {
        from
        until
      }
    }
    total
  }
}
    `;

/**
 * __useAdminListUsersQuery__
 *
 * To run a query within a React component, call `useAdminListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListUsersQuery(
  baseOptions: Apollo.QueryHookOptions<AdminListUsersQuery, AdminListUsersQueryVariables> &
    ({ variables: AdminListUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListUsersQuery, AdminListUsersQueryVariables>(
    AdminListUsersDocument,
    options,
  );
}
export function useAdminListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminListUsersQuery, AdminListUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminListUsersQuery, AdminListUsersQueryVariables>(
    AdminListUsersDocument,
    options,
  );
}
export function useAdminListUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminListUsersQuery, AdminListUsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminListUsersQuery, AdminListUsersQueryVariables>(
    AdminListUsersDocument,
    options,
  );
}
export type AdminListUsersQueryHookResult = ReturnType<typeof useAdminListUsersQuery>;
export type AdminListUsersLazyQueryHookResult = ReturnType<typeof useAdminListUsersLazyQuery>;
export type AdminListUsersSuspenseQueryHookResult = ReturnType<
  typeof useAdminListUsersSuspenseQuery
>;
export type AdminListUsersQueryResult = Apollo.QueryResult<
  AdminListUsersQuery,
  AdminListUsersQueryVariables
>;
export const AdminGetSelectedUsersDocument = gql`
    query adminGetSelectedUsers($filters: AdminFilterUsersInput!) {
  adminGetSelectedUsers(filters: $filters) {
    id
    firstName
    lastName
    email
    cambridgeEnglishLevel
    speakingLevelScore
    speakingLevel
    city
    country
    birthday
    nativeLanguage
    needSpeakingPartner
    badge
    isVerified
    lastOnlineAt
    availability {
      from
      until
    }
    timezone
    isActive
    isComplete
    occupation {
      id
      name
    }
    interests
    englishSkills
    createdAt
    kind
    customers {
      subscriptions {
        status
        plan
        currentPeriodStart
        currentPeriodEnd
        resumesAt
        cancelledAt
      }
    }
    pauseCredits
    showBirthday
    completedPercentage
    isVisible
    wantsNewsletter
  }
}
    `;

/**
 * __useAdminGetSelectedUsersQuery__
 *
 * To run a query within a React component, call `useAdminGetSelectedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetSelectedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetSelectedUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminGetSelectedUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetSelectedUsersQuery,
    AdminGetSelectedUsersQueryVariables
  > &
    ({ variables: AdminGetSelectedUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetSelectedUsersQuery, AdminGetSelectedUsersQueryVariables>(
    AdminGetSelectedUsersDocument,
    options,
  );
}
export function useAdminGetSelectedUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetSelectedUsersQuery,
    AdminGetSelectedUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetSelectedUsersQuery, AdminGetSelectedUsersQueryVariables>(
    AdminGetSelectedUsersDocument,
    options,
  );
}
export function useAdminGetSelectedUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetSelectedUsersQuery,
        AdminGetSelectedUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetSelectedUsersQuery, AdminGetSelectedUsersQueryVariables>(
    AdminGetSelectedUsersDocument,
    options,
  );
}
export type AdminGetSelectedUsersQueryHookResult = ReturnType<typeof useAdminGetSelectedUsersQuery>;
export type AdminGetSelectedUsersLazyQueryHookResult = ReturnType<
  typeof useAdminGetSelectedUsersLazyQuery
>;
export type AdminGetSelectedUsersSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetSelectedUsersSuspenseQuery
>;
export type AdminGetSelectedUsersQueryResult = Apollo.QueryResult<
  AdminGetSelectedUsersQuery,
  AdminGetSelectedUsersQueryVariables
>;
export const AdminGetPlanDetailsDocument = gql`
    query adminGetPlanDetails($userId: String!) {
  adminGetPlanDetails(userId: $userId) {
    name
    cbId
    subscriptionChargebeeId
    customerChargebeeId
  }
}
    `;

/**
 * __useAdminGetPlanDetailsQuery__
 *
 * To run a query within a React component, call `useAdminGetPlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetPlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetPlanDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminGetPlanDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetPlanDetailsQuery,
    AdminGetPlanDetailsQueryVariables
  > &
    ({ variables: AdminGetPlanDetailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetPlanDetailsQuery, AdminGetPlanDetailsQueryVariables>(
    AdminGetPlanDetailsDocument,
    options,
  );
}
export function useAdminGetPlanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetPlanDetailsQuery,
    AdminGetPlanDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetPlanDetailsQuery, AdminGetPlanDetailsQueryVariables>(
    AdminGetPlanDetailsDocument,
    options,
  );
}
export function useAdminGetPlanDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetPlanDetailsQuery, AdminGetPlanDetailsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetPlanDetailsQuery, AdminGetPlanDetailsQueryVariables>(
    AdminGetPlanDetailsDocument,
    options,
  );
}
export type AdminGetPlanDetailsQueryHookResult = ReturnType<typeof useAdminGetPlanDetailsQuery>;
export type AdminGetPlanDetailsLazyQueryHookResult = ReturnType<
  typeof useAdminGetPlanDetailsLazyQuery
>;
export type AdminGetPlanDetailsSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetPlanDetailsSuspenseQuery
>;
export type AdminGetPlanDetailsQueryResult = Apollo.QueryResult<
  AdminGetPlanDetailsQuery,
  AdminGetPlanDetailsQueryVariables
>;
export const GetUserAdminDocument = gql`
    query getUserAdmin($id: String!) {
  getUserAdmin(id: $id) {
    id
    email
    name
    createdAt
    kind
    avatarUrl
    needSpeakingPartner
    cambridgeEnglishLevel
    speakingLevelScore
    speakingLevel
    firstName
    otherLanguage
    isComplete
    badge
    createdAt
    lastName
    isActive
    isBanned
    isVerified
    hasPassword
    city
    country
    timezone
    story
    birthday
    interests
    occupation {
      id
      name
    }
    badges {
      id
      type
      achievedDate
    }
    isAgeVisible
    showBirthday
    nativeLanguage
    completedPercentage
    isVisible
    isFeatured
    wantsNewsletter
    pauseCredits
    hasActivePlan
    availability {
      from
      until
    }
    notes {
      id
      description
    }
    availableAssessmentCharges
    engagementMetrics {
      attendanceMetrics {
        totalEvents
        totalOneToOnes
        totalGroup
        totalWebinars
        totalInPerson
        totalOther
        totalDuration
        totalHostedEvents
        totalHostedOneToOnes
        totalHostedGroup
        totalHostedWebinars
        totalHostedInPerson
        totalHostedOther
        totalHostedDuration
        totalParticipatedEvents
        totalParticipatedScheduledEvents
        totalParticipatedOneToOnes
        totalParticipatedGroup
        totalParticipatedWebinars
        totalParticipatedInPerson
        totalParticipatedOther
        totalParticipatedDuration
        totalVideoCalls
        totalParticipatedNonScheduledEvents
      }
      totalRsvps
      publishedArticles
      totalHostedParticipants
      attendanceRatio
    }
    extraInvitedFriends
  }
}
    `;

/**
 * __useGetUserAdminQuery__
 *
 * To run a query within a React component, call `useGetUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserAdminQuery, GetUserAdminQueryVariables> &
    ({ variables: GetUserAdminQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAdminQuery, GetUserAdminQueryVariables>(
    GetUserAdminDocument,
    options,
  );
}
export function useGetUserAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserAdminQuery, GetUserAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAdminQuery, GetUserAdminQueryVariables>(
    GetUserAdminDocument,
    options,
  );
}
export function useGetUserAdminSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserAdminQuery, GetUserAdminQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserAdminQuery, GetUserAdminQueryVariables>(
    GetUserAdminDocument,
    options,
  );
}
export type GetUserAdminQueryHookResult = ReturnType<typeof useGetUserAdminQuery>;
export type GetUserAdminLazyQueryHookResult = ReturnType<typeof useGetUserAdminLazyQuery>;
export type GetUserAdminSuspenseQueryHookResult = ReturnType<typeof useGetUserAdminSuspenseQuery>;
export type GetUserAdminQueryResult = Apollo.QueryResult<
  GetUserAdminQuery,
  GetUserAdminQueryVariables
>;
export const AdminGetOccupationsDocument = gql`
    query adminGetOccupations {
  adminGetOccupations {
    id
    name
  }
}
    `;

/**
 * __useAdminGetOccupationsQuery__
 *
 * To run a query within a React component, call `useAdminGetOccupationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOccupationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOccupationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetOccupationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminGetOccupationsQuery,
    AdminGetOccupationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetOccupationsQuery, AdminGetOccupationsQueryVariables>(
    AdminGetOccupationsDocument,
    options,
  );
}
export function useAdminGetOccupationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetOccupationsQuery,
    AdminGetOccupationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetOccupationsQuery, AdminGetOccupationsQueryVariables>(
    AdminGetOccupationsDocument,
    options,
  );
}
export function useAdminGetOccupationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetOccupationsQuery, AdminGetOccupationsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetOccupationsQuery, AdminGetOccupationsQueryVariables>(
    AdminGetOccupationsDocument,
    options,
  );
}
export type AdminGetOccupationsQueryHookResult = ReturnType<typeof useAdminGetOccupationsQuery>;
export type AdminGetOccupationsLazyQueryHookResult = ReturnType<
  typeof useAdminGetOccupationsLazyQuery
>;
export type AdminGetOccupationsSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetOccupationsSuspenseQuery
>;
export type AdminGetOccupationsQueryResult = Apollo.QueryResult<
  AdminGetOccupationsQuery,
  AdminGetOccupationsQueryVariables
>;
export const AdminGetGroupedOccupationsDocument = gql`
    query adminGetGroupedOccupations {
  adminGetGroupedOccupations {
    id
    name
    industry {
      id
      name
    }
  }
}
    `;

/**
 * __useAdminGetGroupedOccupationsQuery__
 *
 * To run a query within a React component, call `useAdminGetGroupedOccupationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetGroupedOccupationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetGroupedOccupationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetGroupedOccupationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminGetGroupedOccupationsQuery,
    AdminGetGroupedOccupationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetGroupedOccupationsQuery, AdminGetGroupedOccupationsQueryVariables>(
    AdminGetGroupedOccupationsDocument,
    options,
  );
}
export function useAdminGetGroupedOccupationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetGroupedOccupationsQuery,
    AdminGetGroupedOccupationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetGroupedOccupationsQuery,
    AdminGetGroupedOccupationsQueryVariables
  >(AdminGetGroupedOccupationsDocument, options);
}
export function useAdminGetGroupedOccupationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetGroupedOccupationsQuery,
        AdminGetGroupedOccupationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetGroupedOccupationsQuery,
    AdminGetGroupedOccupationsQueryVariables
  >(AdminGetGroupedOccupationsDocument, options);
}
export type AdminGetGroupedOccupationsQueryHookResult = ReturnType<
  typeof useAdminGetGroupedOccupationsQuery
>;
export type AdminGetGroupedOccupationsLazyQueryHookResult = ReturnType<
  typeof useAdminGetGroupedOccupationsLazyQuery
>;
export type AdminGetGroupedOccupationsSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetGroupedOccupationsSuspenseQuery
>;
export type AdminGetGroupedOccupationsQueryResult = Apollo.QueryResult<
  AdminGetGroupedOccupationsQuery,
  AdminGetGroupedOccupationsQueryVariables
>;
export const GetInvitedFriendsStatsDocument = gql`
    query getInvitedFriendsStats($userId: String!) {
  getInvitedFriendsStats(userId: $userId) {
    invitedFriendsTotal
    invitedPaidFriends
  }
}
    `;

/**
 * __useGetInvitedFriendsStatsQuery__
 *
 * To run a query within a React component, call `useGetInvitedFriendsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedFriendsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedFriendsStatsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetInvitedFriendsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvitedFriendsStatsQuery,
    GetInvitedFriendsStatsQueryVariables
  > &
    ({ variables: GetInvitedFriendsStatsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitedFriendsStatsQuery, GetInvitedFriendsStatsQueryVariables>(
    GetInvitedFriendsStatsDocument,
    options,
  );
}
export function useGetInvitedFriendsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitedFriendsStatsQuery,
    GetInvitedFriendsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitedFriendsStatsQuery, GetInvitedFriendsStatsQueryVariables>(
    GetInvitedFriendsStatsDocument,
    options,
  );
}
export function useGetInvitedFriendsStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetInvitedFriendsStatsQuery,
        GetInvitedFriendsStatsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInvitedFriendsStatsQuery, GetInvitedFriendsStatsQueryVariables>(
    GetInvitedFriendsStatsDocument,
    options,
  );
}
export type GetInvitedFriendsStatsQueryHookResult = ReturnType<
  typeof useGetInvitedFriendsStatsQuery
>;
export type GetInvitedFriendsStatsLazyQueryHookResult = ReturnType<
  typeof useGetInvitedFriendsStatsLazyQuery
>;
export type GetInvitedFriendsStatsSuspenseQueryHookResult = ReturnType<
  typeof useGetInvitedFriendsStatsSuspenseQuery
>;
export type GetInvitedFriendsStatsQueryResult = Apollo.QueryResult<
  GetInvitedFriendsStatsQuery,
  GetInvitedFriendsStatsQueryVariables
>;
export const ListPaginatedAssessmentResultsDocument = gql`
    query listPaginatedAssessmentResults($filters: PaginatedAssessmentResultInput!) {
  listPaginatedAssessmentResults(filters: $filters) {
    total
    items {
      id
      assessmentId
      cefrScore
      halloScore
      createdAt
    }
  }
}
    `;

/**
 * __useListPaginatedAssessmentResultsQuery__
 *
 * To run a query within a React component, call `useListPaginatedAssessmentResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaginatedAssessmentResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaginatedAssessmentResultsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListPaginatedAssessmentResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListPaginatedAssessmentResultsQuery,
    ListPaginatedAssessmentResultsQueryVariables
  > &
    (
      | { variables: ListPaginatedAssessmentResultsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListPaginatedAssessmentResultsQuery,
    ListPaginatedAssessmentResultsQueryVariables
  >(ListPaginatedAssessmentResultsDocument, options);
}
export function useListPaginatedAssessmentResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPaginatedAssessmentResultsQuery,
    ListPaginatedAssessmentResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListPaginatedAssessmentResultsQuery,
    ListPaginatedAssessmentResultsQueryVariables
  >(ListPaginatedAssessmentResultsDocument, options);
}
export function useListPaginatedAssessmentResultsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ListPaginatedAssessmentResultsQuery,
        ListPaginatedAssessmentResultsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListPaginatedAssessmentResultsQuery,
    ListPaginatedAssessmentResultsQueryVariables
  >(ListPaginatedAssessmentResultsDocument, options);
}
export type ListPaginatedAssessmentResultsQueryHookResult = ReturnType<
  typeof useListPaginatedAssessmentResultsQuery
>;
export type ListPaginatedAssessmentResultsLazyQueryHookResult = ReturnType<
  typeof useListPaginatedAssessmentResultsLazyQuery
>;
export type ListPaginatedAssessmentResultsSuspenseQueryHookResult = ReturnType<
  typeof useListPaginatedAssessmentResultsSuspenseQuery
>;
export type ListPaginatedAssessmentResultsQueryResult = Apollo.QueryResult<
  ListPaginatedAssessmentResultsQuery,
  ListPaginatedAssessmentResultsQueryVariables
>;
export const GetUserAssessmentResultsDocument = gql`
    query getUserAssessmentResults($userId: String!, $filters: PaginatedAssessmentResultInput!) {
  getUserAssessmentResults(userId: $userId, filters: $filters) {
    total
    items {
      id
      assessmentId
      cefrScore
      halloScore
      createdAt
      isAddedByAdmin
    }
  }
}
    `;

/**
 * __useGetUserAssessmentResultsQuery__
 *
 * To run a query within a React component, call `useGetUserAssessmentResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssessmentResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssessmentResultsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUserAssessmentResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAssessmentResultsQuery,
    GetUserAssessmentResultsQueryVariables
  > &
    ({ variables: GetUserAssessmentResultsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAssessmentResultsQuery, GetUserAssessmentResultsQueryVariables>(
    GetUserAssessmentResultsDocument,
    options,
  );
}
export function useGetUserAssessmentResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAssessmentResultsQuery,
    GetUserAssessmentResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAssessmentResultsQuery, GetUserAssessmentResultsQueryVariables>(
    GetUserAssessmentResultsDocument,
    options,
  );
}
export function useGetUserAssessmentResultsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserAssessmentResultsQuery,
        GetUserAssessmentResultsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserAssessmentResultsQuery,
    GetUserAssessmentResultsQueryVariables
  >(GetUserAssessmentResultsDocument, options);
}
export type GetUserAssessmentResultsQueryHookResult = ReturnType<
  typeof useGetUserAssessmentResultsQuery
>;
export type GetUserAssessmentResultsLazyQueryHookResult = ReturnType<
  typeof useGetUserAssessmentResultsLazyQuery
>;
export type GetUserAssessmentResultsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserAssessmentResultsSuspenseQuery
>;
export type GetUserAssessmentResultsQueryResult = Apollo.QueryResult<
  GetUserAssessmentResultsQuery,
  GetUserAssessmentResultsQueryVariables
>;
export const AdminListPaginatedVideoSetsDocument = gql`
    query adminListPaginatedVideoSets($filters: AdminFilterVideoSetInput!) {
  adminListPaginatedVideoSets(filters: $filters) {
    items {
      id
      title
      isActive
      audience
      colour
      updatedAt
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedVideoSetsQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedVideoSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedVideoSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedVideoSetsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedVideoSetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedVideoSetsQuery,
    AdminListPaginatedVideoSetsQueryVariables
  > &
    ({ variables: AdminListPaginatedVideoSetsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListPaginatedVideoSetsQuery,
    AdminListPaginatedVideoSetsQueryVariables
  >(AdminListPaginatedVideoSetsDocument, options);
}
export function useAdminListPaginatedVideoSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedVideoSetsQuery,
    AdminListPaginatedVideoSetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedVideoSetsQuery,
    AdminListPaginatedVideoSetsQueryVariables
  >(AdminListPaginatedVideoSetsDocument, options);
}
export function useAdminListPaginatedVideoSetsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedVideoSetsQuery,
        AdminListPaginatedVideoSetsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedVideoSetsQuery,
    AdminListPaginatedVideoSetsQueryVariables
  >(AdminListPaginatedVideoSetsDocument, options);
}
export type AdminListPaginatedVideoSetsQueryHookResult = ReturnType<
  typeof useAdminListPaginatedVideoSetsQuery
>;
export type AdminListPaginatedVideoSetsLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedVideoSetsLazyQuery
>;
export type AdminListPaginatedVideoSetsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedVideoSetsSuspenseQuery
>;
export type AdminListPaginatedVideoSetsQueryResult = Apollo.QueryResult<
  AdminListPaginatedVideoSetsQuery,
  AdminListPaginatedVideoSetsQueryVariables
>;
export const AdminGetVideoSetDocument = gql`
    query adminGetVideoSet($id: String!) {
  adminGetVideoSet(id: $id) {
    id
    title
    isActive
    audience
    colour
    updatedAt
    videos {
      id
      title
      videoURL
      thumbnail
      isActive
      position
    }
  }
}
    `;

/**
 * __useAdminGetVideoSetQuery__
 *
 * To run a query within a React component, call `useAdminGetVideoSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetVideoSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetVideoSetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetVideoSetQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetVideoSetQuery, AdminGetVideoSetQueryVariables> &
    ({ variables: AdminGetVideoSetQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetVideoSetQuery, AdminGetVideoSetQueryVariables>(
    AdminGetVideoSetDocument,
    options,
  );
}
export function useAdminGetVideoSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetVideoSetQuery, AdminGetVideoSetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetVideoSetQuery, AdminGetVideoSetQueryVariables>(
    AdminGetVideoSetDocument,
    options,
  );
}
export function useAdminGetVideoSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetVideoSetQuery, AdminGetVideoSetQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetVideoSetQuery, AdminGetVideoSetQueryVariables>(
    AdminGetVideoSetDocument,
    options,
  );
}
export type AdminGetVideoSetQueryHookResult = ReturnType<typeof useAdminGetVideoSetQuery>;
export type AdminGetVideoSetLazyQueryHookResult = ReturnType<typeof useAdminGetVideoSetLazyQuery>;
export type AdminGetVideoSetSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetVideoSetSuspenseQuery
>;
export type AdminGetVideoSetQueryResult = Apollo.QueryResult<
  AdminGetVideoSetQuery,
  AdminGetVideoSetQueryVariables
>;
export const AdminGetZineCoverDocument = gql`
    query adminGetZineCover($id: String!) {
  adminGetZineCover(id: $id) {
    id
    coverUrl
    isCurrent
  }
}
    `;

/**
 * __useAdminGetZineCoverQuery__
 *
 * To run a query within a React component, call `useAdminGetZineCoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetZineCoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetZineCoverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetZineCoverQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetZineCoverQuery, AdminGetZineCoverQueryVariables> &
    ({ variables: AdminGetZineCoverQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetZineCoverQuery, AdminGetZineCoverQueryVariables>(
    AdminGetZineCoverDocument,
    options,
  );
}
export function useAdminGetZineCoverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetZineCoverQuery,
    AdminGetZineCoverQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetZineCoverQuery, AdminGetZineCoverQueryVariables>(
    AdminGetZineCoverDocument,
    options,
  );
}
export function useAdminGetZineCoverSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminGetZineCoverQuery, AdminGetZineCoverQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetZineCoverQuery, AdminGetZineCoverQueryVariables>(
    AdminGetZineCoverDocument,
    options,
  );
}
export type AdminGetZineCoverQueryHookResult = ReturnType<typeof useAdminGetZineCoverQuery>;
export type AdminGetZineCoverLazyQueryHookResult = ReturnType<typeof useAdminGetZineCoverLazyQuery>;
export type AdminGetZineCoverSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetZineCoverSuspenseQuery
>;
export type AdminGetZineCoverQueryResult = Apollo.QueryResult<
  AdminGetZineCoverQuery,
  AdminGetZineCoverQueryVariables
>;
export const AdminListPaginatedZineCoversDocument = gql`
    query adminListPaginatedZineCovers($filters: AdminFilterZineCoversInput!) {
  adminListPaginatedZineCovers(filters: $filters) {
    items {
      id
      coverUrl
      isCurrent
    }
    total
  }
}
    `;

/**
 * __useAdminListPaginatedZineCoversQuery__
 *
 * To run a query within a React component, call `useAdminListPaginatedZineCoversQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListPaginatedZineCoversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListPaginatedZineCoversQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdminListPaginatedZineCoversQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListPaginatedZineCoversQuery,
    AdminListPaginatedZineCoversQueryVariables
  > &
    ({ variables: AdminListPaginatedZineCoversQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListPaginatedZineCoversQuery,
    AdminListPaginatedZineCoversQueryVariables
  >(AdminListPaginatedZineCoversDocument, options);
}
export function useAdminListPaginatedZineCoversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListPaginatedZineCoversQuery,
    AdminListPaginatedZineCoversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListPaginatedZineCoversQuery,
    AdminListPaginatedZineCoversQueryVariables
  >(AdminListPaginatedZineCoversDocument, options);
}
export function useAdminListPaginatedZineCoversSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListPaginatedZineCoversQuery,
        AdminListPaginatedZineCoversQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListPaginatedZineCoversQuery,
    AdminListPaginatedZineCoversQueryVariables
  >(AdminListPaginatedZineCoversDocument, options);
}
export type AdminListPaginatedZineCoversQueryHookResult = ReturnType<
  typeof useAdminListPaginatedZineCoversQuery
>;
export type AdminListPaginatedZineCoversLazyQueryHookResult = ReturnType<
  typeof useAdminListPaginatedZineCoversLazyQuery
>;
export type AdminListPaginatedZineCoversSuspenseQueryHookResult = ReturnType<
  typeof useAdminListPaginatedZineCoversSuspenseQuery
>;
export type AdminListPaginatedZineCoversQueryResult = Apollo.QueryResult<
  AdminListPaginatedZineCoversQuery,
  AdminListPaginatedZineCoversQueryVariables
>;
