import { CircularProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import EditPopUpNotificationFormCont from "src/components/Notifications/EditPopUpNotificationFormCont.tsx";
import NotificationEmailsForm from "src/components/Notifications/NotificationsEmailsForm.tsx";
import { CustomNotificationModel, useGetCustomNotificationQuery } from "src/types/graphql.ts";

const PopUpNotificationPage: React.FC = () => {
  const params = useParams();

  const { data, loading } = useGetCustomNotificationQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: params?.id ?? "",
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (!data?.getCustomNotification) {
    return null;
  }

  return (
    <>
      <EditPopUpNotificationFormCont
        notification={data?.getCustomNotification as CustomNotificationModel}
      />
      <NotificationEmailsForm notificationId={data?.getCustomNotification.id} />
    </>
  );
};

export default PopUpNotificationPage;
